import { useState } from 'react'
import './App.css'
import MainLayout from "./Layout"
import { Outlet } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import GlobalStyle from '../GlobalStyle';
import withRouter from './services/HOC/withRouterHOC';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import Minicart from './pages/cart/Minicart';


Sentry.init({
  dsn: 'https://30c8b213e1aa0142ee05855127ef2562@o4506423182622720.ingest.us.sentry.io/4507326920916992',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [import.meta.env.VITE_REACT_APP_API_URL],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [import.meta.env.VITE_REACT_APP_SITE_URL, /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


function App(props) {
  const pathName = window.location.pathname
  return (
    <div className="App">
      <GlobalStyle />
      <MainLayout {...props}>
        <Outlet context={props} />
        {pathName !== "/signup" &&
          <Minicart {...props} />}

      </MainLayout>


    </div>
  )
}

export default withRouter(App)

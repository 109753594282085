import React from 'react'
import { FirstBonusCss, } from './RewardStyle'

const FirstBonus = (props) => {
  const { Validatelogin, userRank } = props
  return (
    <FirstBonusCss>
      <figure className="manual-background">
        <img src="images/first-bonus-bg.png" alt="Banner background" />
      </figure>
      <div className="container">
        <div className="cstm-row">
          <div className="col-6">
            <div className="first-bonus-content">
              <h2>
                <span>FIRST ORDER BONUS</span>
              </h2>
              <ul>
                <li>
                  First Order Bonuses for Member Packs: You can earn First Order Bonuses on Member Packs down 3 Levels!
                </li>
                <li>
                  Earning Potential: You can earn First Order Bonuses from Member Packs purchased on your 1st Level of 30%, 2nd Level of 10% and 3rd Level of 5%
                </li>
                <li>
                  Unlimited Width: No limit to the number of First Order Bonuses you can earn.
                </li>
                <li>
                  Precedence over Two for Free Bonus: First Order Bonus takes priority over the Two for Free Bonus.
                </li>
              </ul>
              <div className="btn-wrap">
                <a type="button" className="btn"
                  // href="https://commandconcept.csdevhub.com/media/pdf_files/Rewards_Plan_040324.pdf"
                  href="https://admin.shopkaire.com/media/pdf/Kaire_RewardsPlan_102924.pdf"
                  target="_blank">The full rewards plan</a>
                {userRank?.user_data?.userdetails?.[0]?.qualified_as_rank !== 3 && <button type="button" className="btn btn-2 " onClick={() => Validatelogin()}>Signup
                </button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </FirstBonusCss>
  )
}

export default FirstBonus

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import withRouter from "../../services/HOC/withRouterHOC";
import { NavLink, useNavigate } from "react-router-dom";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { FaUser, FaSignOutAlt } from 'react-icons/fa'
import { BarChart, MenuOpen } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ViewCartPopup from "../../Components/Common/ViewCartPopup";
import apiPaths from "../../services/apPaths";
import api from "../../api/Apis";
import { MenuDropDownPop } from "./NotFicationLogout";
import { HeaderCss } from "./HeaderStyle";

const Header = (props) => {
  const [AddClass, setAddClass] = useState(true);
  const navigate = useNavigate()
  const { customerCheck, subDomain } = props;
  const [open, setOpen] = useState(false);
  const [getProfileData, setGetProfileData] = useState();
  const [userRank, setUserRank] = useState();
  const [menuData, setMenuData] = useState([]);
  const [isActiveMobile, setIsActiveMobile] = useState("");



  useEffect(() => {
    const GetProfileData = localStorage.getItem("Token");
    setGetProfileData(GetProfileData);
    const RANK_DATA = localStorage?.getItem("profileData") ? JSON.parse(localStorage?.getItem("profileData")) : ""
    setUserRank(RANK_DATA);
    _menuSectionFunction();
  }, [getProfileData])



  const _menuSectionFunction = async () => {
    await api.MenuSectionOption().then((response) => {
      if (response?.status === 200 && response?.data?.code === 1) {

        const menuDataArray = response.data.menu_data || [];
        const filterdata = response.data.menu_data.filter((item) => item.id === 3);
        setMenuData(filterdata)
      }
    }).catch((err) => console.log(err))
  }
  const showlogin = () => {
    setAddClass(true);
    // props.setoldpath(router.asPath)
    navigate(`/login`)
    // if (router.push(`/us/login`)) {
    //     setTimeout(() => {
    //         setOpenLoginPopup(true)
    //     }, 2000);
    // }
  }
  // useEffect(() => {
  //   if (props?.isLogin === true) {
  //     navigate(`/`);
  //   }
  // }, [])
  //   useEffect(() => {
  //     const data = localStorage.getItem("Token")
  //     setToken(data)
  //     const dataref = JSON.parse(localStorage.getItem('correctRefferal'))
  //     setDomain(dataref ? props?.correctRefferal?.data?.name : dataref?.data?.name)
  // }, [router]);
  const token = localStorage.getItem("Token")

  const LogoutNav = ({ store, section, setAddClass, token, ...props }) => {
    return <li className={`logout_icon ${section}`} > {/* LOgout icon with pop up  */}
      {(props.isLogin === true || token !== null) &&
        <IconButton onClick={props.handleLogout}>
          Log out
          <FaSignOutAlt />
        </IconButton>
      }
    </li>
  }
  const popRef = React.useRef(null);
  const Validatelogin = (e) => {
    const DATAREF = localStorage.getItem("referral_code") ? localStorage.getItem("referral_code") : ""
    setAddClass(true)
    if (DATAREF) {
      navigate('/signup');
    }
    else if (localStorage.getItem("Token")) {
      navigate('/signup');
    }
    else {
      popRef.current.setRefferalPopup(true);
      //   popRef.current.setGoToPromoterPack(true);
      popRef.current.setCorrectRefferal({ status: false, data: {} });
      setOpen(true);
    }
  };
  return (
    <HeaderCss>
      <div className="container">
        <nav className="header-nav">
          <figure>
            <a href="/">
              <img src="/images/New-logo.png" alt="LoGo" />
            </a>
            {/* <a href="#">
              <img src="/images/small-logo.png" alt="Logo" />
              ShareKaire
            </a> */}
          </figure>
          {AddClass ?
            <button type="button" className="btn d-none" onClick={() => setAddClass(false)}>
              <MenuIcon />
            </button>
            :
            <button type="button" className="btn d-none" onClick={() => setAddClass(true)}>
              <CloseIcon />
            </button>
          }

          <div className={AddClass ? 'header-links' : "header-links active"}>
            <ul>
              {
                menuData?.map(({ title, data, url, check }, index) => (
                  <MenuDropDownPop title={title} url={url} innerData={data} key={index + 1} check={check}
                    setAddClass={setAddClass} setIsActive={setIsActiveMobile} isActive={isActiveMobile} />
                ))
              }

              <li onClick={() => setAddClass(true)}>
                <NavLink to="/ourstory">Our Story</NavLink>
              </li>
              <li onClick={() => setAddClass(true)}>
                <NavLink to="/reward" >Rewards Plan</NavLink>
              </li>
              {customerCheck !== 3 && <li onClick={() => Validatelogin()}>
                Join Now
              </li>}
              {/* </ul> */}
              {/* <ul className="nav navbar-nav navbar-right" onClick={() => { props?.updatecartdata(true) }}> */}
              <li className="left-slide cstm-drop">
                <>
                  {(props.isLogin === true || token !== null) ?
                    <>
                      <NavLink className="nav-link" exact="true" to={`${apiPaths.SHOKAIRE_URL}?token=${token}`} onClick={() => setAddClass(false)}>
                        <span className="account_title font_change">My Account</span>
                        <span className="account_icon font_change"><FaUser />
                        </span>
                      </NavLink>
                      <ul className="dashboard_div">
                        {/* <NavLink className="nav-link" exact="true" to={`/`}>
                        </NavLink> */}
                        <LogoutNav store={'us'} section="from_navBar_section"
                          {...props} key="NavbarSection3" token={token} />
                      </ul>
                    </>
                    :
                    <div className="login_signin">
                      {/* <a type="button" className="Register_cls" onClick={(e) => { Validatelogin(e) }} >Register  as distributor</a>*/}
                      {/*     <a type="button" className="Register_cls" href={`/${store}/signup/?d=distributor`} >Register as distributer</a>*/}
                      <a onClick={showlogin} style={{ cursor: "pointer", marginTop: '2px' }} className="font_change">Login</a>
                    </div>
                  }
                </>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <ViewCartPopup
        ref={popRef}
        content={{
          open: open,
          setOpen: setOpen,
          ...props,
        }} />
    </HeaderCss>
  );
};

export default Header;

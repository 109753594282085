import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

    :root {
        --cmn-gradient: linear-gradient(90deg, rgba(76,113,186,1) 0%, rgba(119,146,230,1) 50%, rgba(144,141,209,1) 100%);
        --cmn-black: #000000;
        --cmn-white: #ffffff;
        --cmn-blue: #00356a;
        --cmn-pink: #d689a5;
        --input-border: #dddddd;
        --grey-text: rgba(255,255,255,0.3);
        --form-border: #B0B0B0;
        --signup-bg: #f2f2f2;
        --active-pack: #efefef;
        --continue-text: #969696;
        --cstm-radio: #1976d2
        --cstm-delete: #ff0000;
    }
    h1,.h1 {
        font-size: 54px;
        font-family: var(--common-light-font);
        font-weight: 300;
        line-height: 1.2;
        margin-bottom: 20px;
        color:  var(--cmn-black);

        @media only screen and (max-width:1399px) {
            font-size: 50px;
        }

        @media only screen and (max-width:1199px) {
            font-size: 40px;
        }

        @media only screen and (max-width:767px) {
            font-size: 30px;
        }
    }
    h2,.h2 {
        font-size: 49px;
        font-family: var(--common-light-font);
        font-weight: 300;

        @media only screen and (max-width: 1399px) {
            font-size: 45px;
        }

        @media only screen and (max-width:1199px) {
            font-size: 37px;
        }

        @media only screen and (max-width:767px) {
            font-size: 28px;
        }

        span {
            font-weight: 300;
            font-size: 52px;
            display: block;
            line-height: 1.1;

            @media only screen and (max-width: 1399px) {
                font-size: 47px;
            }

            @media only screen and (max-width:1199px) {
                font-size: 39px;
            }

            @media only screen and (max-width:767px) {
                font-size: 29px;
            }
        }
    }
    h3,.h3 {
        font-size: 44px;
        font-family: var(--common-light-font);
        font-weight: 300;
        color:  var(--cmn-black);

        @media only screen and (max-width: 1399px) {
            font-size: 40px;
        }

        @media only screen and (max-width:1199px) {
            font-size: 32px;
        }

        @media only screen and (max-width:767px) {
            font-size: 26px;
        }

        small {
            font-size: 34px;
            display: block;

            @media only screen and (max-width: 1399px) {
                font-size: 30px;
            }

            @media only screen and (max-width:1199px) {
                font-size: 28px;
            }

            @media only screen and (max-width:767px) {
                font-size: 24px;
            }
        }
    }
    h4,.h4 {
        font-family: var(--common-font);
        color:  var(--cmn-black);
        font-size: 29px;

        @media only screen and (max-width: 1399px) {
            font-size: 27px;
        }

        @media only screen and (max-width:1199px) {
            font-size: 25px;
        }

        @media only screen and (max-width:767px) {
            font-size: 23px;
        }

        span {
            font-size: 135px;

            @media only screen and (max-width: 1399px) {
                font-size: 125px;
            }

            @media only screen and (max-width:1199px) {
                font-size: 100px;
            }

            @media only screen and (max-width:767px) {
                font-size: 75px;
            }
        }
    }
    h5,.h5 {
        font-size: 25px;
        font-family: var(--common-font);
        font-weight: 300;
        color:  var(--cmn-black);

        @media only screen and (max-width: 1399px) {
            font-size: 24px;
        }

        @media only screen and (max-width:1199px) {
            font-size: 22px;
        }

        @media only screen and (max-width:767px) {
            font-size: 20px;
        }
    }
    h6,.h6 {
        font-family: var(--common-font);
        font-size: 24px;
        line-height: 1.2;
        color:  var(--cmn-black);

        @media only screen and (max-width: 1399px) {
            font-size: 23px;
        }

        @media only screen and (max-width:1199px) {
            font-size: 21px;
        }

        @media only screen and (max-width:767px) {
            font-size: 19px;
        }

        small {
            font-size: 22px;
            line-height: 1.2;
            display: block;
            
            @media only screen and (max-width: 1399px) {
                font-size: 21px;
            }

            @media only screen and (max-width:1199px) {
                font-size: 20px;
            }

            @media only screen and (max-width:767px) {
                font-size: 18px;
            }
        }
    }
    p {
        font-size: 17px;
        line-height: 1.5;
        font-weight: 400;
        margin: 0;

        @media only screen and (max-width: 1399px) {
            font-size: 16px;
        }

        &:not(:last-child) {
            margin-bottom: 10px;
            
            @media only screen and (max-width: 1399px) {
                margin-bottom: 20px;
            }

            @media only screen and (max-width: 767px) {
                margin-bottom: 16px;
            }
        }



        small {
            font-size: 16px;
            line-height: 1.75;
            font-weight: 400;
            
            @media only screen and (max-width:1399px) {
                font-size: 14px;
            }
            @media only screen and (max-width:1199px) {
                line-height: 1.5;
            }
        }
    }

    a {
        text-decoration: none;
        transition: all ease-in-out 0.3s;
        cursor: pointer;
    }

    ul, ol {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            font-size: 16px;
            line-height: 1.75;
            font-weight: 400;

            @media only screen and (max-width:1199px) {
                line-height: 1.5;
            }
        }
    }

    .btn-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 12px;

        @media only screen and (max-width: 767px){
            gap: 8px;
        }
    }

    figure {
        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            display: block;
        }
    }

    .cstm-row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    [class^="col-"] {
        padding: 0 15px;
    }

    [class^="col-6"], [class^="col-7"], [class^="col-5"] {
        @media only screen and (max-width: 991px) {
            width: 100%;
        }
    }

    .container {
        padding: 0 15px;
        @media only screen and (min-width: 1400px) {
            max-width: 1200px;
        }
    }

    .btn {
        background: linear-gradient(90deg, rgba(2,55,109,1) 0%, rgba(94,136,236,1) 50%, rgba(209,137,168,1) 100%);
        font-size: 21px;
        line-height: 2;
        letter-spacing: 2px;
        border-radius: 50px;
        color: var(--cmn-white);
        min-width: 191px;
        border: none;
        padding: 5px 33px;
        transition: all ease-in-out 0.3s;
        box-shadow: none;   
        background-color: var(--cmn-white);

        @media only screen and (max-width: 1399px) {
            font-size: 19px;
        }

        @media only screen and (max-width: 1199px) {
            font-size: 16px;
            line-height: 1.75;
            padding: 5px 33px;
            letter-spacing: 1px;
            min-width: 171px;
        }
        
        &:hover {
            color:  var(--cmn-white);
            background: linear-gradient(90deg,  rgba(209,137,168,1) 0%, rgba(94,136,236,1) 50%, rgba(2,55,109,1) 100%);
        }

        &.btn-2 {
            background: var(--cmn-blue);
            
            &:hover {
                background: var(--cmn-pink);
                color: var(--cmn-white);
            }
        }

        &.btn-3 {
            background: var(--cmn-white);
            color: var(--cmn-black);
            
            &:hover {
                background: var(--cmn-pink);
                color: var(--cmn-white);
            }
        }

        &.btn-4 {
            background: transparent;
            color: var(--cmn-black);
            border: 2px solid var(--cmn-black);
            
            &:hover {
                background: var(--cmn-pink);
                color: var(--cmn-white);
                border-color: var(--cmn-pink);
            }
        }

        &.btn-5 {
            background: transparent;
            color: var(--cmn-white);
            border: 2px solid var(--cmn-white);
            
            &:hover {
                background: var(--cmn-pink);
                color: var(--cmn-white);
                border-color: var(--cmn-pink);
            }
        }

        &.btn-6 {
            border: 1px solid var(--cmn-blue);
            color: var(--cmn-blue);
            background: transparent;
            
            &:hover {
                background: var(--cmn-blue);
                color: var(--cmn-white);
            }
        }
    }

    .text-btn {
        font-size: 19px;
        color: var(--continue-text);
        font-style: italic;
        text-decoration: underline;
        text-underline-offset: 2px;
        transition: all ease-in-out 0.3s;
        
        @media only screen and (max-width: 1199px) {
            font-size: 17px;            
        }
        
        @media only screen and (max-width: 767px) {
            font-size: 16px;            
        }

        &:hover {
            color: var(--cmn-blue);            
        }
    }

    .d-none {
        display: none;

        @media only screen and (max-width: 767px) {
            display: block;
        }
    }

.bt-spinner {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: transparent;
  border: 4px solid #ddd;
  border-top-color: #00356a;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}
.flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: fixed;
  z-index: 999;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  left: 50%;
  justify-content: center;
}

.flexbox>div {
  width: 300px;
  height: 300px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: auto;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}
.flexbox:before {
  content: "";
  width: 100%;
  height: 100vh;
  background: rgb(255 255 255 / 50%);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.trade-mark {
    font-size: 26px;
    line-height: 22px;
}

.MuiDialog-container {
    .view_cart_popup {
        max-width: 1020px;
        width: 100%;
    }
}

#qty {
    max-width: 90px;
    border:1px solid #000;
    margin: 20px auto 0;
    display: flex;
    flex-wrap: wrap;

    &[disabled] {
        opacity: 0.3;
        pointer-events: none;
    }

    input {
        width: calc(100% - 48px);
        border: none;
        border-radius: 0;
        border-left: 1px solid var(--cmn-blue);
        border-right: 1px solid var(--cmn-blue);
        font-size: 16px;
        line-height: 1;
        font-weight: 400;
        text-align: center;
        height: 24px;
        color: var(--cmn-blue);
        padding: 0;
    }
    
    button {
        background: transparent;
        border: none;   
        padding: 0;
        font-size: 16px;
        line-height: 1;
        font-weight: 500;
        width: 24px;
        color: #000;
    }

    & + .edit-del {
        margin-top: 5px;
    }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.added_coupon {
        background: linear-gradient(90deg, rgba(2, 55, 109, 1) 0%, rgba(94, 136, 236, 1) 50%, rgba(209, 137, 168, 1) 100%);
        padding: 10px 20px;
        border-radius: 0px;
        font-size: 14px;
        color: #fff;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;  

        @media only screen and (max-width: 767px){
          padding: 10px 20px;
        }

        .dlt_btn {
          color: #ff0000;
          background: transparent;
          border: none;
          padding: 0;
          height: 16px;
          width: 16px;

          svg {
            height: 100%;
            width: 100%;
            display: block;
          }
        }
      }

@media (min-width: 768px) {
  .for_mobile_view, .mobile_view{
    display: none;
  }

}

@media (max-width: 767px) {
  .for_desktop_view, .desktop_view{
    display: none;
  }

  .row.report_all_in_one [class*="col-md"] {
    padding: 0;
  }

  .address-list .right-side-contents p {
    white-space: break-spaces;
    line-height: normal;
  }

  .table-wrap {
    margin-top: 15px;
  }

  .mainorder-detail-sec {
    margin-top: 0;
  }

  .content_container>* {
    max-width: 100% !important;
    width: 100% !important;
    flex: 100%;
  }

  .order_details_main_dialog .content_container {
    flex-wrap: wrap !important;
  }

  .dashboard_main_div.main_class_responsive .container.order-detail-page.p-4 [class*="couponstyle__C"] {
    padding: 0 20px 20px;
  }

  .dashboard_main_div.main_class_responsive .container.order-detail-page.p-4 [class*="couponstyle__C"] svg.MuiSvgIcon-root {
    margin-right: 0;
  }

  div.mainorder-detail-sec {
    padding: 76px 0 25px !important;
  }

  .Toal-commission span {
    right: 11px;
  }

  .statis_datas .commission-table {
    width: 100%;
  }

  .container.order-detail-page {
    height: auto;
    min-height: auto;
  }

  .dialog_scrollClass p {
    font-size: 16px;
    line-height: normal;
  }

  .commission-table tbody tr:not(:last-child) {
    margin-bottom: 30px;
  }

  .order_table.address-list.order-list.auto-ship-order-table {
    border: none;
  }
  	
  .bogo-banner img {
    display: none;
  }
  .main_div .bogo-banner .for_mob {
    display: block;
  }
}

.distributer_tooltip .MuiTooltip-tooltip {
    padding: 10px;
}

.tooltip-wrap h4 {
    color: #ffff;
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 4px;
}

.tooltip-wrap p, .tooltip-wrap p a  {
    font-size: 12px;
    line-height: 1.4;
    color: #fff;
    font-weight:400;
}

.tooltip-wrap p a  {
    text-decoration: underline;
    font-weight: 600;
}

.tooltip-wrap p a:hover {
    text-decoration: none;
}

.tooltip-wrap:not(:last-child) {
    margin-bottom: 16px;
}

`;

export default GlobalStyle;

import React, { useEffect } from 'react';
import LoginStyle from './LoginStyle';
import { useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import * as yup from 'yup';
import _ from 'lodash';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import api from '../../api/Apis';
import withRouter from '../../services/HOC/withRouterHOC';

const Login = (data) => {

    const props = useOutletContext();

    useEffect(() => {
        if (props?.isLogin === true) {
            window.location.assign("/")
        }
    }, [])
    const [passwordShow, setPasswordShow] = useState(false);
    const INITIALVALUES = {
        "email": "",
        "password": "",
    }
    const schema = yup.object().shape({
        email: yup.string().trim().required('This field is required.').email('Please enter vaild email.'),
        password: yup.string().trim().required('This field is required.').min(6, "Please enter minimum 6 characters."),
    });

    // async function onSubmit(data) {
    //     await api.loginUser(data).then((res) => {
    //         if (res?.data?.code == 1) {
    //             // if (cartdata?.products?.length > 0) {
    //             //     router.push("/us/checkout/addresslist");
    //             // } else {
    //             //     router.push("/us/user/dashboard/");
    //             // }

    //             setInnerLoader(false);
    //             //   subdomainUserCheckFunction(res.data.token);
    //             //   setisLogin(true);
    //             localStorage.setItem("Login", true);
    //             localStorage.setItem("Token", res.data.token);
    //             //   getMiniCartProduct("onLoadData", res?.data?.token);
    //             //   WishlistAllCart(res?.data?.token);
    //             ProfileFunction(res?.data?.token);
    //             setCustomerCheck(
    //                 res?.data?.user_data?.userdetails?.[0]?.qualified_as_rank
    //             );
    //             // toast('Successfully Login', { duration: 5, type: "success", });
    //         }

    //         if (res?.data?.code === 0 && res?.data?.message) {
    //             setError("email", {
    //                 type: res?.data?.message,
    //                 message: res?.data?.message,
    //             });
    //             setInnerLoader(false);
    //         }
    //     });
    // }
    function onSubmit(values) {
        props?.LoginSubmit(values)
    }

    return (
        <>
            {/* <Head>
                <title>Login</title>
            </Head> */}
            <LoginStyle>
                <div className="container">
                    {/* {props?.isLogin !== true ? */}
                    {/* <form className="signupform main-sign-frm" 
                        // onSubmit={handleSubmit(_callFunction)}
                        > */}
                    <Formik
                        enableReinitialize
                        initialValues={
                            INITIALVALUES
                        }
                        validationSchema={schema}
                        onSubmit={(values) => onSubmit(values)}
                    >
                        {({ values, handleChange, setFieldValue, setValues, handleSubmit, setErrors }) => (
                            <Form className="signupform main-sign-frm">

                                <>
                                    <h1 className="title">Login</h1>
                                    <div className="mb-3 field-class">
                                        <label htmlFor="exampleInputEmail1" className="form-label"> Username or Email Address</label>
                                        {/* <input type="text" className="form-control" 
                                    // onBlur={(e) => onBlurChange(e)}
                                        // ref={register({
                                        //     required: "Please enter your email",
                                        //     pattern: {
                                        //         value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                                        //         message: "Invalid Email Address"
                                        //     },
                                        // })}
                                         name="email" id="exampleInputEmail1" aria-describedby="emailHelp" /> */}
                                        <Field
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            id="exampleInputEmail1"
                                            aria-describedby="nameHelp"
                                            placeholder="Email"

                                        />

                                        {/* {errors.email?.message && <span className="error">{errors.email.message}</span>
                                    } */}
                                        <ErrorMessage name={'email'} render={(msg) => <span className='error'>{msg}</span>} />
                                        {props?.loginError && <span className='error'>{props?.loginError}</span>}

                                    </div>

                                    <div className="mb-3 field-class">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                        {/* <input type={passwordShow ? "text" : "password"} className="form-control" name="password" id="exampleInputPassword1" 
                                    // ref={register({ required: true })} 
                                    /> */}
                                        {/* {
                                        passwordShow ?
                                            <Visibility onClick={() => showPassword()} /> : <VisibilityOff onClick={() => showPassword()} />
                                    }

                                    {errors.password && <span className="error">This field is required</span>} */}
                                        <Field
                                            type={passwordShow ? "text" : "password"}
                                            className="form-control"
                                            name="password"
                                            id="exampleInputPassword1"
                                            aria-describedby="nameHelp"
                                            placeholder="Password"

                                        />
                                        <ErrorMessage name={'password'} render={(msg) => <span className='error'>{msg}</span>} />

                                    </div>

                                    <div className="sign-up-bnt">
                                        <button type="submit" className="btn btn-primary login">Login</button>
                                    </div>

                                    {/* <div className="signupandforget">
                                        <>

                                            <div className="forgot password">
                                                <Link className="nav-link" exact

                                                >
                                                    <a>Forgot Password</a>
                                                </Link>
                                            </div>
                                        </>

                                    </div> */}
                                </>
                                {/* <div className="signupandforget">
                                <div className="forgot password">
                                    <Link className="nav-link" 
                                    // onClick={handleMagicLink} 
                                    >
                                        <a>Send Login Link to My Email</a>
                                    </Link>
                                </div>
                            </div> */}
                            </Form>
                        )}
                    </Formik>
                    {/* </form> */}
                    {/* :
                        <div className="empty_div_section"></div> */}
                    {/* // } */}
                </div>
            </LoginStyle>
            {/* <SvgAnimationBackground />
            <MagicLinkPopUp open={open} setOpen={setOpen} handleClose={handleClose} /> */}
        </>
    )
}

export default Login;
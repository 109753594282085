import styled from "styled-components";

const MinCartStyle = styled.div`
  z-index: 9999;
  position: relative;
  && .mini-cart-sec {
    .cart-heading {
      position: relative;
      h1 {
        margin: 0;
      }
      button {
        position: absolute;
        left: 0px;
        top: 0px;
        background: transparent;
        border: 0;
        color: #fff;
        font-weight: bold;
        background: #fff;
        color: #06356a;
        width: 30px;
        height: 100%;
        font-size: 15px;
        border-radius: 0;
        padding: 0;
        /* box-shadow: 0 0 0 #000, 0 0 16px #000; */
        /* border-bottom: 1px solid #ddd; */
      }
    }
    .Cart_product{
    .checkout-btn {
      button.btn.btn-primary { 
        display: table;
        float: none;
        text-align: center;
        justify-content: center;
        margin: 0 auto;
        color: #fff;
        max-width: fit-content;
        width: 100%;
        border-radius: 25px;
        border: 2px solid var(--blue);
        transition: 0.3s ease all;
        background-color: var(--blue);
        padding: 8px 30px;
        font-size: 16px;
        transition: 0.3s ease all;  
        &:hover{
          background-color: #fff;
          color: var(--blue);
        }
    } 
  }
}

    .mini-cart { 
      .min-cat-uppper {
        .cart-image {
          img {
            mix-blend-mode: multiply;
          }
        }
        .mini-cart-des {
          .cart-product-details {
            .box {
              width: 100%;
              .select {
                display: table;
                width: 100%;
              }
            }

            &:first-child {
              color: var(--blue);
            }

            .title{
              &>div {
                display: flex;
              }
            }

            .price-beforediscount {
              text-decoration: line-through;
              color: #ff0000;
              margin-left:5px;
            }
          }
          .main-qty-sec {
            .box {
              #qty {
                max-width: 80% !important;
                display: flex;
                justify-content: center;
                border: 1px solid var(--blue);
                button {
                  background: #fff;
                  border-radius: 0 !important;
                  width: 32px;
                  font-size: 19px;
                  font-weight: 600;
                }
                input {
                  width: 40px;
                  background: #fff;
                  border-left: 1px solid var(--blue);
                  border-right: 1px solid var(--blue);
                }
                .sub {
                  left: 0;
                }
                .add {
                  right: 0;
                }
              }
            }
          }
        }
      }
      .cart-product-qty-del {
        // bottom: 32px!important;
        svg {
          font-size: 26px;
        }
        button.dlt {
          background-color: #fff;
          border-radius: 0px;
          font-size: 0px;
          font-weight: bold;
          line-height: 1;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          border: 1px solid red;
          padding: 0;
          color: red;
          border-radius: 50%;
          &:before {
          }
          svg {
            width: 20px;
            height: 20px;
            fill: red;
          }
        }
      } 
      .free_product_title {
        padding: 6px;
        background: #f00;
        width: 100%;
        /* max-width: 315px; */
        border: none;
        margin-top: 5px;
        /* @media (max-width: 767px){
          max-width: 266px;
        } */
        p {
          margin: 0;
          font-size: 20px;
          font-family: "Graphik-bold";
          color: #fff;
          text-align: center;
        }
      }
    }

    .checkout-btn {
      bottom: 0; 
      .col-md-12{
        padding: 0;
      }
      .d-flex.btn-flex {
        width: 100%;
        padding: 0;
      button.btn.btn-primary {
        width: 100%;
        border-radius: 50px;
        font-size: 14px;
        background: #06356a;
        border: 2px solid #06356a;
        height: 44px;
        max-width: fit-content;
        min-width: auto;
        letter-spacing: normal;
        margin: 0px auto;


        &:hover {
          background: #fff;
          color: #06356a;
        }
        
      }
      }
    }
   
  }


.mini-cart-sec {
  position: fixed;
  right: 0;
  top: 0;
  background: #ffff;
  width: 376px;
  box-shadow: 0 0 10px 1px #ddd;
  height: calc(100vh);

  @media only screen and (max-width: 480px){
    width: 340px;
  }
}

.mini-cart-sec:before {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgb(0 0 0 / 60%);
  z-index: -1;
}

.mini-cart-sec .container {
  padding: 0;
  background: #fff;
  height: 100%;
}

.mini-cart-sec .container h1.title {
  background: #06356a;
  color: #fff;
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  padding: 10px 0;
}

.mini-cart-sec .container .min-cat-uppper {
  display: flex;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  padding: 10px 15px 20px;
  position: relative;
  align-items: center;
}

.mini-cart-sec .container .mini-cart .cart-image {
  width: 30%;
}

.mini-cart-sec .container .mini-cart .mini-cart-des {
  width: 70%;
  float: left;
  padding-left: 10px;
  text-align: left;
}

.mini-cart-sec .container button.btn.btn-primary {
  margin: 0 auto;
  display: table;
  float: none;
}

.mini-cart {
  position: relative;
}

.mini-cart-sc {
  overflow-y: auto;
  height: calc(100vh - 240px);
}

.mini-cart-sec .container .mini-cart .cart-image img {
  width: 100%;
}

.mini-cart-sec .container .mini-cart .cart-product-qty-del {
  position: absolute;
  right: 0;
  top: 0;
  /* left: 0;
  margin: 0 auto;
  text-align: center; */
}

.mini-cart-sec .container .mini-cart .cart-product-qty-del button {
  background: transparent;
  color: #000;
  font-size: 13px;
  width: 100%;
  height: auto;
  background: transparent !important;
  color: red;
  padding: 0;
  border:none;

    svg {
    font-size: 26px;
    width: 14px;
  }
}

.mini-cart-sec .cart-heading {
  margin-bottom: 0;
  margin-top: 0;
}

.mini-cart-sec .container .mini-cart .mini-cart-des .cart-product-details {
  font-weight: 600;
  margin-bottom: 6px;
  font-size: 15px;
}

.mini-cart-sec .container .mini-cart .mini-cart-des .main-qty-sec {
  justify-content: flex-start;
  margin-bottom: 5px;
  margin-left: 0;
}

.main-qty-sec {
    margin: 0 -15px 15px;
    display: flex;
}

.mini-cart-sec .container .mini-cart .mini-cart-des .main-qty-sec button {
  color: var(--blue);
  border-radius: 0;
  background: #fff;
  padding: 10px;
  width: 50px;
  height: 40px;
  font-weight: 800;
  border: 1px solid var(--blue);
}

.main-qty-sec .box #qty button {
    top: 0;
    bottom: 0;
    width: 22px;
    height: 37px;
    background: transparent;
    color: #000;
    border: 0;
    padding: 0;
}

.mini-cart-sec .mini-cart .min-cat-uppper .mini-cart-des .main-qty-sec .box #qty button {
    background: rgb(255, 255, 255);
    width: 32px;
    font-size: 19px;
    font-weight: 600;
    border-radius: 0px !important;
}

.mini-cart-sec .mini-cart .min-cat-uppper .mini-cart-des .main-qty-sec .box #qty .sub {
    left: 0px;
}

.mini-cart-sec .mini-cart .min-cat-uppper .mini-cart-des .main-qty-sec .box #qty .add {
    right: 0px;
}

.mini-cart-sec .container .mini-cart:nth-child(2n) {
  background: #ececec;
}

.mini-cart-sec .checkout-btn {
  width: 100%;
  float: left;
  position: absolute;
  padding: 0 15px;
  bottom: 0;
  left: 0;
}

.mini-cart-sec .checkout-btn .row {
  margin: 0;
}

.mini-cart-sec .checkout-btn .row ul {
  width: 100%;
  margin: 40px 0;
}

.mini-root-div {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #00356a;
  padding: 13px;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
}

.mini-root-div .fa.fa-shopping-cart {
  color: #fff;
  font-size: 24px;
}

.mini-root-div span {
  position: absolute;
  top: 8px;
  right: 4px;
  background: #f00;
  color: #fff;
  font-size: 14px;
  width: 17px;
  height: 17px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mini-cart-sec .container .mini-cart .mini-cart-des .main-qty-sec .box {
  margin: 0;
  max-width: 70%;
  flex: 0 0 70%;
  -ms-flex: 0 0 70%;
  padding-left: 0;
}

.mini-cart-sec .select {
  width: 70%;
}

.mini-cart-sec .select::after {
  margin-top: -11px;
}

.mini-cart-sec select {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid #ddd;
  margin: 0;
  height: 35px;
  padding: 0 15px;
  font-size: 14px;
  height: 40px;
  border-radius: 5px;
  width: 100%;
  padding-left: 10px;
  border-color: #ddd;
  font-weight: 600;
  margin: 0;
}

.mini-cart-sec .Cart_product>h3.title {
  text-align: center;
  padding: 15px 0 0;
  font-size: 16px;
  color: #f00;
}

.Cart_product .row {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  margin-bottom: 15px;
  align-items: center;
}

.Cart_product div#qty {
  max-width: 100% !important;
  flex: 0 0 100% !important;
  -ms-flex: 0 0 70%;
}

.main-qty-sec .box #qty {
    position: relative;
    border: 1px solid var(--blue);
    align-items: center;
}

.main-qty-sec .box:last-child #qty {
    max-width: 70%;
    flex: 0 0 70%;
    -ms-flex: 0 0 70%;
}

.mini-cart-sec .mini-cart .min-cat-uppper .mini-cart-des .main-qty-sec .box #qty {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    border: 1px solid var(--blue);
    max-width: 80% !important;
    margin: 0;
}



.Cart_product {
  background: #fff;
  padding: 10px 20px;
  margin-bottom: 0;

  @media (max-width: 767px) {
      margin-bottom: 30px;
  }

}


.main-qty-sec .box #qty input {
    width: 20%;
    border: 0;
    height: 37px;
    text-align: center;
    background: #fff;
}

.mini-cart-sec .mini-cart .min-cat-uppper .mini-cart-des .main-qty-sec .box #qty input {
    width: 40px;
    background: rgb(255, 255, 255);
    border-left: 1px solid var(--blue);
    border-right: 1px solid var(--blue);
}

.cart-product-details {
    display: flex;
    align-items: center;
}

.dsxUgV.dsxUgV .mini-cart-sec .mini-cart .min-cat-uppper .mini-cart-des .cart-product-details .title > div {
    display: flex;
}

.cart-product-details .title div {
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
}

.checkout-btn ul li {
  text-align: right;
  padding: 0 0 15px;
  margin: 0 0 15px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  line-height: 20px;
}

.checkout-btn ul li:last-child {
  border-bottom: 0;
  padding: 0;
  margin: 0;
}

.checkout-btn ul li strong {
  float: left;
  font-weight: 600;
  text-transform: uppercase;
}

.d-flex {
    display: flex;
    gap: 10px;
}


`;

export default MinCartStyle;

import styled from "styled-components";

const AccountTypeLogin = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 0 34px 24px;
  .login-section-divider {
    border: 1px solid #858585;
    width: 100%;
    margin-top: 45px;
    margin-bottom: 24px;
  }
  .ic-pc-button-label {
    margin: 30px auto;
  }
`;

const TextH4 = styled.h4`
  padding-bottom: ${(props) => (props.section ? "16px" : "32px")};
  font-size: 24px;
  font-family: var(--common-font-bd);
  color: #252525;
  line-height: normal;
  letter-spacing: normal;
`;

const TextH2 = styled.h2`
  font-size: 36px;
  font-weight: 400;
  font-family: var(--common-font-bd);
  color: var(--blue);
  line-height: 1.17;
  min-height: 41px;
  letter-spacing: -0.43px;
`;

const TextSmallBold = styled.div`
  font-size: 16px;
  font-family: var(--common-font-bd);
  color: #252525;
  line-height: 1.56;
  letter-spacing: 0.16px;
  padding-top: 16px;
`;
const Button = styled.button`
  padding: 14px ${(props) => (props.section === "login" ? "90px" : "62px")};
  font-family: var(--common-font);
  font-size: 17px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.06px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  min-width: 153px;
  border-radius: 50px;
  background: var(--blue);
  border: 2px solid var(--blue);
  width: 100%;
  transition: 0.3s ease all;
  &:hover {
    background: #fff;
    color: var(--blue);
    text-decoration: none;
  }
  @media (max-width: 767px) {
    padding: 13px 0;
    font-size: 14px;
  }
`;

const LoginSectionContainer = styled.div`
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  padding-bottom: 16px;
  .ic-pc-container {
    .heading:empty {
      display: none;
    }
    @media (max-width: 767px) {
      flex: 100%;
      max-width: 100%;
    }
  }
  .ic-pc-button-label {
    margin: 0;
    display: flex;
  }
`;

const RefferalContainer = styled.div`
  padding: 20px 40px 23px;
  @media (max-width: 767px) {
    padding: 20px 0 0;
  }

  p.ref_msg_new {
    font-size: 20px;
    margin-bottom: 0;
    line-height: 35px;
    @media (max-width: 767px){
      font-size: 17px; 
      line-height: 1.5;
    }
    @media (max-width: 576px){
      font-size: 16px; 
    }
    span {
        display: block;
    }
  }
  .subtitle {
    @media (max-width: 767px) {
      padding-top: 0;
    }
  }
  h2.dialog_title_for_all{
    font-size: 25px; 
    text-transform: capitalize;
    @media (max-width: 767px) {
      font-size: 14px; 
    }  
  }
  h3.ref_title {
    margin-bottom: 12px;
    text-align: center; 
    img {
      max-width: 35vw;
      width: 100%;
      @media (max-width: 991px){ 
          max-width: 100%; 
      }
    }
    @media (max-width: 767px) {
      font-size: 20px;
    }
    .close_button{
    padding: 0;
    right: 13px;
    top: 50%;
    width: 40px;
    border-radius: 0 !important;
    transform: translateY(-50%);
    color: #fff;
    } 
  }
  h4.ref_subtitle {
    color: #919192;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.02em;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    @media (max-width: 991px) {
      font-size: 18px;
      margin-top: 0;
    }
    @media (max-width: 991px) {
      font-size: 17px; 
    }
  }
  .text-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 500px;
    border-radius: 50px;
    margin: 40px auto 20px;
    border: 2px solid #ddd;
    overflow: hidden;
    position: relative;
    justify-content: flex-start;
    @media (max-width: 991px){
      margin: 20px auto;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      overflow: visible;
      border: none;
    }
  }
  .ref_input-form {
    max-width: 100%;
    width: 100%;
    padding-right: 200px;
    input {
      margin: 0 !important;
      height: 50px;
      font-style: normal;
      font-weight: 400; 
      font-size: 18px;
      line-height: normal;
      border: none;
      color: #131313;
      box-shadow: none;
      max-width: 100%;
      width: 100%;
      @media (max-width: 767px) {
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 30px;
        font-size: 18px;
        margin-bottom: 15px !important;
      }
    }
    @media (max-width: 767px) {
      padding-right: 0px;
    }
  }
  button {
    font-style: normal;
    border-radius: 50px;
    border: 2px solid var(--blue);
    max-width: 200px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    height: 50px;
    background-color: var(--blue);
    transition: 0.3s ease all;
    position: absolute;
    right: 0;
    &:hover {
      color: var(--blue);
      background: #fff;
    }
    @media (max-width: 767px) {
      position: unset;
      width: 100%;
      max-width: 100%;
    }
  }

  p {
    text-align: center;
    font-size: 18px;
    font-family: 'Graphik';
    /* &.ref_msg_new {
      background: #ffefd6cf;
      border-radius: 5px;
      @media (max-width: 767px) {
        padding: 10px;
      }
    } */
    &.dont_haveCode {
    cursor: pointer;
    font-size: 24px;
    font-weight: 900;
    text-decoration: underline;
    color: #337ab7;
    text-underline-offset: 5px; 
    /* font-family: 'Graphik-bold'; */

    @media (max-width: 991px){
      font-size: 18px;
    }
      &:hover {
        color: var(--blue);
        text-decoration: underline;
      }
    }
    &.error_msg {
      font-style: normal;
      line-height: 21px;
      color: red;
    }
  }
`;

const CurrentRefferal = styled.div`
  text-align: center;
  h5.refby-title {
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.01em;
    color: #919192;
  }
  img.img-circle {
    border-radius: 50%;
    max-width: 72px;
    margin-bottom: 7px;
  }
  h4.refby-name {
    margin-bottom: 5px;
    font-size: 36px;
    line-height: 100%;
    letter-spacing: -0.01em;
  }
  a {
    &.refby-contact-info {
      font-size: 16px;
      line-height: 150%;
      color: #919192;
    }
  }
  .refby_button {
    height: 47px;
    min-width: 240px;
    border-radius: 50px;
    max-width: fit-content;
    background: var(--blue);
    color: #fff;
    border: none;
    font-size: 16px;
    line-height: normal;
    letter-spacing: 0.05em;
    padding: 14px 52px;
    border: 2px solid var(--blue);
  }
  .refby-btn {
    padding-top: 26px;
    padding-bottom: 22px;
  }
  .refby-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  button.ref-confirm-no {
    font-size: 16px;
    padding: 0;
    line-height: 150%;
    color: #131313;
    border-bottom: 1px solid #131313;
    border: none;
    background: none;
    border-bottom: 1px solid #000;
    border-radius: unset;
    margin: 10px;
  }

  p {
    &.refby_note {
      padding-top: 20px;
      padding-bottom: 18px;
      font-size: 14px;
      line-height: 150%;
      margin: 0;
    }
  }
`;

const PromoterPackInViewCartPop = styled.div`
  && .promoter_pack_container {
    width: 100%;
    max-width: calc(100% / 3 - 40px);
    margin: 0 auto;
    flex: 1;
    button {
      color: #fff;
      border: 2px solid var(--blue);
      transition: 0.3s ease all;
      span {
        font-weight: 600;
      }
      &:hover {
        background: #fff;
        color: var(--blue);
      }
    }
    @media (max-width: 767px) {
      max-width: 100%;
      margin: 0;
    }
  }
`;

export default AccountTypeLogin;
export {
  TextH4,
  TextH2,
  Button,
  LoginSectionContainer,
  TextSmallBold,
  PromoterPackInViewCartPop,
  RefferalContainer,
  CurrentRefferal,
};

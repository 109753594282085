import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from '../../api/Apis';
import Cookie from "js-cookie";
import Footer from '../../Layout/Footer';

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();

        const [innerLoader, setInnerLoader] = useState(false);
        const [showloader, setshowloader] = useState(false);
        const [activeStep, setActiveStep] = useState(0);
        const [cartdata, setCartData] = useState([]);
        const [addNotify, setAddNotify] = useState("");
        const [smartShipCheck, setSmartShipCheck] = useState(false);
        const [isLoginToken, setisLoginToken] = useState();
        const [counts, setcounts] = useState();
        const [promoterPackAvailability, setPromoterPackAvailibilty] =
            useState(false);
        const [isLogin, setisLogin] = useState();
        const [updatecartdata, setupdatecartdata] = useState(false);
        const [customerCheck, setCustomerCheck] = useState(1);
        const [showprotectedpage, setShowProtectedPage] = useState(false);
        const [isLogout, setisLogout] = useState();
        const [profileData, setProfileData] = useState();
        const [loginError, setLoginError] = useState();
        const [correctRefferal, setCorrectRefferal] = useState({
            status: false,
            data: {},
        });
        const [amountCalculations, setAmountCalculations] = useState();
        const [coupon, setcoupon] = useState();
        const [amountAfterDiscount, setAmountAfterDiscount] = useState('');

        const [validReferralCheck, setValidReferralCheck] = useState(false);
        const [subDomain, setSubDomain] = useState("");
        const [signupDefualtValue, setDefaultValue] = useState({});
        const getcode = window.location.search;
        const code = getcode.split("?token=")[1];
        const [autoshipOrderId, setAutoshipOrderId] = useState('')
        const [showminicart, setshowminicart] = useState(false);
        function generateRandomString(length) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return result;
        }
        useEffect(() => {
            setisLogin(
                localStorage.getItem("Login")
                    ? JSON.parse(localStorage.getItem("Login"))
                    : false
            );
            const formdata = localStorage.getItem("Token")
                ? localStorage.getItem("Token")
                : "";
            setisLoginToken(formdata);
            if (formdata === "" || formdata === undefined) {
                if (Cookie.get("sessionkey")?.length !== 16) {
                    Cookie.set("sessionkey", generateRandomString(16));
                }
            }

            if (updatecartdata) {
                WishlistAllCart(formdata);
                setupdatecartdata(false);
            }
            ProfileFunction(formdata);
            formdata !== "" || (isLogin === true && validateauth(formdata));
            code !== undefined && setshowloader(true);
            if (code !== undefined) {
                api.LoginCheckByurl(code).then((res) => {
                    if (res?.data?.code === 1) {
                        setisLogin(true);
                        localStorage.removeItem("profileData");
                        localStorage.setItem("Login", true);
                        localStorage.setItem("Token", code);
                        ProfileFunction(code);
                        window.location.assign(`/signup`);
                        setTimeout(() => {
                            setshowloader(false);
                        }, 5000);
                    } else {
                        setshowloader(false);
                    }
                })
            };
        }, [isLogin, updatecartdata, code]);
        const validateauth = async (token) => {
            await api.LoginCheck(token || isLoginToken).then((res) => {
                if (res?.data?.code === 0) {
                    localStorage.removeItem("Token");
                    localStorage.removeItem("address_id");
                    localStorage.removeItem("Login");
                    setisLogin(false);
                    setisLogout(true);
                    setShowProtectedPage(false);
                    // toast.error(res?.data?.message);
                    // window.location.assign(`/`);
                }
                if (res?.data?.code === 1) {
                    setShowProtectedPage(true);
                }
            });
        };
        async function WishlistAllCart(token) {
            // setshowloader(true)
            if (token) {
                await api.getAllCartProduct(token).then((res) => {
                    if (res?.status === 200 && res?.data?.code === 1) {
                        const dataSss = localStorage.getItem("packProduct")
                            ? JSON.parse(localStorage.getItem("packProduct"))
                            : null;
                        let QTYCOUNTS = res?.data?.products?.reduce(
                            (prevValue, currentValue) =>
                                +prevValue + (+currentValue?.quantity + currentValue?.extra_qty),
                            0
                        );
                        let couuntValue = dataSss !== null ? +QTYCOUNTS + 1 : +QTYCOUNTS;
                        setcounts(couuntValue);
                        // setcounts(res?.data?.products?.length)
                        let data = _.map(res?.data.products, ({ product }) => +product?.id);
                        setAddNotify(data);
                        // setshowloader(false)
                    }
                });
            }
        }




        async function getMiniCartProduct(section = "", logintoken) {
            // setshowloader(true)

            await api.getAllCartProduct(logintoken).then((res) => {
                if (res?.status === 200) {
                    const dataSss = localStorage.getItem("packProduct")
                        ? JSON.parse(localStorage.getItem("packProduct"))
                        : null;


                    // setshowloader(false)
                    if (res?.data?.code === 1) {
                        // const CART_DATA_ARRAY = BYthreeGetONeFree(res?.data);
                        // setCartData(CART_DATA_ARRAY);

                        setCartData(res?.data);
                        const filterData = _.filter(res?.data?.products, {
                            is_autoship: "True",
                        });
                        const promoterCheck = _.find(res?.data?.products, { promoter: true });
                        let smartShipOnCart = filterData?.length > 0 ? true : false;
                        setSmartShipCheck(smartShipOnCart);
                        let data = _.map(res?.data.products, ({ product }) => +product?.id);
                        setAddNotify(data);

                        if (promoterCheck) {
                            setPromoterPackAvailibilty(true);
                        } else {
                            setPromoterPackAvailibilty(false);
                        }
                        let QTYCOUNTS = res?.data?.products?.reduce(
                            (prevValue, currentValue) =>
                                +prevValue + (+currentValue?.quantity + currentValue?.extra_qty),
                            0
                        );
                        let couuntValue = dataSss !== null ? +QTYCOUNTS + 1 : +QTYCOUNTS;
                        setcounts(couuntValue);
                        // if (section === "onLoadData") {
                        // let couuntValue = (dataSss !== null) ? +counts + 1 : +counts
                        // setcounts(couuntValue)
                        // }
                    } else {
                        setSmartShipCheck(false);
                        // setcounts([]);
                        if (dataSss === null) {
                            setCartData(res?.data);
                            setcounts(0);
                        } else {
                            setcounts(1);
                            setCartData({});
                        }
                    }
                }
            });
        }
        async function ProfileFunction(token) {
            if (token) {
                const data = localStorage.getItem("profileData");
                if (data) {
                    const values = JSON.parse(data);
                    setProfileData(values);
                    // setProfileData(+values?.user_data?.userdetails?.[0]?.paid_as_rank)
                    // setMiniCartAutoshipCheck(
                    //     values?.user_data?.userdetails[0]?.is_autoship_user
                    // );
                    setCustomerCheck(
                        +values?.user_data?.userdetails?.[0]?.qualified_as_rank
                    );
                    setInnerLoader(false);

                } else {
                    setInnerLoader(true);
                    await api.getMyProfileDetails(token).then((res) => {
                        if (res?.data?.code === 1) {
                            setInnerLoader(false);
                            localStorage.setItem("profileData", JSON.stringify(res?.data));
                            localStorage.setItem("profileDetail", JSON.stringify(res?.data?.user_data?.user_addresses[0]));
                            localStorage.setItem("shippingAddress", res?.data?.user_data?.user_addresses[0]?.id);
                            setProfileData(res?.data);
                            setDefaultValue(res?.data?.user_data?.user_addresses[0])
                            // setMiniCartAutoshipCheck(
                            //     res?.data?.user_data?.userdetails[0]?.is_autoship_user
                            // );
                            setCustomerCheck(
                                +res?.data?.user_data?.userdetails?.[0]?.qualified_as_rank
                            );
                            setInnerLoader(false);
                        } else {
                            setInnerLoader(false);
                        }
                    });
                }
            }
        }
        const LoginSubmit = async (data, setError) => {
            await api.loginUser(data).then((res) => {
                if (res?.data?.code == 1) {
                    // if (cartdata?.products?.length > 0) {
                    //     router.push("/us/checkout/addresslist");
                    // } else {
                    //     router.push("/us/user/dashboard/");
                    // }
                    // setDetail(res?.data?.user_data?.userdetails?.[0]?.paid_as_rank)
                    // localStorage.setItem("profileData", JSON.stringify(res?.data))
                    setInnerLoader(false);
                    // subdomainUserCheckFunction(res.data.token);
                    setisLogin(true);
                    localStorage.setItem("Login", true);
                    localStorage.setItem("Token", res.data.token);
                    getMiniCartProduct("onLoadData", res?.data?.token);
                    WishlistAllCart(res?.data?.token);
                    ProfileFunction(res?.data?.token);
                    setCustomerCheck(
                        res?.data?.user_data?.userdetails?.[0]?.qualified_as_rank
                    );
                    const redirectUrl = sessionStorage.getItem('redirectAfterLogin');
                    if (res?.data?.user_data?.userdetails?.[0]?.qualified_as_rank !== 3) {
                        if (redirectUrl) {
                            navigate(redirectUrl);
                        } else {
                            navigate("/");
                        }
                        sessionStorage.removeItem('redirectAfterLogin');
                    } else {
                        navigate("/");
                        sessionStorage.removeItem('redirectAfterLogin');
                    }
                }

                if (res?.data?.code === 0 && res?.data?.message) {
                    setLoginError(res?.data?.message)
                    setTimeout(() => {
                        setLoginError();
                    }, 3000);
                    setInnerLoader(false);
                }

            });
        };
        useEffect(() => {
            const formdata = localStorage.getItem("Token")
                ? localStorage.getItem("Token")
                : "";
            getMiniCartProduct("onLoadData", formdata);
        }, []);
        const handleLogout = async () => {
            const formdata = localStorage.getItem("Token")
                ? localStorage.getItem("Token")
                : "";
            await api.logoutApi(formdata).then((res) => {
                if (res?.data?.code === 1) {
                    localStorage.removeItem("Token");
                    localStorage.removeItem("address_id");
                    localStorage.removeItem("Login");
                    localStorage.removeItem("packProduct");
                    localStorage.removeItem("PromoterPack");
                    localStorage.removeItem("profileDetail");
                    localStorage.removeItem("referral_code");
                    localStorage.removeItem("shippingAddress");
                    localStorage.removeItem("correctRefferal");
                    localStorage.removeItem("profileData");
                    localStorage.removeItem("coupon_name");
                    localStorage.removeItem("discount_avaible");
                    Cookie.remove("sessionkey");
                    // toast.success(res?.data?.message);

                    setSmartShipCheck(false);

                    setProfileData();


                    setisLogin(false);
                    // setValidReferralCheck(false);
                    window.location.assign("/")
                    setcounts();
                    setCartData([]);

                }
                if (res?.data?.code === 0) {
                    localStorage.removeItem("Token");
                }
            });
        };
        //referral code check function

        async function subdomainUserCheckFunction(token) {
            const subDomainDirect = window.location.hostname.split(".")[0];
            // setshowloader(true)
            if (
                subDomainDirect !== "" &&
                window.location.hostname !== "sharekaire.com" && window.location.hostname !== "sharekaire.vercel.app"
            ) {
                try {
                    // setshowloader(true)
                    const res = await api.subDomainCheckFunction({
                        referral_code: subDomainDirect,
                    });
                    if (res?.status === 200) {
                        setValidReferralCheck(res?.data?.status);
                        if (res?.data?.status !== true) {
                            // localStorage.removeItem('Token');
                            // localStorage.removeItem('address_id');
                            // localStorage.removeItem('Login');
                            // window.location.assign(process.env.domainName);

                            if (cartdata?.products?.length > 0) {
                                router.push("/us/checkout/addresslist");
                            } else {
                                router.push("/us/user/dashboard/");
                            }

                            setCorrectRefferal({ status: res?.data?.status, data: {} });
                        } else {
                            const { user_details } = res?.data?.data;
                            setCorrectRefferal({
                                status: res?.data?.status,
                                data: {
                                    name: user_details?.name,
                                    email: user_details?.email,
                                    phone: user_details?.phone,
                                },
                            });
                            localStorage.setItem("referral_code", subDomainDirect);
                            localStorage.setItem("correctRefferal", JSON.stringify({
                                status: res?.data?.status,
                                data: {
                                    name: user_details?.name,
                                    email: user_details?.email,
                                    phone: user_details?.phone,
                                },
                            })
                            );
                            setAnalyticsCode({
                                facebook: res?.data?.facebook_analytic_key
                                    ? res?.data?.facebook_analytic_key
                                    : "837382719344418227664",
                                google: res?.data?.google_analytic_key
                                    ? res?.data?.google_analytic_key
                                    : "G- XZ37FF4HMP",
                            });

                            // if (cartdata?.products?.length > 0) {
                            //   router.push('/us/checkout/addresslist')
                            // } else {
                            //   router.push('/us/user/dashboard/');
                            // }
                        }
                    }
                } catch (error) {
                    return error.response;
                }
            }
        }

        useEffect(() => {
            const subDomain = (window.location.hostname).split('.');
            // if (window.location.hostname !== "sharekaire.com" && window.location.hostname !== "sharekaire.vercel.app") {
            //     // setSubDomain()
            //     setSubDomain()
            //     // subDomain[0] !== "localhost" &&
            //     if (subDomain[0] !== "localhost" && subDomain[0] !== "192") {
            //         subdomainUserCheckFunction();
            //     }
            // }
            if (window.location.hostname !== "sharekaire.com" && (subDomain?.length > 0)) {
                setSubDomain(subDomain[0])
                if (subDomain[0] !== "localhost" && subDomain[0] !== "192") {
                    subdomainUserCheckFunction();
                }
            }
            const formdata = localStorage.getItem('Token') ? localStorage.getItem('Token') : '';
            getMiniCartProduct("onLoadData", formdata);

        }, []);
        return (
            <div className="main_div">
                <Component
                    innerLoader={innerLoader}
                    setActiveStep={setActiveStep}
                    subDomain={subDomain}
                    activeStep={activeStep}
                    setInnerLoader={setInnerLoader}
                    showloader={showloader}
                    setshowloader={setshowloader}
                    cartdata={cartdata}
                    setCartData={setCartData}
                    addNotify={addNotify}
                    setAddNotify={setAddNotify}
                    counts={counts}
                    setcounts={setcounts}
                    setSmartShipCheck={setSmartShipCheck}
                    smartShipCheck={smartShipCheck}
                    getMiniCartProduct={getMiniCartProduct}
                    handleLogout={handleLogout}
                    isLogin={isLogin}
                    {...props}
                    setupdatecartdata={setupdatecartdata}
                    LoginSubmit={LoginSubmit}
                    setLoginError={setLoginError}
                    loginError={loginError}
                    signUpRefferal={{ correctRefferal, setCorrectRefferal }}
                    coupon={coupon}
                    setcoupon={setcoupon}
                    amountCalculations={amountCalculations}
                    setAmountCalculations={setAmountCalculations}
                    amountAfterDiscount={amountAfterDiscount}
                    setAmountAfterDiscount={setAmountAfterDiscount}
                    ProfileFunction={ProfileFunction}
                    setProfileData={setProfileData}
                    setCustomerCheck={setCustomerCheck}
                    isLoginToken={isLoginToken}
                    profileData={profileData}
                    customerCheck={customerCheck}
                    signupDefualtValue={signupDefualtValue}
                    setDefaultValue={setDefaultValue}
                    autoshipOrderId={autoshipOrderId}
                    setAutoshipOrderId={setAutoshipOrderId}
                    setshowminicart={setshowminicart}
                    showminicart={showminicart}
                />
                {
                    innerLoader === true && (
                        <div className="flexbox">
                            <div className="">
                                <div className="bt-spinner"></div>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }

    return ComponentWithRouterProp;
}

export default withRouter;
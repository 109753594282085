import React from "react";
import { MemberHighlightCss } from "./RewardStyle";

const MemberHighlight = (props) => {
  const { Validatelogin, userRank } = props
  return (
    <MemberHighlightCss>
      <div className="container">
        <div className="cstm-row">
          <div className="col-5">
            <div className="member-highlight-content">
              <h2>
                <span>MEMBER HIGHLIGHTS</span>
              </h2>
              <ul>
                <li>
                  Two self-replicating business management websites:
                  <ul>
                    <li>ShopKaire – For your customers</li>
                    <li>ShareKaire – For your member referrals</li>
                  </ul>
                </li>
                <li>Earn weekly and monthly referral commissions on personal customers and members and their team's purchases</li>
                <li>Online Marketing Tools</li>
                <li>
                  Four $5 first order discount coupons to share with your
                  referred members ($20 value)
                </li>
              </ul>
              <div className="btn-wrap">
                <a
                  type="button"
                  className="btn"
                  // href="https://commandconcept.csdevhub.com/media/pdf_files/Rewards_Plan_040324.pdf"
                  href="https://admin.shopkaire.com/media/pdf/Kaire_RewardsPlan_102924.pdf"
                  target="_blank"
                >
                  The full rewards plan
                </a>
                {userRank?.user_data?.userdetails?.[0]?.qualified_as_rank !== 3 && <button type="button" className="btn btn-2 " onClick={() => Validatelogin()}>
                  Signup
                </button>}
              </div>
            </div>
          </div>
          <div className="col-7">
            <figure className="member-highlight-img">
              <img src="images/member-highlight.png" alt="Image" />
            </figure>
          </div>
        </div>
      </div>
    </MemberHighlightCss>
  );
};

export default MemberHighlight;

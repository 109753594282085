import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../api/Apis";
import apiPaths from "../../../services/apPaths";
import { SmartshipPackStyle } from "./SmartshipStyle";
import parse from 'html-react-parser';
import { Radio, Tooltip } from "@mui/material";
import NumberFormatComp from "../../../Components/Common/NumberFormatComp";
import NumberFormat from "react-number-format";
import cookieCutter from 'cookie-cutter'
import { FaTrash } from "react-icons/fa";
import { Edit } from "@mui/icons-material";

export const SecondSection = ({ data }) => {

  const { costtype, productCost, productSmartShip, minCartAutoshipCheck, setcosttype, id, smartShipCheck, bonusValue } = data;
  const findData = _.find(costtype, { id: id });
  const [selectedClass, setSelectedClass] = useState('Normal');

  const changeCostValue = (values) => {
    const availableData = _.reject(costtype, { id: findData?.id })
    setcosttype([...availableData, { id: id, value: values }]);
  }
  return (
    <div className="second_section">
      {[
        // { option: "Normal", name: "One-Time Order", paragraph: "List Price", para1: "SmartShip Discount Applied" },
        { option: "AutoShip", name: "SmartShip", paragraph: "Monthly Subscription", para1: "Monthly Subscription" }

      ].map(({ option, name, paragraph, para1 }, index) => (
        <div className="custom-radio"
          //  className={ClassNames(`${option.toLowerCase()}_radio_container radio_class custom_radio`, {
          //   "selected_class": selectedClass === option, "add_to_the_class": (option === "Normal" && (minCartAutoshipCheck === "True" || smartShipCheck))
          // })}
          key={index + 1}
          onClick={() => {
            setSelectedClass(option);
            changeCostValue(option);
          }}>

          <div className="radio_section">
            <Radio
              checked={findData?.id === id && findData?.value === option}
              onChange={(e) => {
                changeCostValue(e.target.value);
                setSelectedClass(option);
              }}
              value={option}
              name="radio-buttons"
              inputProps={{ 'aria-label': `${option}` }}
            />
          </div>
          <div className="title_section">
            <h3 className="order">{name}</h3>
            {
              (minCartAutoshipCheck === "True" || smartShipCheck) ?
                <p className={option === "Normal" ? "green_code" : "black_code"}>
                  {para1}
                </p> :
                <p>{paragraph}</p>
            }
          </div>
          <div className={data?.section ? "allproduct_" + option.toLowerCase() : "price_section"}>
            {option === "Normal"
              ?
              <>
                {(minCartAutoshipCheck !== "True" && !smartShipCheck) &&
                  <NumberFormatComp value={productCost ? productCost : 0} />
                }
                {(minCartAutoshipCheck === "True" || smartShipCheck) &&
                  <>
                    <NumberFormatComp value={productSmartShip ? productSmartShip : 0} render={(value) =>
                      <Tooltip title='Smartship price'>
                        <div className="autoship_price_for_true">{value} USD
                          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 192 512" height="1em"
                            width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 424.229h20V279.771H20c-11.046 0-20-8.954-20-20V212c0-11.046 8.954-20 20-20h112c11.046 0 20 8.954 20 20v212.229h20c11.046 0 20 8.954 20
                                           20V492c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20v-47.771c0-11.046 8.954-20 20-20zM96 0C56.235 0 24 32.235 24 72s32.235 72 72 
                                           72 72-32.235 72-72S135.764 0 96 0z"></path></svg>
                        </div>
                      </Tooltip>

                    } />
                    <span className='discount_smartship' style={{ color: 'green' }}> You Save ${parseFloat(productCost - productSmartShip).toFixed(2)}</span>
                  </>
                }
              </>
              :
              <>
                <div>
                  ${parseFloat(productSmartShip).toFixed(2)} USD
                </div>
                <div>
                  {parseFloat(bonusValue)} BV
                </div>
              </>
              // <NumberFormatComp value={productSmartShip} />

            }

          </div>
        </div>
      ))
      }
    </div >
  )
}








const SmartshipPack = ({ handleNext, setActiveStep, successMsg, setSuccessMeg, ...props }) => {
  const navigate = useNavigate()
  const store = "us";
  const [productData, setProductData] = useState([]);
  // const [cartData, setCartData] = useState();
  const [qtyCount, setQtyCount] = useState([]);
  const [costType, setCostType] = useState([]);
  const [tokenDat, setTokenDat] = useState();
  const [customPackProduct, setCustomerPackProduct] = useState();
  const [memberPack, setMemberPack] = useState()
  const [errPack, setErrPack] = useState(false)
  const [membershipId, SetMembershipId] = useState();

  const {
    setshowloader,
    setInnerLoader,
    minCartAutoshipCheck,
    counts,
    setcounts,
    smartShipCheck,
    addToCart,
    addNotify,
    setAddNotify,
    cartdata,
    setCartData,
    getMiniCartProduct
  } = props;
  const hasEffectRunRef = useRef(false);
  useEffect(() => {

    const token = localStorage.getItem("Token") ? localStorage.getItem("Token") : null;
    setTokenDat(token);
    const custompackData = localStorage.getItem("packProduct") ? JSON.parse(localStorage.getItem("packProduct")) : null;
    if (custompackData) {
      setCustomerPackProduct(custompackData?.customPack);
    }
    else {
      setCustomerPackProduct()
    }
    const member = localStorage.getItem("PromoterPack") ? JSON.parse(localStorage.getItem("PromoterPack")) : null;
    if (member) {
      setMemberPack(member)
    }
    else {
      setMemberPack()
    }
    if (!hasEffectRunRef.current) {
      _getAllProduct();
      hasEffectRunRef.current = true;
    }

    // window.scroll({
    //   top: 0,
    //   left: 0,
    //   behavior: 'smooth'
    // });

  }, [cartdata]);
  function _handleAddtoCart(product) {
    setInnerLoader(true)
    api.getAllCartProduct().then((res) => {
      if (res?.data?.code === 1) {

        setCartData(res?.data);
        let data = _.map(res?.data.products, ({ product }) => +product?.id);
        setAddNotify(data);
        // props?.setcounts(res?.data);
        // props?.setshowminicart(true);

        setInnerLoader(false)
      }
      setInnerLoader(false)
    });

    const { id, value } = _.find(costType, (row) => row?.id === product?.id);
    const { qty } = _.find(qtyCount, { id: product.id });
    const formData = {
      product_id: id,
      variant_id: null,
      token: tokenDat,
      quantity: +qty,
      is_autoship: value !== "Normal" ? true : false,
    };
    addToCart(formData);
    // localStorage.setItem("smartship", JSON.stringify(formData))
  }


  async function _getAllProduct() {
    // setshowloader(true);
    // setInnerLoader(true);

    if (store) {
      await api.getAllProduct(store).then((res) => {
        if (res?.status === 200) {
          let CART_DATA = [];
          const filterData = _.filter(res?.data?.products, {
            category: "ANTIOXIDANTS",
          });

          // setProductData(res?.data?.products);
          setProductData(filterData);
          api.promoterPackApi().then((resp) => {
            const Yearly_membership_id = _.find(resp?.data.promoter_packs, { category: "Membership" });
            api.getAllCartProduct().then((response) => {
              const membership_avaiable = _.find(response?.data.products, { category: "Member Pack" });
              const Yearly_membership_avaiable = _.find(response?.data.products, { category: "Membership" });
              if ((response?.data?.products?.length === 0 || !membership_avaiable) && !Yearly_membership_avaiable) {
                const formData = {
                  product_id: Yearly_membership_id?.id,
                  variant_id: null,
                  quantity: 1,
                  is_autoship: true,
                };
                addToCart(formData);
                setCartData(response?.data)
              }
              if (response?.data?.code === 1) {
                if (response?.data?.products.length > 1) {
                  if (membership_avaiable) {
                    api.deleteProductFromAddToCart({ product_id: Yearly_membership_avaiable?.product?.id, is_autoship: Yearly_membership_avaiable?.is_autoship }).then((res) => {

                      if (res?.data?.code === 1) {
                        getMiniCartProduct("delete", "");
                        localStorage.removeItem("smartship");
                        setInnerLoader(false)
                      }
                    });
                  }
                }

                CART_DATA = _.map(response?.data.products, (row) => {
                  let GET_ID = _.pick(row.product, "id");
                  let VALUES = _.pick(row, ["is_autoship", "quantity"]);
                  return { ...GET_ID, ...VALUES };
                });
                let data = _.map(
                  response?.data.products,
                  ({ product }) => +product?.id
                );
                setAddNotify(data);

              }

              const AllQTY = _.map(res?.data?.products, (row) => ({
                id: row?.id,
                qty:
                  CART_DATA?.length > 0 && _.map(CART_DATA, "id").includes(row.id)
                    ? +_.find(CART_DATA, { id: row?.id }).quantity
                    : 1,
              }));

              const allvalues = _.map(res?.data?.products, (row) => ({
                id: row.id,
                value:
                  CART_DATA?.length > 0 && _.map(CART_DATA, "id").includes(row.id)
                    ? _.find(CART_DATA, { id: row?.id }).is_autoship === "True"
                      ? "AutoShip"
                      : "Normal"
                    : "AutoShip",
              }));
              setQtyCount(AllQTY);

              setCostType(allvalues);
              // setshowloader(false);
              // setInnerLoader(false);
              // setCartData(response?.data)
            });
          });
        }
      });
    }
  }
  function handleBack() {
    setActiveStep((prevActiveStep) => {
      return prevActiveStep - 1
    })
  }
  function handleNext() {

    setActiveStep((prevActiveStep) => {
      return prevActiveStep + 1
    })
  }
  function deleteMemberPack(id, is_autoship) {
    setInnerLoader(true)
    api.deleteProductFromAddToCart({ product_id: id, is_autoship: is_autoship }).then((res) => {
      if (res?.data?.code === 1) {
        localStorage.removeItem("PromoterPack")
        setMemberPack()
        setInnerLoader(false)
        getMiniCartProduct("delete", "");
        _getAllProduct()
      }
      else {
        setInnerLoader(false)
      }
    });
  }


  const UpdateCartData = async (datas, section) => {
    setInnerLoader(true)
    const update_data = {
      token: tokenDat,
      datas: datas
    }
    await api.updateCart(update_data).then(res => {
      if (res.data.code === 1) {
        getMiniCartProduct(section, tokenDat);
        setInnerLoader(false)
      }
    });
  }

  //add qty of product in mini cart
  const Added = async (e, cartId) => {
    // let is_auto_ship = e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector('.form-select')?.value;
    if (+(e.target.value) >= 0) {
      const data_update = e?.target?.name.split(',')
      const datas = {
        product_id: cartId?.product?.id,
        variant_id: null,
        id: cartId?.id,
        quantity: +(e.target.value) + 1,
        cookie_id: cookieCutter.get('sessionkey'),
        is_autoship: true
      }
      UpdateCartData(datas, "Add");

    }
  }
  //subtract qty of product from minicart.

  const Subtracted = (e, cartId) => {
    let is_auto_ship = e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector('.form-select')?.value;
    const data_update = e?.target?.name.split(',')
    const datas = {
      product_id: cartId?.product?.id,
      variant_id: null,
      id: cartId?.id,
      quantity: +(e.target.value) > 0 ? (+(e.target.value) - 1) : +(e.target.value),
      cookie_id: cookieCutter.get('sessionkey'),
      is_autoship: true
    }
    let a = e.currentTarget.value;
    if (a > 1) {
      UpdateCartData(datas, "Sub");
    }
  }





  const Add = (e, producData) => {
    let a = e?.currentTarget?.value;
    let max = producData?.quantity;
    if (+(max) > 0 && +(a) < +(max)) {
      let data = _.find(qtyCount, { id: producData?.id });
      if (data) {
        let rejectData = _.reject(qtyCount, data);
        setQtyCount([...rejectData, { id: producData?.id, qty: data?.qty + 1 }]);
      } else {
        setQtyCount([...qtyCount, { id: producData?.id, qty: (+a) + 1 }])
      }
    }
  }
  // decrease product qty
  const Sub = (e, producData) => {
    let a = e.currentTarget.value;
    if (a > 1) {
      let data = _.find(qtyCount, { id: producData?.id });
      if (data) {
        let rejectData = _.reject(qtyCount, data);
        setQtyCount([...rejectData, { id: producData?.id, qty: data?.qty - 1 }]);
      } else {
        setQtyCount([...qtyCount, { id: producData?.id, qty: (+a) - 1 }])
      }

    }
  }
  const isStockAvailable = _.filter(productData, { is_stock_available: "False" })

  return (
    <SmartshipPackStyle>
      <div className="container">
        <div className="cmn-packs-wrap">
          <div className="cmn-packs-heading">
            <h3>
              <small>YOUR CURRENT SELECTION</small>
            </h3>
          </div>
          {/* {memberPack &&

            <div className="cmn-packs-outer">
              <div className="cmn-packs">
                <figure>

                  {<img src={apiPaths.DOC_URL + memberPack?.promoterDetail?.product_images[0]?.image} alt="Image" />}
                </figure>
                <div className="cmn-pack-content">
                  <h5>{memberPack?.promoterDetail?.name}</h5>
                  <p>{memberPack?.promoterDetail?.description ? parse(memberPack?.promoterDetail?.description) : ""}</p>
                  <h6>${parseFloat(memberPack?.promoterDetail?.autoship_cost_price).toFixed(2)}</h6>

                  <div className="edit-del d-flex">
                    <div className="edit">
                      <a className="btn-primary-btn" onClick={() => handleBack()}>Edit <Edit /></a>
                    </div>
                    <div className="edit">
                      <a className="btn-primary-btn dlt-btn" onClick={() => deleteMemberPack(memberPack?.product_id, memberPack?.is_autoship)}>Delete <FaTrash /></a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          } */}
          <div className="cmn-packs-outer">
            <div className="cmn-packs">
              {_.map(cartdata.products, (row, index) => {
                return (
                  <>
                    <figure>
                      {<img src={apiPaths.DOC_URL + row?.product?.product_images[0]?.image} alt="Image" />}
                    </figure><div className="cmn-pack-content">
                      <h5>{row?.product?.name}</h5>

                      <h6>${parseFloat(row?.product.member_autoship_cost_price * row?.quantity).toFixed(2)}</h6>
                      {row.category !== "Member Pack" &&
                        <div id="qty" disabled={row?.product?.sku === 'MEM-01'}>
                          <button type="button"
                            name={`${row?.id},${null}`}
                            id={row?.id}
                            className="sub"
                            value={row.quantity}
                            disabled={row?.product?.sku === 'MEM-01'}
                            onClick={(e) => { Subtracted(e, row) }}>-</button>

                          <input
                            name={`${row?.id},${null}`}
                            type="text"
                            className="get"
                            value={row.quantity}
                            list={row?.quantity}
                            max={row?.quantity}
                            readOnly
                          />
                          <button type="button"
                            name={`${row?.id},${null}`}
                            id={row?.id} className="add"
                            value={row.quantity}
                            disabled={row?.product?.sku === 'MEM-01'}
                            onClick={(e) => { Added(e, row) }}>+</button>
                        </div>

                      }

                      <div className="edit-del d-flex">
                        {row.category === "Member Pack" &&
                          <div className="edit">
                            <a className="btn-primary-btn" onClick={() => handleBack()}>Edit <Edit /></a>
                          </div>
                        }
                        {row.category !== "Membership" &&
                          <div className="edit">
                            <a className="btn-primary-btn dlt-btn" onClick={() => deleteMemberPack(row?.product.id, row?.is_autoship)}>Delete <FaTrash /></a>
                          </div>
                        }
                      </div>
                    </div></>
                )
              })}
            </div>
          </div>


          <div className="smartship-products-wrap">
            <p>If a Member Pack is selected, your yearly Membership is included. If you did not choose a Member Pack, and you wish to join ShareKaire as a Member and earn commissions by referring customers and members, a yearly Membership is required. The membership will be added to your cart.</p>

            <p>A Member is active and qualified for various commissions with a discounted monthly Smartship (automatic order). You may select below to set up your Smartship.</p>
            {/* <p>
              Get your monthly health boost delivered straight to your doorstep
              like clockwork! Select below to add more products or smartships
            </p> */}
            <div className="smartship-products-outer">
              {_.map(productData, (row, index) => {
                const STOCK_CHECK = +row?.quantity >= 10 ? 10 : +row?.quantity;
                const CURRENTVALUE = _.find(costType, { id: row.id });
                let checkQuantity = _.find(qtyCount, { id: row?.id })
                if (row?.retail_open === true) {
                  return (
                    <div className="smartship-products">

                      <figure>

                        {/* <img src="/images/maritime-prime-180.png" alt="Image" /> */}
                        {row?.product_images?.length > 0 && (
                          <img src={apiPaths.DOC_URL + row?.product_images[0]?.image} alt="Image" />
                        )}
                      </figure>
                      <div className="smartship-product-details">
                        <div className="smartship-product-heading">
                          <h6>
                            <small>{row?.name}</small>
                          </h6>
                          <svg
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            data-testid="ArrowDropDownIcon"
                          >
                            <path d="M7 10l5 5 5-5z"></path>
                          </svg>
                        </div>
                        <div className="smartship-product-pricing">
                          <SecondSection
                            data={{
                              costtype: costType,
                              productCost: row?.cost_price,
                              productSmartShip: row?.member_autoship_cost_price,
                              bonusValue: row?.member_autoship_bonus_value,
                              minCartAutoshipCheck,
                              smartShipCheck,
                              setcosttype: setCostType,
                              id: row.id,
                              section: "signUp",
                            }}
                          />
                          <div id="qty">
                            <button type="button"
                              name={`${row?.id},${null}`}
                              id={row?.id}
                              className="sub"
                              value={(checkQuantity) ? checkQuantity.qty : 1}
                              disabled={isStockAvailable.some(data => data.id === row.id)}
                              onClick={(e) => { Sub(e, row) }}>-</button>
                            <input
                              name={`${row?.id},${null}`}
                              type="text"
                              className="get"
                              value={(checkQuantity) ? checkQuantity?.qty : 1}
                              list={row?.quantity}
                              max={row?.quantity}
                              readOnly
                            />
                            <button type="button"
                              name={`${row?.id},${null}`}
                              id={row?.id} className="add"
                              value={(checkQuantity) ? checkQuantity.qty : 1}
                              disabled={isStockAvailable.some(data => data.id === row.id)}
                              onClick={(e) => { Add(e, row) }}>+</button>

                          </div>
                          <div className="form-btn-wrap">
                            <button type="button" className="btn btn-2" onClick={() => _handleAddtoCart(row)}
                              disabled={isStockAvailable.some(data => data.id === row.id)}
                            >
                              {isStockAvailable.some(data => data.id === row.id) ?
                                "OUT OF STOCK" :
                                "Add To Cart"
                              }

                            </button>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>

                    </div>
                  );
                }
              })}
            </div>
          </div>

          <div className="continue-btn">
            <button className="btn btn-6" onClick={() => handleBack()}> Back</button>
            <button className="btn btn-2" onClick={() => handleNext()}>Next</button>
          </div>
          {successMsg && <span className="success_msg">{successMsg}</span>}
        </div>
      </div>
    </SmartshipPackStyle >
  );
};

export default SmartshipPack;

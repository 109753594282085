import React from "react";
import { EnrollmentBannerStyle } from "./EnrollmentStyle";

const EnrollmentBanner = () => {
  return (
    <EnrollmentBannerStyle>
      <div className="container">
        <figure className="inner-logo">
          <a href="#">
            <img src="/images/logo.png" alt="Logo" />
          </a>
        </figure>
        <h3>CHOOSE YOUR<br />ENROLLMENT PACK</h3>
        <ol>
          <li>
            <a href="javscript:void(0);">
              <span>1</span>
              . SIGNUP
            </a>
          </li>
          <li className="active">
            <a href="javscript:void(0);">
              <span>2</span>
              . ENROLLMENT PACKS
            </a>
          </li>
          <li>
            <a href="javscript:void(0);">
                <span>3</span>
                . SMARTSHIP
            </a>
          </li>
          <li>
            <a href="javscript:void(0);">
                <span>4</span>
                . REVIEW
            </a>
          </li>
        </ol>
      </div>
    </EnrollmentBannerStyle>
  );
};

export default EnrollmentBanner;

import React, { useEffect, useRef, useState } from "react";
import { ReviewPackStyle } from "./ReviewStyle";
import Delete from "@mui/icons-material/Delete";
import { Edit } from "@mui/icons-material";
import DetailSection from "./DetailSection";
import _ from "lodash";
import api from "../../../api/Apis";
import apiPaths from "../../../services/apPaths";
import parse from 'html-react-parser';
import NumberFormatComp from "../../../Components/Common/NumberFormatComp";
import { FormControl, FormControlLabel, Radio } from "@mui/material";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";
import Discount from "../../../Components/Common/Discount";
import NumberFormat from "react-number-format";

const ReviewPack = (props) => {
  const {
    signupDefualtValue,
    handleBack,
    stepsOption,
    setshowloader,
    setActiveStep,
    setInnerLoader,
    checkCustomPack,
    setCheckCustomPack,
    minCartAutoshipCheck,
    setSmartShipCheck,
    smartShipCheck,
    setAddNotify,
    addNotify,
    addToCart,
    coupon,
    setcoupon,
    amountCalculations,
    setAmountCalculations,
    amountAfterDiscount,
    setAmountAfterDiscount,
    autoshipOrderId,
    setAutoshipOrderId,
    ...other
  } = props;
  let REJECT_DATA_PRODUCT = _.reject(other?.cartdata?.products, function (row) { return ["MPY-1", "MEM-01"].includes(row?.product?.sku) });
  let REJECT_MAMBER_PACK = _.reject(other?.cartdata?.products, function (row) { return ["SUPER-PACK-1", "PRIME-PACK-1", "SUPER-PROMO-PACK-1", "PPP-200"].includes(row?.product?.sku) });
  const [datePickerError, setDatePickerError] = useState("")
  const [billingAddress, setBillingAddress] = useState(null);
  const [countryValue, setCountryValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [countryError, setCountryError] = useState();
  const [stateError, setStateError] = useState();
  const [isShippingSame, setIsShippingSame] = useState(false);
  const [totalPrice, setTotalPrice] = useState({
    subtotal: 0,
    total: 0,
  });

  const [isAuto, setIsAuto] = useState(false);
  const [orderProduct, setOrderProduct] = useState([]);
  const [userToken, setUserToken] = useState();
  const [shippingData, setShippingData] = useState([]);
  const [customShippingData, setCustomShippingData] = useState([]);
  const [productsWeight, setProductWeight] = useState(0);
  const [selectedShipping, setSelectedShipping] = useState({
    id: null,
    amount: null,
  });
  const [selectedCustomProductShipping, setSelectedCustomProductShipping] = useState({
    id: null,
    amount: null,
  });
  const [dataShip, setDataShip] = useState();
  const [paymentDisable, setPaymentDisable] = useState(false);
  const [shippingError, setShippingError] = useState("");
  const [getData, setGetData] = useState();
  const [nextDate, setNextDate] = useState();
  const [smartshipOpen, setSmartShipOpen] = useState(false);
  const [couponData, setCouponData] = useState();
  const [radioError, setRadioError] = useState("");
  const [autoShipRadioError, setAutoShipRadioError] = useState("");
  const [autoShipAmount, setAutoShipAmount] = useState(null);
  const [discountSection, setDisCountSection] = useState({
    discountAmount: null,
    freeShipping: false,
  });
  const [productData, setProductData] = useState([]);
  const [qtyCount, setQtyCount] = useState([]);
  const [costType, setCostType] = useState([]);
  const navigate = useNavigate();
  const currentDate = new Date();
  // currentDate.setDate(currentDate.getDate() + 30)
  const [minDate, setMinDate] = useState(REJECT_DATA_PRODUCT?.length > 0 ? currentDate : null);
  const [logintoken, setToken] = useState();
  const [discount_amount1, setdiscount_amount] = useState();
  const [copanerror, setcopanerror] = useState();
  const store = "us";
  const hasEffectRunRef = useRef(false);
  const [customCouponDiscount, setCustomCouponDiscount] = useState([]);
  const [autoshipCouponDiscount, setAutoshipCouponDiscount] = useState([]);
  const IsMemberProduct = _.filter(other?.cartdata?.products, (row) => ["Member Pack", "Membership"].includes(row?.category))
  const IsSmartshipProduct = other?.cartdata?.products?.some((row) => ["Member Pack", "Membership"].includes(row?.category));
  const IsOneTimeOrder = other?.cartdata?.products?.some((row) => ["ANTIOXIDANTS"].includes(row?.category));
  const IsCustomProduct = _.filter(other?.cartdata?.products, (row) => row?.category === "ANTIOXIDANTS")
  useEffect(() => {
    if (!hasEffectRunRef.current) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      const promoterToken = localStorage.getItem("Token");
      _getAllProduct();
      _getAllProductData();
      setUserToken(promoterToken);
      hasEffectRunRef.current = true;
    }
  }, []);
  useEffect(() => {
    _getAllProductData();
  }, [other?.cartdata]);
  const ISMEMBERSHIP_PRICE = _.map(
    _.filter(other?.cartdata?.products, (product) => product.category === "ANTIOXIDANTS"),
    (row) => row?.product?.member_autoship_cost_price * +row?.quantity
  );
  const availableShippingModules = async (orderData, WEIGHT, totalPrice, CUSTOMWEIGHT) => {
    const promoterToken = localStorage.getItem("Token");
    const SHIPPING_ADDRESS = localStorage.getItem("shippingAddress");
    const data = localStorage.getItem('packProduct');
    const jsonPraseData = data ? JSON.parse(data) : {};
    const payload = {
      // shipping_address_id: parseInt(+SHIPPING_ADDRESS),
      shipping_address_id: SHIPPING_ADDRESS,
      cart_items: orderData?.length + (jsonPraseData?.customPack) ? 1 : 0,
      cart_weight: (jsonPraseData?.customPack) ? +(jsonPraseData?.customPack?.totalWeight) + WEIGHT : WEIGHT,
      cart_total: totalPrice,
      isCustomProduct: IsOneTimeOrder ? true : false,
      isCustomProductWeight: CUSTOMWEIGHT
    };

    await api.availableShippingModules(promoterToken, payload).then((response) => {
      if (response.status === 200 && response?.data) {
        setInnerLoader(false)
        let data = [];
        let customdata = [];
        if (response?.data.weight_based) {
          Object.values(response?.data.weight_based).forEach((value) => {
            if (Array.isArray(value)) {
              data = [...data, ...value];
            } else {
              data.push(value);
            }
          });
        }
        setShippingData(data);
        if (response?.data.custom_weight_based) {
          Object.values(response?.data.custom_weight_based).forEach((value) => {
            if (Array.isArray(value)) {
              customdata = [...customdata, ...value];
            } else {
              customdata.push(value);
            }
          });
        }
        setCustomShippingData(customdata)
      }
      else {
        setInnerLoader(false)
      }
    })
      .catch((err) => console.log(err));
  };

  function _getAllProductData() {
    let totalPrice = checkCustomPack?.products?.length > 0 ? +checkCustomPack?.totalAmount : 0;
    let WEIGHT = 0;
    if (_.keys(other?.cartdata).length > 0) {
      setNextDate(other?.cartdata?.next_shipping_on);
      let data = _.map(other?.cartdata.products, ({ product }) => +product?.id);

      // _.forEach(other?.cartdata?.products, (row) => {
      //   WEIGHT += parseFloat(
      //     +_.get(row, "product.weight", 0) * +row?.quantity
      //   );
      // });
      _.forEach(IsMemberProduct, (row) => {
        WEIGHT += parseFloat(
          +_.get(row, "product.weight", 0) * +row?.quantity
        );
      });
      setProductWeight(WEIGHT);
      // setCartData(other?.cartdata);

      const AUTOSHIP_PRICE = _.map(
        _.filter(other?.cartdata?.products, { is_autoship: "True" }), (row) => row?.product?.member_autoship_cost_price * +row?.quantity
      );
      const PRICE = _.map(
        _.filter(other?.cartdata?.products, { is_autoship: "False" }),
        (row) => (AUTOSHIP_PRICE?.length > 0
          ? row?.product?.member_autoship_cost_price
          : row?.product?.cost_price) * row?.quantity
      );

      if (AUTOSHIP_PRICE?.length > 0) {
        setIsAuto(true);
        setSmartShipCheck(true);
        let totalAuto = 0;
        _.forEach(AUTOSHIP_PRICE, (row) => {
          totalAuto += row;
        });
        setAutoShipAmount(totalAuto);
      } else {
        setIsAuto(false);
        setSmartShipCheck(false);
      }
      _.forEach([...AUTOSHIP_PRICE, ...PRICE], (row) => {
        totalPrice += +row;
      });

      const ORDER_PASS_PRODUCT = _.map(other?.cartdata?.products, (cart) => ({
        is_autoship: cart?.is_autoship === "True" ? true : false,
        product_id: cart?.product.id,
        quantity: cart.quantity,
        variant_id: cart?.variant?.id ? cart.variant.id : null,
      }));
      let CUSTOMWEIGHT = 0;
      if (_.keys(IsCustomProduct).length > 0) {
        _.forEach(IsCustomProduct, (row) => {
          CUSTOMWEIGHT += parseFloat(
            +_.get(row, "product.weight", 0) * +row?.quantity
          );
        });
      }
      setOrderProduct(ORDER_PASS_PRODUCT);
      setTotalPrice({ total: totalPrice, subtotal: totalPrice });
      // setshowloader(false);
      setInnerLoader(false);
      availableShippingModules(ORDER_PASS_PRODUCT, WEIGHT, totalPrice, CUSTOMWEIGHT);
      props?.setcounts(other?.cartdata?.products?.length);

      setAddNotify(data);
    } else if (checkCustomPack?.products?.length > 0) {
      setTotalPrice({ total: totalPrice, subtotal: totalPrice });
      availableShippingModules([], 0, totalPrice, 0);

    }
    else {
      setInnerLoader(false);
      // other?.setCartdata([]);
      setTotalPrice({ total: 0, subtotal: 0 });
      availableShippingModules([], 0, totalPrice, 0);
      setInnerLoader(false);
      setShippingData([])

    }

  }

  function _handleClick(id, shipping_cost) {
    setSelectedShipping({ id, amount: +shipping_cost });

    setTotalPrice({
      ...totalPrice,
      total: parseFloat(totalPrice.subtotal) + parseFloat(+shipping_cost) - parseFloat(discount_amount1 || 0),
    });
  }

  function _handleCustomClick(id, shipping_cost) {
    setSelectedCustomProductShipping({ id, amount: +shipping_cost });

    // setTotalPrice({
    //   ...totalPrice,
    //   total: parseFloat(totalPrice.subtotal) + parseFloat(+shipping_cost) - parseFloat(discount_amount1 || 0),
    // });
  }




  async function _getAllProduct() {
    // setshowloader(true);
    // setInnerLoader(true);
    if (store) {
      await api.getAllProduct(store).then((res) => {
        if (res?.status === 200) {
          let CART_DATA = [];
          setProductData(res?.data?.products);
          api.getAllCartProduct().then((response) => {

            if (response?.data?.length === 0) {
              const formData = {
                product_id: 44,
                variant_id: null,
                quantity: 1,
                is_autoship: true,
              };
              addToCart(formData);
              other?.setCartData(response?.data)
            }

            if (response?.data?.code === 1) {
              // if (response?.data?.products.length > 1) {
              //   const membership_avaiable = _.find(response?.data.products, { category: "Member Pack" });
              //   if (membership_avaiable !== "") {
              //     api.deleteProductFromAddToCart({ product_id: membership_avaiable?.product?.id, is_autoship: membership_avaiable?.is_autoship }).then((res) => {
              //       if (res?.data?.code === 1) {
              //         setInnerLoader(false)
              //         other?.getMiniCartProduct("delete", "");
              //         localStorage.removeItem("smartship");
              //         setSelectedShipping({ id: null, amount: null });
              //       }
              //     });
              //   }
              // }
              CART_DATA = _.map(response?.data.products, (row) => {
                let GET_ID = _.pick(row.product, "id");
                let VALUES = _.pick(row, ["is_autoship", "quantity"]);
                return { ...GET_ID, ...VALUES };
              });
              let data = _.map(
                response?.data.products,
                ({ product }) => +product?.id
              );
              setAddNotify(data);
              other?.setCartData(response?.data)

            }

            const AllQTY = _.map(res?.data?.products, (row) => ({
              id: row?.id,
              qty:
                CART_DATA?.length > 0 && _.map(CART_DATA, "id").includes(row.id)
                  ? +_.find(CART_DATA, { id: row?.id }).quantity
                  : 1,
            }));

            const allvalues = _.map(res?.data?.products, (row) => ({
              id: row.id,
              value:
                CART_DATA?.length > 0 && _.map(CART_DATA, "id").includes(row.id)
                  ? _.find(CART_DATA, { id: row?.id }).is_autoship === "True"
                    ? "AutoShip"
                    : "Normal"
                  : "Normal",
            }));
            setQtyCount(AllQTY);
            setCostType(allvalues);
            // setshowloader(false);
            // setInnerLoader(false);
          });
        } else {
          // setInnerLoader(false);
        }
      });
    }
  }
  function convertToIndianFormat(phoneNumber) {
    const cleanedNumber = phoneNumber?.replace(/\D/g, '');
    if (cleanedNumber) {
      return `${cleanedNumber}`;
    } else {
      return phoneNumber;
    }
  }
  function PreviousButton() {
    setActiveStep((prevActiveStep) => {
      return prevActiveStep - 1
    })
    setdiscount_amount();
    setAmountAfterDiscount();
    setAmountCalculations()
    setcoupon("");
    localStorage.removeItem("coupon_name");
    localStorage.removeItem("discount_avaible");
  }
  function _onhandleChange(status) {
    if (status) {
      setCountryValue(signupDefualtValue?.country);
      setStateValue(signupDefualtValue?.state);
      formik.setValues({
        ...formik.values,
        first_name: signupDefualtValue?.first_name,
        last_name: signupDefualtValue?.last_name,
        address_line1: signupDefualtValue?.street_address_1 || signupDefualtValue?.address_line1,
        address_line2: signupDefualtValue?.street_address_2 || signupDefualtValue?.address_line2,
        city: signupDefualtValue?.city,
        zipcode: signupDefualtValue.postal_code || signupDefualtValue?.zipcode,
        phone_no: convertToIndianFormat(signupDefualtValue.phone_number) || convertToIndianFormat(signupDefualtValue.phone_no),
      });
    } else {
      setCountryValue("");
      setStateValue("");
      formik.setValues({
        ...formik.values,
        first_name: "",
        last_name: "",
        address_line1: "",
        address_line2: "",
        city: "",
        zipcode: "",
        phone_no: "",
      });

    }
    setIsShippingSame(status);
  }

  const handleDelete = (id, is_autoship) => {
    api.deleteProductFromAddToCart({ product_id: id, is_autoship: is_autoship }).then((res) => {
      if (res?.data?.code === 1) {
        setInnerLoader(false)
        other?.getMiniCartProduct("delete", "");
        _getAllProductData();
        localStorage.removeItem("smartship");
        setSelectedShipping({ id: null, amount: null });
        setSelectedCustomProductShipping({ id: null, amount: null })
        setdiscount_amount();
        setAmountAfterDiscount();
        setAmountCalculations()
        setcoupon("");
        localStorage.removeItem("coupon_name");
        localStorage.removeItem("discount_avaible");
      }
    });
  }
  function handleEdit() {
    if (stepsOption?.length === 5) {
      setActiveStep(3)
    }
    else {
      setActiveStep(2)
    }
  }
  const objIndex = other?.cartdata?.products?.findIndex(
    (obj) => obj.is_autoship == "True"
  );


  function SmartShipData(datas) {
    let data = _.keys(datas)?.length > 0 ? datas : getData;
    const DATASHIP = JSON.parse(localStorage.getItem("shippingAddress"));
    setDataShip(DATASHIP);
    if (selectedShipping?.id) {
      let payload = {
        amount: other?.cartdata?.totalAfterDiscount ? totalPrice?.subtotal - _.reduce(ISMEMBERSHIP_PRICE, (sum, price) => sum + price, 0) : IsSmartshipProduct ? totalPrice?.subtotal - _.reduce(ISMEMBERSHIP_PRICE, (sum, price) => sum + price, 0) : totalPrice?.subtotal,
        custom_amount: other?.cartdata?.totalAfterDiscount ? parseFloat(other?.cartdata?.totalAfterDiscount) : _.reduce(ISMEMBERSHIP_PRICE, (sum, price) => sum + price, 0),
        amount_paid: other?.cartdata?.totalAfterDiscount ? totalPrice?.subtotal - _.reduce(ISMEMBERSHIP_PRICE, (sum, price) => sum + price, 0) : IsSmartshipProduct && autoshipDiscountAmount ? totalPrice?.subtotal - _.reduce(ISMEMBERSHIP_PRICE, (sum, price) => sum + price, 0) + selectedShipping?.amount - autoshipDiscountAmount : totalPrice?.subtotal - _.reduce(ISMEMBERSHIP_PRICE, (sum, price) => sum + price, 0) + selectedShipping?.amount,
        custom_amount_paid: other?.cartdata?.totalAfterDiscount ? parseFloat(other?.cartdata?.totalAfterDiscount + selectedShipping?.amount) : _.reduce(ISMEMBERSHIP_PRICE, (sum, price) => sum + price, 0) + selectedCustomProductShipping.amount,
        custom_gross_total: other?.cartdata?.totalAfterDiscount ? parseFloat(other?.cartdata?.totalAfterDiscount + selectedShipping?.amount) : _.reduce(ISMEMBERSHIP_PRICE, (sum, price) => sum + price, 0) + selectedCustomProductShipping.amount,
        billing_address_id: isShippingSame ? DATASHIP : "",
        billing_details: {
          first_name: data?.first_name,
          last_name: data?.last_name,
          phone_number: data?.phone_no,
          city: data?.city,
          country: countryValue,
          state: stateValue,
          company: null,
          street_address_1: data?.address_line1,
          street_address_2: data?.address_line2 ? data?.address2 : '',
          postal_code: data?.zipcode,
        },
        products: orderProduct,
        coupon: couponData ? couponData?.coupon_details?.code : null,
        coupon_id: couponData ? couponData?.coupon_details?.id : null,
        customPack: checkCustomPack?.products?.length > 0 ? true : false,
        discount_amount: discount_amount1 ? discount_amount1 : "00.00",
        gross_total: other?.cartdata?.totalAfterDiscount ? parseFloat(other?.cartdata?.totalAfterDiscount + selectedShipping?.amount) : IsSmartshipProduct && autoshipDiscountAmount ? totalPrice?.subtotal - _.reduce(ISMEMBERSHIP_PRICE, (sum, price) => sum + price, 0) + selectedShipping?.amount - autoshipDiscountAmount : totalPrice?.subtotal - _.reduce(ISMEMBERSHIP_PRICE, (sum, price) => sum + price, 0) + selectedShipping?.amount,
        is_autoship: isAuto,
        is_kaire_cash_used: false,
        is_wallet_used: false,
        kaire_cash_used: "",
        order_for: "self",
        shipping_amount: selectedShipping?.amount,
        custom_shipping_amount: selectedCustomProductShipping?.amount,
        custom_shipping_module_id: selectedCustomProductShipping?.id,
        shipping_module_id: selectedShipping?.id,
        sku: "umb-20",
        tax_amount: 0,
        wallet_cash_used: "",
        order_total_bv: 4,
        saved_card_id: null,
        shipping_address_id: isShippingSame ? DATASHIP : DATASHIP,
        payment_details: {
          card_expiry_month: _.split(data?.cardExpiry, "/", 2)?.[0],
          card_expiry_year: _.split(data?.cardExpiry, "/", 2)?.[1],
          card_number: data?.cardNumber,
          card_owner_name: data?.cardHolder,
          card_type: "visa",
          security_code: data?.cardCVC,
        },
        save_cardit_card: true,
        upcoming_shipping_date: minDate ? moment(minDate).format('yyyy-MM-DD') : null,
        is_member: true,
        fullamount: other?.cartdata?.cartTotalAmount ? parseFloat(other?.cartdata?.cartTotalAmount) : totalPrice?.subtotal,
      };
      if (checkCustomPack?.products?.length > 0) {
        payload["packData"] = checkCustomPack?.products;
        payload["custompack_total_amount"] = checkCustomPack?.totalAmount;
      }

      // setInnerLoader(true);
      setPaymentDisable(true);
      setInnerLoader(true);
      api.createOrder(payload, userToken, "self").then((res) => {
        setPaymentDisable(true);
        if (res?.data?.code === 1) {
          setPaymentDisable(false);
          setOrderProduct([]);
          setAutoshipOrderId(res?.data?.autoship_order_detail_dict?.autoship_order_id)
          localStorage.removeItem("PromoterPack");
          // localStorage.removeItem("profileDetail");
          localStorage.removeItem("parentrefferalPromoter");
          localStorage.removeItem("packProduct");
          // localStorage.removeItem("shippingAddress");
          // localStorage.removeItem("referral_code");
          localStorage.removeItem("correctRefferal");
          localStorage.removeItem("coupon_name");
          other?.getMiniCartProduct("");
          props?.setcounts();
          setInnerLoader(false);
          // toast.success(res?.data?.message, { duration: 3 });

          navigate(`/Order/thankyou/:orderid=${res?.data.order_id}&autoshipOrderId=${res?.data?.autoship_order_detail_dict?.autoship_order_id ? res?.data?.autoship_order_detail_dict?.autoship_order_id : ""}`);
        } else {
          setInnerLoader(false);
          setPaymentDisable(false);
          // toast.warn(res?.data?.message, { duration: 3 });
        }
        if (res?.data?.code === 0) {
          setShippingError(res?.data?.message);
          setTimeout(() => {
            setShippingError("");
          }, 3000);
        }
      });

    }
    // toast.warn('please select shipping model')
  }
  const INITIAL_VALUES = {
    first_name: isShippingSame ? signupDefualtValue?.first_name : "",
    last_name: isShippingSame ? signupDefualtValue?.last_name : "",
    address_line1: isShippingSame ? signupDefualtValue?.street_address_1 : "",
    address_line2: isShippingSame ? signupDefualtValue?.street_address_2 : "",
    country: isShippingSame ? signupDefualtValue?.country : "",
    state: isShippingSame ? signupDefualtValue?.state : "",
    city: isShippingSame ? signupDefualtValue?.city : "",
    zipcode: isShippingSame ? signupDefualtValue?.postal_code : "",
    phone_no: isShippingSame ? signupDefualtValue?.phone_number : "",
    cardHolder: "",
    cardNumber: "",
    cardExpiry: "",
    cardCVC: "",
    save_cardit_card: totalPrice.total === 0 ? false : true,
    termscondition3: false,
    termscondition4: false,
    termscondition5: false,
    termscondition6: false,
  }
  const handleCountryStateError = () => {
    if (!minDate) {
      setDatePickerError("Please Select Date")
    }
    if (
      countryValue === undefined ||
      (stateValue === "" && countryError === undefined)
    ) {
      setCountryError("Please Select The Country");
    }
    if (
      stateValue === undefined ||
      (stateValue === "" && stateError === undefined)
    ) {
      setStateError("Please Select The State");
    }
  };
  const createValidationSchema = (totalPrice) => {
    const schema = yup.object().shape({
      first_name: yup.string().required("Please enter your first name."),
      last_name: yup.string().required("Please enter your last name."),
      address_line1: yup.string().required("Please enter your address."),
      address_line2: yup.string().notRequired(),
      city: yup.string().required("Please enter your city."),
      zipcode: yup.string().required("Please enter your zip code.").min(4, "Minimun 4 digits required"),
      phone_no: yup
        .string()
        .required("Please enter your phone Number.")
        .min(9, "Enter minimum 9 digit")
        .max(12, "Phone number should not be more than 12 digits"),
      cardHolder: totalPrice?.total === 0 ? yup.string().notRequired() : yup.string().required("Please enter your card name."),
      termscondition3: yup.boolean().oneOf([true], "This field is required"),
      termscondition4: yup.boolean().oneOf([true], "This field is required"),
      termscondition5: yup.boolean().oneOf([true], "This field is required"),
      termscondition6: IsCustomProduct.length > 0 ? yup.boolean().oneOf([true], "This field is required") : yup.string().notRequired(),

      cardExpiry: totalPrice?.total === 0 ? yup.string().notRequired() : yup
        .string()
        .required("Enter your expiry date.")
        .matches(
          /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{4})$/,
          "please enter date in this format MM/YYYY"
        ),
      cardNumber: totalPrice?.total === 0 ? yup.string().notRequired() : yup
        .string()
        .required("Please enter your card number.")
        .matches(
          /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
          "Please Enter Valid card Number"
        ),
      cardCVC: totalPrice.total === 0 ? yup.string().notRequired() : yup
        .string()
        .required("Enter CVV.")
        .min(3, "Enter minimum 3 digits")
      // .matches(/^[0-9]{3,4}$/, "Enter valid CVV."),
    });
    return schema;
  }
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: createValidationSchema(totalPrice),
    onSubmit: values => {
      if (IsMemberProduct.length > 0) {
        if (!selectedShipping?.id) {
          setRadioError("Please Select Shipping method");
          setTimeout(() => {
            setRadioError("");
          }, 2000);
          return
        }
      }
      if (IsCustomProduct.length > 0) {
        if (!selectedCustomProductShipping?.id) {
          setAutoShipRadioError("Please Select Autoship Shipping method");
          setTimeout(() => {
            setAutoShipRadioError("");
          }, 2000);
          return
        }
      }

      if (smartShipCheck) {
        setGetData(values);
        setSmartShipOpen(true);
        SmartShipData(values);
      } else {
        SmartShipData(values);
      }
    },
  });
  function handleChangeDate(date) {
    if (date && !isNaN(date)) {
      const DATE = new Date(moment(date).toLocaleString())
      setMinDate(DATE)
      setDatePickerError(null)
    } else {
      setMinDate(null);
    }
  }
  // formik.setFieldValue
  const formatCardExpiry = (value, setFieldValue) => {
    let trimmedValue = value.trim();

    if (trimmedValue === "") {
      setFieldValue('cardExpiry', '');
      return '';
    }
    const match = trimmedValue.match(/^(\d{1,2})([ /]?)(\d{0,4})$/);
    if (!match) return value;

    if (trimmedValue > 1 && !trimmedValue.includes("/") && value?.length < 2) {
      trimmedValue = `${0}${value}` + "/"
    } else if (trimmedValue.includes("/")) {
      trimmedValue = value;
    } else if ((value?.length === 2) && !trimmedValue.includes("/") && (trimmedValue?.length > formik.values.cardExpiry?.length)) {
      trimmedValue = value + "/";

    } else {
    }
    setFieldValue('cardExpiry', trimmedValue);
    return trimmedValue;

  };
  function editMemberpack() {
    setActiveStep(1)
  }

  function deleteMemberPack(id, is_autoship) {
    api.deleteProductFromAddToCart({ product_id: id, is_autoship: is_autoship }).then((res) => {
      setInnerLoader(true)
      if (res?.data?.code === 1) {
        other?.getMiniCartProduct("delete", "");
        _getAllProductData();
        localStorage.removeItem("PromoterPack")

      }

    });
  }
  const removecoupon = () => {
    localStorage.removeItem("coupon_name");
    localStorage.removeItem("discount_avaible");

    setdiscount_amount();
    setAmountAfterDiscount();
    setAmountCalculations()
    setAutoshipCouponDiscount()
    setCustomCouponDiscount()
    setcoupon();
    setSelectedShipping({ id: null, amount: null });
    setSelectedCustomProductShipping({ id: null, amount: null })
    other?.getMiniCartProduct("delete", "");

  };


  useEffect(() => {
    if (!amountCalculations) {
      return;
    }
    const customProductIds = IsMemberProduct?.map((custom) => +custom.product.id);
    const filteredCalculations = amountCalculations.filter((calc) =>
      customProductIds.includes(calc.productid)
    );
    setAutoshipCouponDiscount(filteredCalculations);
  }, [amountCalculations]);


  useEffect(() => {
    if (!amountCalculations) {
      return;
    }
    const customProductIds = IsCustomProduct?.map((custom) => +custom.product.id);
    const filteredCalculations = amountCalculations.filter((calc) =>
      customProductIds.includes(calc.productid)
    );
    setCustomCouponDiscount(filteredCalculations);

  }, [amountCalculations]);

  const autoshipDiscountAmount = autoshipCouponDiscount ? autoshipCouponDiscount.reduce((acc, curr) => acc + curr.discountAmount, 0) : 0;
  const customDiscountAmount = customCouponDiscount ? customCouponDiscount.reduce((acc, curr) => acc + curr.discountAmount, 0) : 0;


  return (
    <ReviewPackStyle>
      <div className="container">
        <div className="cmn-packs-wrap">

          <form onSubmit={formik.handleSubmit}>
            <DetailSection
              detail={signupDefualtValue}
              setBillingAddress={setBillingAddress}
              countryValue={countryValue}
              stateValue={stateValue}
              setCountryValue={setCountryValue}
              setStateValue={setStateValue}
              countryError={countryError}
              _onhandleChange={(value) => _onhandleChange(value)}
              stateError={stateError}
              setStateError={setStateError}
              isShippingSame={isShippingSame}
              setIsShippingSame={setIsShippingSame}
              _onChange={formik.handleChange}
              INITIALVALUES={INITIAL_VALUES}
              _onBlur={formik.handleBlur}
              formik={formik}

            />


            {(_.filter(other?.cartdata?.products, (row) => row?.category === "Member Pack"))?.length > 0 &&
              <>
                <div className="cmn-packs-heading">
                  <h3>
                    <small>YOUR PACK</small>
                  </h3>
                </div>

                {_.map(_.filter(other?.cartdata?.products, (row) => row?.category === "Member Pack"), (row, index) => {
                  const IDS = _.find(amountCalculations, { productid: +row?.product?.id });
                  return (
                    <>
                      <div className="cmn-packs-outer">
                        <div className="cmn-packs">
                          <figure>
                            {
                              <img src={apiPaths.DOC_URL + row?.product?.product_images[0]?.image} alt="helo" />
                            }
                          </figure>

                          <div className="cmn-pack-content">
                            <h5>{row?.product?.name}</h5>
                            <p>{row?.product?.description ? parse(row?.product?.description) : ""}</p>
                            {discount_amount1 !== undefined &&
                              discount_amount1 !== null ? (
                              <>
                                {IDS?.discountAmount !== 0 &&
                                  <div className="price-beforediscount">
                                    <NumberFormatComp value={IDS?.productAmount} />
                                  </div>
                                }
                                <div className="price-afterdiscount">
                                  <h6>

                                    <NumberFormatComp value={IDS?.productAmountAfterDiscount} />
                                  </h6>
                                </div>
                              </>
                            ) : (
                              <h6>${row?.product?.cost_price}</h6>
                            )}

                            {/* <h6>${row?.product?.cost_price}</h6> */}
                            {/* <button type="button" className="btn btn-6">
                            ONE TIME
                          </button> */}
                            {/* <div className="edit-del d-flex">
                              <div className="edit">
                                <a className="btn-primary-btn" onClick={() => editMemberpack()}>Edit <Edit /></a>
                              </div>
                              <div className="edit">
                                <a className="btn-primary-btn dlt-btn" onClick={() => deleteMemberPack(row?.product?.id, row?.is_autoship)}>Delete <Delete /></a>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })

                }

              </>
            }



            <div className="coupam-enter">
              <Discount
                cartData={other?.cartdata}
                coupon={coupon}
                setcoupon={setcoupon}
                amountCalculations={amountCalculations}
                setAmountCalculations={setAmountCalculations}
                amountAfterDiscount={amountAfterDiscount}
                setAmountAfterDiscount={setAmountAfterDiscount}
                logintoken={logintoken}
                setToken={setToken}
                setdiscount_amount={setdiscount_amount}
                discount_amount1={discount_amount1}
                setcopanerror={setcopanerror}
                totalPrice={totalPrice}
                setTotalPrice={setTotalPrice}
                setCouponData={setCouponData}
              />
            </div>
            {copanerror && <h4 className="added_coupon">{copanerror}</h4>}
            {(discount_amount1 !== undefined || discount_amount1 !== null) &&
              coupon?.name && (
                <h4 className="added_coupon">
                  {coupon?.name} Coupon Added{" "}
                  <button
                    className="dlt_btn"
                    onClick={(e) => {
                      removecoupon(e);
                    }}
                  >
                    <Delete
                      onClick={(e) => {
                        removecoupon(e);
                      }}
                    />
                  </button>
                </h4>
              )}
            {(_.filter(other?.cartdata?.products, (row) => row?.category !== "Member Pack"))?.length > 0 &&
              <div className="product-table-wrap">
                <div className="cmn-packs-heading">
                  <h3>
                    <small>YOUR ADDED PRODUCT/SMARTSHIPS</small>
                  </h3>
                </div>
                <div className="overflow-auto">

                  <table>
                    {(_.filter(other?.cartdata?.products, (row) => row?.category !== "Member Pack"))?.length > 0 &&
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Subtotal</th>
                          <th>Actions</th>
                        </tr>
                      </thead>}
                    <tbody>

                      {_.map(_.filter(other?.cartdata?.products, (row) => row?.category !== "Member Pack"), (row, index) => {
                        let AUTOSHIP_CHECK = objIndex >= 0 ? true : false;
                        let PRICE = AUTOSHIP_CHECK ? row?.product?.member_autoship_cost_price : row?.product?.cost_price;
                        const IDS = _.find(amountCalculations, { productid: +row?.product?.id });
                        return (
                          <tr>
                            <td>
                              <span className="product-table-img-wrap">
                                <span className="product-table-img">
                                  {/* <img src="/images/silverkaire.png" alt="Image" /> */}
                                  {row?.product?.product_images?.length >
                                    0 && (
                                      <img
                                        src={
                                          apiPaths.DOC_URL +
                                          row?.product?.product_images[0]?.image
                                        }
                                      />
                                    )}
                                </span>
                                <span>{row?.product?.name}</span>
                              </span>
                            </td>
                            <td>
                              <span className="product-price">
                                <NumberFormatComp value={PRICE} />
                                {row?.is_autoship === "False"
                                  ? ""
                                  :
                                  <span>Smartship</span>}
                              </span>
                            </td>
                            <td>{row?.quantity}</td>
                            <td>
                              {discount_amount1 !== undefined &&
                                discount_amount1 !== null ? (
                                <>
                                  {IDS?.discountAmount !== 0 &&
                                    <div className="price-beforediscount">
                                      <NumberFormatComp value={IDS?.productAmount} />
                                    </div>
                                  }
                                  <div className="price-afterdiscount">
                                    <NumberFormatComp value={IDS?.productAmountAfterDiscount} />
                                  </div>
                                </>
                              ) : (
                                <NumberFormatComp value={PRICE * +row?.quantity} />
                              )}
                            </td>
                            <td>
                              <span className="product-icons">
                                {row.category !== "Membership" &&
                                  <button type="button" className="delete" onClick={() => handleDelete(row?.product?.id, row?.is_autoship)}>
                                    <Delete />
                                  </button>
                                }
                                {row.category !== "Membership" &&
                                  <button onClick={() => handleEdit()}>
                                    <Edit />
                                  </button>
                                }
                              </span>
                            </td>
                          </tr>);
                      })}

                    </tbody>
                  </table>
                </div>
              </div>
            }
            <div className="payment-method-wrap">
              <div className="cmn-packs-heading">
                <h3>
                  <small>PAYMENT METHOD AND SHIPPING</small>
                </h3>
              </div>
              <div className="payment-method">

                {totalPrice.total !== 0 &&
                  <div className="cmn-payment-wrap">
                    <p>
                      <strong>Payment Method</strong>
                    </p>
                    <figure className="credit-card-img">
                      <img src="/images/creditcard.png" alt="Image" />
                    </figure>

                    <div className="payment-method-form">
                      <div className="form-group">
                        <input
                          type="text"
                          name="cardHolder"
                          className="form-control"
                          placeholder="Card Holder Name"
                          onChange={formik.handleChange}

                        />
                        {formik.touched.cardHolder && formik.errors.cardHolder ? (
                          <div className="error">{formik.errors.cardHolder}</div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="cardNumber"
                          onChange={formik.handleChange}
                          placeholder="Card Number" />
                        {formik.touched.cardNumber && formik.errors.cardNumber ? (
                          <div className="error">{formik.errors.cardNumber}</div>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          name="cardExpiry"
                          className="form-control"
                          placeholder="Card Expiry (MM/YYYY)"
                          value={formik.values.cardExpiry}
                          onChange={(e) => formatCardExpiry(e.target.value, formik.setFieldValue)} />
                        {formik.touched.cardExpiry && formik.errors.cardExpiry ? (
                          <div className="error">{formik.errors.cardExpiry}</div>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          name="cardCVC"
                          className="form-control"
                          placeholder="Card CVV"
                          onChange={formik.handleChange} />
                        {formik.touched.cardCVC && formik.errors.cardCVC ? (
                          <div className="error">{formik.errors.cardCVC}</div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <div className="shipping-check">
                          <label>
                            <input type="checkbox" name="save_cardit_card" id="" checked onChange={formik.handleChange} />
                            <span>Save Credit Card</span>
                          </label>
                        </div>
                      </div>
                    </div>

                  </div>
                }
                <div className="cmn-payment-wrap">
                  <div className="payment-table">
                    <table>
                      <tbody>
                        <tr>
                          <td>Order Total:</td>
                          {IsSmartshipProduct
                            ?
                            <td>
                              <NumberFormatComp value={totalPrice?.subtotal - _.reduce(ISMEMBERSHIP_PRICE, (sum, price) => sum + price, 0)} />
                            </td>
                            :
                            <td> <NumberFormatComp value={totalPrice?.subtotal} /></td>
                          }
                        </tr>
                        {autoshipDiscountAmount !== undefined && (
                          autoshipDiscountAmount !== null && (
                            autoshipDiscountAmount !== 0 && (
                              <tr>
                                <td>Discount Amount:
                                  <span>({coupon?.name})</span>
                                </td>
                                {discount_amount1 !== undefined &&
                                  discount_amount1 !== null && (
                                    <td className="discount-wrap">
                                      <span>-</span>
                                      <NumberFormatComp value={discount_amount1} />
                                    </td>
                                  )}
                              </tr>
                            )
                          )
                        )}
                        <tr>
                          {totalPrice?.subtotal > 0 && (

                            <>
                              <td>Shiping:</td>
                              <td>
                                Choose a Shipping method:
                                {shippingData?.length > 0 ? (
                                  shippingData?.map(
                                    (data, index) => {
                                      return (
                                        <FormControl
                                          key={index + 1}
                                          className="radio_option"
                                        >
                                          <FormControlLabel
                                            value="end"
                                            control={<Radio
                                              name="radioo"
                                              value={data?.shipping_cost}
                                              checked={selectedShipping?.id ===
                                                data?.id}
                                              onChange={() => _handleClick(
                                                data?.id,
                                                data?.shipping_cost
                                              )}
                                              inputProps={{
                                                "aria-label": "A",
                                              }} />}
                                            label={<p>{`${data?.name}: ${data?.shipping_cost}`}</p>} />
                                        </FormControl>
                                      );
                                    }
                                  )
                                ) : (
                                  <span>
                                    No shipping method on this
                                    address.
                                  </span>
                                )}
                                {radioError && (
                                  <span className="error">
                                    {radioError}
                                  </span>
                                )}


                              </td>
                            </>
                          )}
                        </tr>
                        <tr>
                          <td>Total:</td>
                          {IsSmartshipProduct
                            ?
                            amountCalculations?.length > 0 ?
                              <td>
                                <NumberFormat
                                  value={
                                    totalPrice?.subtotal -
                                    _.reduce(ISMEMBERSHIP_PRICE, (sum, price) => sum + price, 0) +
                                    selectedShipping?.amount -
                                    autoshipDiscountAmount -
                                    (discountSection?.currentTotalAmount || 0)
                                  }
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  renderText={(value) => (
                                    <div>
                                      ${parseFloat(value) === 0 ? '0.00' : value} USD
                                    </div>
                                  )}
                                />

                                {/* <NumberFormatComp value={totalPrice?.subtotal - _.reduce(ISMEMBERSHIP_PRICE, (sum, price) => sum + price, 0) + selectedShipping?.amount - autoshipDiscountAmount || discountSection?.currentTotalAmount} /> */}
                              </td>
                              :
                              <td>
                                <NumberFormatComp value={totalPrice?.subtotal - _.reduce(ISMEMBERSHIP_PRICE, (sum, price) => sum + price, 0) + selectedShipping?.amount || discountSection?.currentTotalAmount} />
                              </td>
                            :
                            <td>
                              <NumberFormatComp value={discountSection?.currentTotalAmount || totalPrice?.total} />
                            </td>
                          }

                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>




              {IsCustomProduct.length > 0 &&
                <div className="autoship-method-wrap">
                  <div className="cmn-autoship-packs-heading">
                    <h3>
                      <small>SMARTSHIP SHIPPING METHOD</small>
                    </h3>
                  </div>

                  <div className="autoship-payment-table">
                    <table>
                      <tbody>
                        <tr>
                          <td>Order Total:</td>
                          <td>
                            <NumberFormatComp value={_.reduce(ISMEMBERSHIP_PRICE, (sum, price) => sum + price, 0)} />
                          </td>
                        </tr>



                        {customDiscountAmount !== undefined && (
                          customDiscountAmount !== null && (
                            customDiscountAmount !== 0 && (
                              <tr>
                                <td>Discount Amount:
                                  <span>({coupon?.name})</span>
                                </td>
                                {discount_amount1 !== undefined &&
                                  discount_amount1 !== null && (
                                    <td className="discount-wrap">
                                      <span>-</span>
                                      <NumberFormatComp value={discount_amount1} />
                                    </td>
                                  )}
                              </tr>
                            )
                          )
                        )}

                        <tr>
                          {totalPrice?.subtotal > 0 && (

                            <>
                              <td>Shiping:</td>
                              <td>
                                Choose a Shipping method:
                                {customShippingData?.length > 0 ? (
                                  customShippingData?.map(
                                    (data, index) => {
                                      return (
                                        <FormControl
                                          key={index + 1}
                                          className="radio_option"
                                        >
                                          <FormControlLabel
                                            value="end"
                                            control={<Radio
                                              name="radioo"
                                              value={data?.shipping_cost}
                                              checked={selectedCustomProductShipping?.id ===
                                                data?.id}
                                              onChange={() => _handleCustomClick(
                                                data?.id,
                                                data?.shipping_cost
                                              )}
                                              inputProps={{
                                                "aria-label": "A",
                                              }} />}
                                            label={<p>{`${data?.name}: ${data?.shipping_cost}`}</p>} />
                                        </FormControl>
                                      );
                                    }
                                  )
                                ) : (
                                  <span>
                                    No shipping method on this
                                    address.
                                  </span>
                                )}
                                {autoShipRadioError && (
                                  <span className="error">
                                    {autoShipRadioError}
                                  </span>
                                )}


                              </td>
                            </>
                          )}
                        </tr>
                        <tr>
                          <td>Total:</td>
                          {
                            amountCalculations?.length > 0 ?
                              <td>
                                <NumberFormatComp value={_.reduce(ISMEMBERSHIP_PRICE, (sum, price) => sum + price, 0) + selectedCustomProductShipping?.amount - customDiscountAmount} />
                              </td>
                              :

                              <td>
                                <NumberFormatComp value={_.reduce(ISMEMBERSHIP_PRICE, (sum, price) => sum + price, 0) + selectedCustomProductShipping?.amount} />
                              </td>
                          }

                        </tr>
                        {REJECT_DATA_PRODUCT?.length > 0 &&
                          <tr>
                            <td>
                              <span>Smartship</span>
                              Start Date:</td>
                            <td>
                              <DatePicker
                                selected={minDate}
                                dateFormat="MM-dd-yyyy"
                                onChange={handleChangeDate}
                                minDate={moment().toDate()}
                                placeholderText="MM/DD/YY"
                              />
                              {
                                datePickerError && (
                                  <span className="error">{datePickerError}</span>
                                )
                              }

                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              }







            </div><div className="privacy-terms-wrap">
              <div className="cstm-checkbox-wrap">
                <label>
                  <input type="checkbox" name="termscondition3" onChange={formik.handleChange} />
                  <span className="cstm-checkbox" >
                    I have read and agree to the <a className="privacy_style" target='_blank' href='https://admin.shopkaire.com/media/pdf/Kaire_refund_&_return_Policy_new_may_2024.pdf'>Return Policy</a>, <a className="privacy_style" target='_blank' href='https://commandconcept.csdevhub.com/media/pdf/Kaire_PrivacyPolicy.pdf'> Privacy Policy</a> and  <a className="privacy_style" target='_blank' href="https://admin.shopkaire.com/media/pdf/Kaire_policies_&_procedures.pdf"> Policies & Procedures</a>.
                  </span>
                  {formik.touched.termscondition3 && formik.errors.termscondition3 ? (
                    <div className="error">{formik.errors.termscondition3}</div>
                  ) : null}
                </label>
              </div>
              <div className="cstm-checkbox-wrap">
                <label>
                  <input type="checkbox" name="termscondition4" onChange={formik.handleChange} />
                  <span className="cstm-checkbox">
                    I certify that I am at least 18 years old and eligible to purchase this product.
                  </span>
                  {formik.touched.termscondition4 && formik.errors.termscondition4 ? (
                    <div className="error">{formik.errors.termscondition4}</div>
                  ) : null}
                </label>
              </div>
              <div className="cstm-checkbox-wrap" name="termscondition5" >
                <label>
                  <input type="checkbox" name="termscondition5" onChange={formik.handleChange} />
                  <span className="cstm-checkbox">
                    {/* By acknowledging, I am agreeing to an annual automatic renewal purchase. This purchase will be automatically processed each month on the Smartship Order renewal date. */}

                    By acknowledging, I am agreeing to a yearly automatic membership renewal purchase.
                  </span>
                  {formik.touched.termscondition5 && formik.errors.termscondition5 ? (
                    <div className="error">{formik.errors.termscondition5}</div>
                  ) : null}
                </label>
              </div>
              {IsCustomProduct.length > 0 &&
                <div className="cstm-checkbox-wrap" name="termscondition6" >
                  <label>
                    <input type="checkbox" name="termscondition6" onChange={formik.handleChange} />
                    <span className="cstm-checkbox">
                      By acknowledging, I am agreeing to an automatic monthly Smartship purchase that will process on the Smartship order renewal date.
                    </span>
                    {formik.touched.termscondition6 && formik.errors.termscondition6 ? (
                      <div className="error">{formik.errors.termscondition6}</div>
                    ) : null}
                  </label>
                </div>
              }
            </div><div className="continue-btn">
              <button className="btn btn-6" onClick={() => PreviousButton()}>Back</button>
              <button type="submit" className="btn btn-2" onClick={() => handleCountryStateError()}>Process Payment</button>

              {shippingError && (
                <span className="error">{shippingError}</span>
              )}
            </div>
          </form>
        </div>


      </div >

    </ReviewPackStyle >
  );
};

export default ReviewPack;
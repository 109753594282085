import React from "react";
import styled from "styled-components";

export const SignUpStyle = styled.div`
  background-color: var(--signup-bg);
`;

export const SignupBannerStyle = styled.section`
  background-image: url("images/signup.jpg");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0 375px;
  position: relative;

  @media only screen and (max-width: 1399px) {
    padding: 70px 0 300px;
  }

  @media only screen and (max-width: 767px) {
    padding: 50px 0 225px;
  }

  &::after {
    content: "";
    height: 100%;
    width: 100%;
    /* background-color: rgba(0,0,0,0.3); */
    position: absolute;
    left: 0;
    top: 0;
  }

  .container {
    position: relative;
    z-index: 2;
  }



  .cstm-stepper {
    .MuiPaper-root,
    .MuiStepper-root {
      padding: 0;
      background: transparent;
      margin-top: 30px;
      gap: 20px;

      @media only screen and (max-width: 1199px) {
        gap: 15px;
      }

      @media only screen and (max-width: 767px) {
        /* gap: 10px; */
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.2);
        gap: 5px;
      }

      @media only screen and (max-width: 575px) {
        flex-wrap: wrap;
      }

      .MuiStep-alternativeLabel {
        flex: unset;
        padding: 0;

        &.MuiStep-completed,
        &.Mui-completed {
          .MuiStepLabel-root {
            .MuiTypography-root,
            .MuiStepLabel-label {
              color: var(--grey-text);
            }
          }
        }
        
        .MuiStepConnector-root {
          display: none;
        }
        
        .MuiStepLabel-root {
          &.Mui-disabled {
            .MuiTypography-root,
            .MuiStepLabel-label {
              color: var(--grey-text);
            }
          }
          .MuiStepLabel-iconContainer {
            display: none;
          }
          
          .MuiTypography-root,
          .MuiStepLabel-label {
            display: block;
            color: var(--cmn-white);
            font-weight: 300;
            font-size: 19px;
            line-height: 1.2;
            margin: 0;
            text-transform: uppercase;
            font-family: var(--common-font);
            
            @media only screen and (max-width: 1199px) {
              font-size: 17px;
            }
            
            @media only screen and (max-width: 767px) {
              font-size: 16px;
              font-weight: 600;
            }

            span {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .inner-logo {
    max-width: 280px;
    display: block;
    margin: 0 0 70px;

    @media only screen and (max-width: 1199px) {
      max-width: 250px;
      margin: 0 0 50px;
    }

    @media only screen and (max-width: 767px) {
      max-width: 200px;
      margin: 0 0 30px;
    }

    a {
      display: block;
      /* display: flex;
      align-items: center;
      width: 100%;
      font-size: 54px;
      line-height: 1.15;
      font-family: var(--logo-font);
      color: var(--cmn-white); */

      /* @media only screen and (max-width: 1199px) {
        font-size: 45px;
      }

      @media only screen and (max-width: 767px) {
        font-size: 40px;
      } */

      img {
        width: 100%;
        object-fit: contain;
        /* max-width: 54px;
        margin-right: 3px; */

        /* @media only screen and (max-width: 767px) {
          max-width: 50px;
        } */
      }
    }
  }

  h3 {
    margin-bottom: 4px;
    color: var(--cmn-white);
  }

  h5 {
    color: var(--cmn-white);
  }

  ol {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    li {
      &.active {
        a {
          color: var(--cmn-white);
        }
      }
      a {
        display: block;
        color: var(--grey-text);
        font-weight: 300;
        font-size: 19px;
        line-height: 1.2;

        span {
          font-weight: 600;
        }
      }
    }
  }
`;

export const SignupFormStyle = styled.section`
  margin-top: -340px;
  padding-bottom: 155px;
  position: relative;
  z-index: 2;

  @media only screen and (max-width: 1399px) {
    margin-top: -275px;
    padding-bottom: 100px;
  }

  @media only screen and (max-width: 767px) {
    margin-top: -200px;
    padding-bottom: 70px;
  }

  .already_exist_customer {
    .btn_register {
      border: none;
      background-color: unset;
      padding: 0;
      font-size: 14px;
      line-height: 1;
      color: var(--cmn-blue);
      transition: all ease-in-out 0.3s;
      outline: none;
      
      &:hover {
        text-decoration: underline;
      }
    } 
  }


  .form-wrap {
    padding: 50px 70px;
    background-color: var(--cmn-white);
    border: 1px solid var(--form-border);

    @media only screen and (max-width: 1399px) {
      padding: 50px;
    }

    @media only screen and (max-width: 991px) {
      padding: 40px;
    }

    @media only screen and (max-width: 767px) {
      padding: 30px;
    }

    @media only screen and (max-width: 575px) {
      padding: 20px 15px;
    }

    .main-cstm {
      margin-bottom: 20px;
      text-align: center;
      background: linear-gradient(90deg,  rgba(209,137,168,1) 0%, rgba(94,136,236,1) 50%, rgba(2,55,109,1) 100%);
      padding: 5px 10px;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
    }

    form {
      max-width: 845px;
      margin: auto;

      .form-row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        .form-group {
          padding: 0 10px;
          margin-bottom: 24px;
          width: calc(100% / 2);
          position: relative;

          &.select-drop {
            position: relative;
            &::after {
              content: "";
              width: 8px;
              height: 8px;
              border: 2px solid;
              position: absolute;
              border-top: 0;
              border-left: 0;
              transform: rotate(45deg);
              right: 25px;
              top: 15px;
              pointer-events: none;
            }

            .form-control {
              padding-right: 30px;
            }
          }

          @media only screen and (max-width: 991px) {
            width: calc(100%);
          }

          .eye-icon {
            position: absolute;
            top: 8px;
            right: 20px;
            z-index: 2;
            cursor: pointer;

            .MuiSvgIcon-root {
              width: 16px;
              height: 16px;
            }
          }

          span.error {
            /* display: block; */
            font-size: 14px;
            line-height: 1;
            margin-top: 5px;
            color: red;
          }

          .pass-show {
            &.form-control {
              padding-right: 30px;
            }
          }

          &.form-btn-wrap {
            margin: 16px 0 0;
          }

          &.w-100 {
            width: 100%;
          }

          .form-control,
          .cstm-referral {
            height: 41px;
            box-shadow: none;
            outline: none;
            border: 1px solid var(--input-border);
            border-radius: 30px;
            padding: 11px 12px;
            font-size: 14px;
            line-height: 1.2;
            color: var(--cmn-black);

            &::placeholder {
              color: var(--cmn-black);
            }

            @media only screen and (max-width: 991px) {
              font-size: 13px;
            }

          }

          .cstm-checkbox-wrap {
            label {
              .cstm-checkbox-input {
                position: absolute;
                pointer-events: none;
                opacity: 0;
                visibility: hidden;

                &:checked {
                  & ~ .cstm-checkbox {
                    color: var(--cmn-blue);

                    &::before {
                      background-color: var(--cmn-blue);
                      border-color: var(--cmn-blue);
                    }

                    &::after {
                      opacity: 1;
                    }
                  }
                }
              }

              .cstm-checkbox {
                font-size: 14px;
                line-height: 1.2;
                color: var(--cmn-blue);
                position: relative;
                display: flex;
                align-items: center;
                transition: all ease-in-out 0.3s;
                cursor: pointer;
                padding-left: 22px;

                &::before {
                  content: "";
                  height: 18px;
                  width: 18px;
                  border: 2px solid var(--cmn-black);
                  display: block;
                  border-radius: 100%;
                  margin-right: 4px;
                  transition: all ease-in-out 0.3s;
                  position: absolute;
                  left: 0;
                  top: -2px;
                }

                &::after {
                  content: "";
                  position: absolute;
                  top: 3px;
                  left: 3px;
                  height: 5px;
                  width: 11px;
                  border-left: 2px solid var(--cmn-white);
                  border-bottom: 2px solid var(--cmn-white);
                  transform: rotate(-45deg);
                  transition: all ease-in-out 0.3s;
                  opacity: 0;
                }
              }
            }
          }

          .cstm-referral {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 7px 12px;

            input {
              border: none;
              flex: 1;
              outline: none;
              box-shadow: none;
              padding: 0;
              color: var(--cmn-black);

              &::placeholder {
                color: var(--cmn-black);
              }
            }

            .extra-content {
              display: flex;
              align-items: center;

              >div{
                margin-left: 3px;
                height: 25px;
                width: 25px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--blue);
                color: #fff;
                font-size: 12px;
                cursor: pointer;
              }
            }

            .info-icon {
              height: 25px;
              width: 25px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: var(--cmn-blue);
              border-radius: 100%;
              margin-left: 10px;
              font-size: 14px;

              @media only screen and (max-width: 991px) {
                margin-left: 5px;
                height: 20px;
                width: 20px;
                font-size: 12px;
              }

              svg {
                display: block;
                fill: var(--cmn-white);
              }
            }
          }
        }

        p {
          font-size: 14px;
        }
      }
      .btn {
        font-size: 16px;
        line-height: 1.75;
        min-width: unset;
        border-width: 1px;
        padding: 5px 29px;
        text-transform: uppercase;
      }
    }
  }
`;

import { styled } from "styled-components";

export const HeaderCss = styled.header`
  background: url("/images/footer-bg.png"),
    linear-gradient(
      90deg,
      rgba(2, 55, 109, 1) 0%,
      rgba(94, 136, 236, 1) 44.7845%,
      rgba(209, 137, 168, 1) 100%
    );
  padding: 25px 0 24px;
  position: relative;
  z-index: 2;

  @media only screen and (max-width: 991px) {
    padding: 15px 0;
  }

  .header-nav {
    display: flex;
    align-items: center;
    position: relative;

    &>.btn {
      @media only screen and (max-width: 767px){
        display: flex!important;
        margin-left: auto;
        min-width: unset;
        padding: 0;
        border-radius: 0;
        background: #fff;
        line-height: 1;
        height: 24px;
        width: 30px;
        align-items: center;
        justify-content: center;

        svg {
          width: 100%;
          fill: #000;
        }
      }
    }

    figure {
      max-width: 296px;
      display: block;
      margin: 0 48px 0 0;

      @media only screen and (max-width: 1399px) {
        max-width: 250px;
      }

      @media only screen and (max-width: 1199px) {
        max-width: 200px;
        margin: 0 30px 0 0;
      }

      a {
        display: block;
        /* display: flex;
        align-items: center;
        font-size: 54px;
        line-height: 1.15;
        font-family: var(--logo-font);
        color: var(--cmn-white); */
        
        /* @media only screen and (max-width: 1199px) {
          font-size: 45px;
        }

        @media only screen and (max-width: 767px) {
          font-size: 40px;
        } */
      }

      img {
        width: 100%;
        object-fit: contain;
        /* max-width: 54px;
        margin-right: 3px; */

        /* @media only screen and (max-width: 767px) {
          max-width: 50px;
        } */
      }
    }

    .header-links {
      flex: 1;

      @media only screen and (max-width: 767px) {
        display: none;
        position: absolute;
        top: calc(100% + 15px);
        left: 0;
        background-color: #fff;
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.3);

        &.active {
          display: block;
        }
      }

      ul {
        display: flex;
        gap: 40px;

        @media only screen and (max-width:991px){
          gap: 20px;
        }

        @media only screen and (max-width: 767px) {
          flex-direction: column;
          gap: 5px;
        }
        
        li {
          display: block;
          font-size: 20px;
          line-height: 1.2;
          color: #fff;
          cursor: pointer;
          transition: all ease-in-out 0.3s;
          

          &:hover {
            color: var(--cmn-blue);
          }

          &.products_section_class {
            position: relative;
            padding-right: 20px;

            &.toggle_active{

              &::after {
                @media only screen and (max-width:767px) {
                  transform: rotate(225deg);
                  top: 6px;
                }
              }
              .products_pop_class {
                @media only screen and (max-width:767px) {
                  display: block;
                }
              } 
            }

            &:hover{
              .products_pop_class {
                @media only screen and (min-width:768px) {
                  display: block;
                }
              }
            }

            &::after {
              content: "";
              display: block;
              box-sizing: border-box;
              position: absolute;
              width: 10px;
              height: 10px;
              border-bottom: 2px solid rgb(255, 255, 255);
              border-right: 2px solid rgb(255, 255, 255);
              transform: rotate(45deg);
              right: 4px;
              top: 5px;
              border-top-color: rgb(255, 255, 255);
              border-left-color: rgb(255, 255, 255);
              transition: all ease-in-out 0.3s;

              @media only screen and (max-width: 1399px) {
                top: 3px;
              }

              @media only screen and (max-width: 1199px) {
                top: 4px;
                right: 6px;
                width: 8px;
                height: 8px;
              }

              @media only screen and (max-width: 767px) {
                border-color: #000;
                right: 0px;
              }
            }
            .products_pop_class {
              list-style: none;
              margin: 0px;
              position: absolute;
              left: 0px;
              top: 18px;
              min-width: 215px;
              display: none;
              z-index: 2;
              overflow: hidden;
              padding-top: 49px;
              box-shadow: unset;

              @media only screen and (max-width:1399px) {
                padding-top: 44px !important;
              }

              @media only screen and (max-width:1199px) {
                padding-top: 37px !important;
              }

              @media only screen and (max-width:991px) {
                padding-top: 27px !important;
              }

              @media only screen and (max-width:767px) {
                position: static;
                padding: 0 !important;
              }
              li{
              background: rgb(0, 53, 106);
              border-radius: 0px;
              padding: 20px 15px 0px;

              @media only screen and (max-width:767px) {
                padding: 0 10px;
                padding: 10px 10px 0;
                background-color: transparent;
              }
              &:last-child{
                padding-bottom:20px;

                @media only screen and (max-width:767px) {
                  padding: 10px 10px;
                }
              }
              a{
                font-size:16px;
                @media only screen and (max-width:767px) {
                  font-size:14px;
                }
                &:hover{
                  color: #fff;
                }
              }
              }
              &:before{
                display: none;
              }
            }

          }
            
            @media only screen and (max-width: 1399px) {
                font-size: 18px;
                .products_pop_class{
                  padding-top: 62px !important;
                }
            }
            
            @media only screen and (max-width: 1199px) {
                font-size: 16px;
                .products_pop_class{
                  padding-top: 56px !important;
                }
            }

            @media only screen and (max-width: 991px) {
                .products_pop_class{
                  padding-top: 47px !important;
                }
            }
            
            @media only screen and (max-width: 767px) {
            color: #000;
            display: block;
            line-height: 1;
          }
          &.left-slide {
            margin-left: auto;
            
            @media only screen and (max-width: 767px) {
              margin-left: unset;              
            }
          }

          &.cstm-drop {
            display: flex;
            position: relative;

            @media only screen and (max-width: 767px) {
              flex-wrap: wrap;
            }

            &:hover {
              .dashboard_div {
                display: block;
              }
            }

            a {
              display: flex;
            }

            .dashboard_div {
              position: absolute;
              left:0;
              width: 100%;
              top: 100%;
              display: none;
              box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
              
              @media only screen and (max-width: 767px) {
                position: static;
                margin-top: 5px;
                box-shadow: none;
              }

              
              li {
                padding: 0 15px;
                background: #fff;
                display: block;

                @media only screen and (max-width: 767px) {
                  padding: 0;
                }

                button {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  column-gap: 10px;
                  font-size: 13px;
                  width: 100%;
                  border-radius: 0;

                  @media only screen and (max-width: 767px) {
                    width: auto;
                    font-size: 16px;
                    padding: 0;
                    line-height: 1.2;
                    color: var(--cmn-black);
                    
                    &:hover {
                      color: var(--cmn-blue);
                    }
                  }

                  &:hover {
                    background-color: transparent;
                  }
                }
              }

              .logout_icon {
                display: block;
              }
            }
          }

          a {
            font-size: 20px;
            line-height: 1.2;
            color: var(--cmn-white);
            display: block;
            
            @media only screen and (max-width: 1399px) {
                font-size: 18px;
            }
            
            @media only screen and (max-width: 1199px) {
                font-size: 16px;
            }

            @media only screen and (max-width: 767px) {
              color: #000;
              display: inline-block;
            }

            &:hover {
              color: var(--cmn-blue);
            }

            span {
              display: block;

              &.account_icon {
                margin-left: 5px;
              }

              svg {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .header-sec img {
    max-width: 50px;
  }
  .top-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 70px;
    @media (max-width: 991px) {
      padding: 15px 30px;
    }
    @media (max-width: 767px) {
      padding: 15px;
    }
  }
  .header-sec span {
    color: #00356a !important;
    font-size: 20px;
    letter-spacing: 1px;
    margin-left: 10px;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
  .top-sec a {
    color: #000;
    font-size: 20px;
    text-decoration: none;
    &:first-child {
      margin-right: 10px;
    }
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
/* headercss27aug */
ul.products_pop_class:before {
    content: "";
    height: 5px;
    width: 100%;
    position: absolute;
    top: 0;
    filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: -1;
    background: #000000ad;
  }
  .collapse.navbar-collapse {
    max-width: calc(100% - 58px);
    flex: calc(100% - 58px);
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0;
    @media (min-width: 1025px) {
      display: inline-flex !important;
    }
  }

  ul.nav.navbar-nav.navbar-left {
    max-width: calc(100% - 380px);
    flex: calc(100% - 380px);
    width: 100%;
  }
  ul.nav.navbar-nav.navbar-right > li a.font_change {
    padding: 0;
    color: #fff;
    line-height: normal;
  }

  ul.nav.navbar-nav.navbar-right {
    float: none !important;
  }

  ul.nav.navbar-nav.navbar-right > li .dashboard_div {
    line-height: normal;
  }

  .upper_lower_div ul.nav.navbar-nav.navbar-right li.share-kaire:after {
    content: "";
    height: 100%;
    width: 2px;
    background: #fff;
    position: absolute;
    right: 0;
    top: 0;
    @media (max-width: 1024px){
      display: none;
    }
  }

`;
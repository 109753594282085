import _ from "lodash";
import React, { useEffect, useState } from "react";
import api from "../../../api/Apis";
import apiPaths from "../../../services/apPaths";
import { EnrollmentPackStyle } from "./EnrollmentStyle";
import parse from "html-react-parser";


const EnrollmentPack = ({ handleNext, prvStep, minBV, setMinBv, setActiveStep, ...props }) => {
  const { setshowloder, userToken, getMiniCartProduct, setUserToken, memberShipProduct, setMemberShipProduct, setshowminicart, activeStep, addToCart, hideShowState, setcounts,
  } = props;
  const [packProduct, setPackProduct] = useState([]);
  const [activeClass, setActiveClass] = useState();
  const [selectedPack, setSelectedPack] = useState({});

  useEffect(() => {
    cartProduct();
  }, []);

  function cartProduct() {
    api.promoterPackApi().then((res) => {
      const { code, promoter_packs } = res?.data;
      if (res?.status === 200 && code === 1) {
        const data = _.find(promoter_packs, { name: "Membership" });
        const packsproduct = _.reject(promoter_packs, { name: "Membership" });
        setMemberShipProduct(data);
        setPackProduct(_.reject(promoter_packs, { name: "Membership" }));
        api.getAllCartProduct().then((res) => {
          const filterData = _.find(res?.data?.products, {
            category: "Member Pack",
          });
          let id = null;
          if (filterData && filterData.product) {
            id = filterData.product.id;
          }
          let filterId = null;
          if (id !== null) {
            filterId = _.find(activeClass, { id: id });
          }
          if (filterId) {
            filterId["hidden"] = !filterId["hidden"];
            const REMAIN_DATA = _.reject(activeClass, filterId);
            if (!filterId["hidden"]) {

              _deletePromoterCustomPack({ product_id: selectedPack?.id, is_autoship: false }, "promoterPack_delete")
              setSelectedPack(filterId);
              const PromoterPack = {
                product_id: id,
                variant_id: null,
                token: userToken,
                quantity: 1,
                is_autoship: false,
                promoterDetail: packProduct?.find((row) => row?.id === id)
              };
              localStorage.setItem("PromoterPack", JSON.stringify(PromoterPack));
              setActiveClass([...REMAIN_DATA, filterId]);
            } else {

              const formdata = { product_id: id, is_autoship: false };
              _deletePromoterCustomPack(formdata, "promoterPack_remove")
              setSelectedPack({});
            }

          }
        });
      }
    })
  }

  useEffect(() => {

    _promoterPackApi();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);
  async function _promoterPackApi() {
    // setshowloader(true);

    await api.promoterPackApi().then((res) => {
      const { code, promoter_packs } = res?.data;
      const allvalues = _.map(promoter_packs, (row) => ({
        id: row?.id,
        hidden: true,
        is_autoship: false
      }));
      if (res?.status === 200 && code === 1) {
        const data = _.find(promoter_packs, { name: "Membership" });
        setMemberShipProduct(data);
        setPackProduct(_.reject(promoter_packs, { name: "Membership" }));
        // setshowloader(false);
        setActiveClass(allvalues);
        pack(allvalues);
      }
    });

  }

  function pack(data) {
    const promoterData = JSON.parse(localStorage.getItem("PromoterPack"));
    if (promoterData) {
      const filterId = _.find(data, { id: promoterData?.product_id });
      if (filterId) {
        filterId["hidden"] = !filterId["hidden"];
        const REMAIN_DATA = _.reject(data, filterId);
        if (!filterId["hidden"]) {
          setSelectedPack(filterId);
        }
        setActiveClass([...REMAIN_DATA, filterId]);
      }
    }
  }

  useEffect(() => {
    pack();
  }, [activeStep]);


  useEffect(() => {
    const token = localStorage.getItem("Token")
      ? localStorage.getItem("Token")
      : null;
    setUserToken(token);
  }, []);

  async function _deletePromoterCustomPack(formdata, section) {
    await api.deleteProductFromAddToCart(formdata).then((res) => {
      if (res?.data?.code === 1) {
        if (section === "promoterPack_remove") {
          localStorage.removeItem('packProduct')
          localStorage.removeItem("PromoterPack");
        } else if (section === "promoterPack_delete") {
          getMiniCartProduct("delete", "");
          localStorage.removeItem('packProduct')
        } else {
          getMiniCartProduct("delete", "");
          localStorage.removeItem("PromoterPack");
        }

        // toast.success(res?.data?.message);
      }
    });
  }

  function _handleSelect(id, is_autoship) {
    getMiniCartProduct()
    const filterId = _.find(activeClass, { id: id });
    if (filterId) {
      filterId["hidden"] = !filterId["hidden"];
      const REMAIN_DATA = _.reject(activeClass, filterId);
      if (!filterId["hidden"]) {
        _deletePromoterCustomPack({ product_id: selectedPack?.id, is_autoship: false }, "promoterPack_delete")
        setSelectedPack(filterId);
        const PromoterPack = {
          product_id: id,
          variant_id: null,
          token: userToken,
          quantity: 1,
          is_autoship: false,
          promoterDetail: packProduct?.find((row) => row?.id === id)
        };
        localStorage.setItem("PromoterPack", JSON.stringify(PromoterPack));
        addToCart(PromoterPack); handleNext();
        localStorage.removeItem('packProduct')
        const dataSss = localStorage.getItem("packProduct") ? JSON.parse(localStorage.getItem("packProduct")) : null;

        let couuntValue = dataSss !== null ? PromoterPack + 1 : +PromoterPack;
        setcounts(couuntValue);
        // setcounts(PromoterPack)
        // setshowminicart(true);
      } else {
        const formdata = { product_id: id, is_autoship: false };
        _deletePromoterCustomPack(formdata, "promoterPack_remove")
        setSelectedPack({});
      }
      setActiveClass([...REMAIN_DATA, filterId]);
    }
  }
  const isStockAvailable = _.filter(packProduct, { is_stock_available: "False" })
  return (
    <EnrollmentPackStyle>
      <div className="container">
        <div className="cmn-packs-wrap">
          <div className="cmn-packs-heading">
            <h3>
              <small>MEMBER PACKS</small>
            </h3>
          </div>
          <div className="cmn-packs-outer">
            {_.map(packProduct, (row, index) => (
              <>

                <div
                  // className="cmn-packs"
                  className={_.find(activeClass, { id: row?.id })?.hidden ? "cmn-packs" : "cmn-packs active-pack"}
                >
                  {row?.product_images?.length > 0 && (
                    <figure>
                      <img src={apiPaths.DOC_URL + row?.product_images[0]?.image} alt="Image" />
                    </figure>
                  )}
                  <div
                    className="cmn-pack-content"

                  >
                    <h5>{row?.name}</h5>
                    <p>{row?.description ? parse(row?.description) : ""}</p>
                    <h6> ${parseFloat(row?.autoship_cost_price).toFixed(2)}
                      <span> {parseFloat(row?.bonus_value)} BV</span>
                    </h6>
                    <button type="button"
                      // className={_.find(activeClass, { id: row?.id })?.hidden ? "btn btn-6" : "active-packs"}
                      className="btn btn-6"
                      onClick={() => _handleSelect(row?.id, row?.is_autoship)}
                      disabled={isStockAvailable.some(data => data.id === row.id)}
                    >
                      {isStockAvailable.some(data => data.id === row.id) ?
                        "OUT OF STOCK" :
                        `SELECT PACK ${index + 1}`
                      }
                    </button>
                  </div>
                  <span className="save_tag">
                    SAVE ${parseFloat(row?.member_save_price).toFixed(2)}</span>
                </div>
              </>
            ))}
          </div>
          <div className="continue-btn">
            <a onClick={() => handleNext()} className="text-btn">No thanks, continue</a>
          </div>

        </div>
      </div>
    </EnrollmentPackStyle>
  );
};

export default EnrollmentPack;

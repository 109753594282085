
import React, { useEffect, useState } from 'react';
import CustomerBonus from './components/CustomerBonus';
import HighlightSection from './components/HighlightSection';
import RewardBanner from './components/RewardBanner';
import SmartshipBonus from './components/SmartshipBonus';
import FreeBonus from './components/FreeBonus';
import FirstBonus from './components/FirstBonus';
import FirstBonusMatch from './components/FirstBonusMatch';
import UnilevelComission from './components/UnilevelComission';
import UnilevelComissionMatch from './components/UnilevelCommissionMtach';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Pagination } from 'swiper/modules';
import { RewardMainStyle } from './RewardMainStyle';
import Footer from '../../Layout/Footer';
import MemberHighlight from './components/MemberHighlight';
import ViewCartPopup from '../../Components/Common/ViewCartPopup';
import { useNavigate } from 'react-router-dom';
// import BannerTop from './components/BannerTop';

const Reward = (props) => {
  const [getProfileData, setGetProfileData] = useState();
  const [userRank, setUserRank] = useState();

  const navigate = useNavigate()
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [])
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const GetProfileData = localStorage.getItem("Token");
    setGetProfileData(GetProfileData);
    const RANK_DATA = localStorage?.getItem("profileData") ? JSON.parse(localStorage?.getItem("profileData")) : ""
    setUserRank(RANK_DATA);
  }, [getProfileData])

  const popRef = React.useRef(null);
  const Validatelogin = (e) => {
    if (getProfileData) {
      navigate('/signup');
    }
    else {
      popRef.current.setRefferalPopup(true);
      popRef.current.setCorrectRefferal({ status: false, data: {} });
      setOpen(true);
    }
  };

  return (
    <>
      <RewardMainStyle >
        {/* <BannerTop /> */}
        <RewardBanner Validatelogin={Validatelogin} userRank={userRank} />
        <HighlightSection Validatelogin={Validatelogin} userRank={userRank} />
        <MemberHighlight Validatelogin={Validatelogin} userRank={userRank} />
        {/* <CustomerBonus /> */}
        <SmartshipBonus Validatelogin={Validatelogin} userRank={userRank} />
        <FreeBonus Validatelogin={Validatelogin} userRank={userRank} />
        <FirstBonus Validatelogin={Validatelogin} userRank={userRank} />
        <FirstBonusMatch Validatelogin={Validatelogin} userRank={userRank} />
        <UnilevelComission Validatelogin={Validatelogin} userRank={userRank} />
        <UnilevelComissionMatch Validatelogin={Validatelogin} userRank={userRank} />
      </RewardMainStyle>
      <ViewCartPopup
        ref={popRef}
        content={{
          open: open,
          setOpen: setOpen,
          ...props,
        }} />
    </>
  )
}

export default Reward

import React from "react";
import { UnilevelComissionMatchCss } from "./RewardStyle";

const UnilevelComissionMatch = (props) => {
  const { Validatelogin, userRank } = props
  return (
    <UnilevelComissionMatchCss>
      <div className="container">
        <div className="cstm-row">
          <div className="col-6">
            <div className="unilevel-commission-match-content">
              <h2>
                <span>UNILEVEL COMMISSIONS MATCH</span>
              </h2>
              <ul>
                <li>Infinite Width</li>
                <li>More Enrollments, More Matches</li>
                <li>Easy qualification</li>
                <li>Boost Income</li>
                <li>Team Growth</li>
              </ul>
              <div className="btn-wrap">
                <a type="button" className="btn"
                  // href="https://commandconcept.csdevhub.com/media/pdf_files/Rewards_Plan_040324.pdf" 
                  href="https://admin.shopkaire.com/media/pdf/Kaire_RewardsPlan_102924.pdf"
                  target="_blank">
                  The full rewards plan
                </a>
                {userRank?.user_data?.userdetails?.[0]?.qualified_as_rank !== 3 && <button type="button" className="btn btn-2 " onClick={() => Validatelogin()}>
                  Signup
                </button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </UnilevelComissionMatchCss>
  );
};

export default UnilevelComissionMatch;

// import NumberFormat from 'react-number-format';
import { FaTrash } from "react-icons/fa";
// import { NumberFormatBase } from 'react-number-format';
import _ from 'lodash';
import apiPaths from "../../../services/apPaths";
const Product = ({ smartShipCheck, is_autoship, minCartAutoshipCheck, Cart, deleteproduct, index, Add, Sub, updateqty, setcosttype, costtype, swithtoautoship }) => {
  //check product is selected to autoship or normal

  function _setAutoshipFunction(event, id, orderId) {

    // const found = _.find(costtype, { id: id });
    // if (found) {
    //   const removeData = _.reject(costtype, found);
    //   setcosttype([...removeData, { id: id, autoship: event?.target?.value }]);
    // } else {
    //   setcosttype([...costtype, { "id": id, "autoship": event?.target?.value }]);
    // }
    swithtoautoship(event, orderId);
  }

  return (
    <>
      <tr key={index}>
        <td>
          <div className="cart-image">
            {Cart?.product?.product_images[0]?.image
              ?
              <img src={`${apiPaths.DOC_URL}${Cart?.product.product_images[0]?.image}`} />
              :
              <img src="/images/no-image.jpg" />
            }
          </div>
        </td>
        <td>
          {Cart?.extraProduct &&
            <div className='free_product_title'>
              <p style={{
                color: "#f00",
                fontFamily: 'Graphik-bold',
                margin: 0
              }}>Your Free Product!</p>
            </div>
          }
          <div className="cart-product-details">
            {Cart?.product?.name}
          </div>
        </td>
        {(!Cart?.extraProduct) ?
          <>
            <td>
              <div className="">
                {["Product", "Bundle"].includes(Cart?.product?.product_type) &&
                  <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                    data-product-id={Cart?.product?.id}
                    data-product-qty={Cart?.quantity}
                    data-variant-id={null}

                    onChange={(e) => _setAutoshipFunction(e, Cart?.product?.id, Cart?.id)}
                    value={_.find(costtype, { orderId: Cart?.id })?.autoship}
                  >
                    <option value="Normal">One-time Only</option>
                    <option value="AutoShip">SmartShip</option>
                    {/* <option value="AutoShip" selected={is_autoship==='True'?true:false} >AutoShip</option> */}
                  </select>
                }
              </div>
            </td>
            <td>
              <div className="main-qty-sec">
                <div className="box">
                  {(Cart?.qty_display) ?
                    <div id="qty">
                      <button type="button" name={`${Cart?.product?.id},${null}`} id={Cart?.product?.id} className="sub" value={Cart?.quantity} onClick={(e) => { Sub(e, Cart?.id) }}>-</button>
                      <input
                        name={`${Cart?.product?.id},${null}`}
                        type="text"
                        // ref={register &&
                        //   register({
                        //     valueAsNumber: true,
                        //   })
                        // }
                        value={+Cart?.quantity}
                      />
                      <button type="button" name={`${Cart?.product?.id},${null}`} id={Cart?.product?.id}
                        data-value={Cart?.product?.quantity}
                        className="add"
                        value={Cart?.quantity}
                        onClick={(e) => { Add(e, Cart?.id) }}>+</button>
                    </div>
                    :
                    <div id="qty">
                      <button type="button" name={`${Cart?.product?.id},${null}`} id={Cart?.product?.id} className="sub">-</button>
                      <input
                        name={`${Cart?.product?.id},${null}`}
                        type="text"
                        readOnly={true}
                        // ref={register &&
                        //   register({
                        //     valueAsNumber: true,
                        //   })
                        // }
                        value={+Cart?.quantity}
                      />
                      <button type="button" name={`${Cart?.product?.id},${null}`} id={Cart?.product?.id} data-value={Cart?.product?.quantity} className="add">+</button>
                    </div>
                  }
                </div>
              </div>
            </td>
          </>
          :
          <>
            <td></td>
            <td>
              <div className="main-qty-sec">
                <div style={{
                  width: "100%",
                  maxWidth: "112px",
                  textAlign: "center",
                }}>
                  <input
                    name={`${Cart?.product?.id},${null}`}
                    type="text"
                    style={{
                      width: "40px",
                      background: "#fff",
                      border: "1px solid var(--blue)",
                      textAlign: "center",
                      padding: "7.5px"
                    }}
                    readOnly
                    value={+Cart?.quantity}
                  />
                </div>
              </div>
            </td></>
        }
        <td>
          <div className="cart-product-details">
            <div className="title">
              {/* <NumberFormatBase value={parseFloat(Cart?.product?.cost_price * Cart?.quantity).toFixed(2)}
                displayType={'text'}
                thousandSeparator={true} prefix={'$'}
                renderText={value => <div> {
                  // (Cart?.is_autoship !== 'True' && minCartAutoshipCheck !== "True")
                  (minCartAutoshipCheck !== "True" && smartShipCheck !== true) ? value + " " + "USD" : `$${parseFloat(Cart?.product?.[`${(minCartAutoshipCheck === "True" || smartShipCheck)
                    ? "autoship_cost_price"
                    : "cost_price"}`] * Cart?.quantity).toFixed(2)}` + " " + "USD" + (Cart?.is_autoship === "True" ? '/ $' + parseFloat(Cart?.product?.autoship_cost_price * Cart?.quantity).toFixed(2) + " " + "USD" : "") + " " + (Cart?.is_autoship === "True" ? 'SmartShip' : "")}
                </div>
                }
              /> */}

            </div>
            {Cart?.is_autoship === "True" ?
              <div className="title">
                {/* <Hover data="Ships now & after 28 days" /> */}
              </div>
              :
              <div className="title">
                {/* <Hover data="SmartShip is deactivated" /> */}
              </div>
            }
          </div>
        </td>
        <td>
          <div className="pro-cart-table">
            <div className="cart-product-qty-del">
              {(!Cart?.extraProduct) &&
                <button className="dlt" >
                  {/* <FaTrash onClick={(e) => { deleteproduct(Cart?.product?.id, Cart?.variant?.id, Cart?.id, Cart?.is_autoship) }} /> */}
                  <FaTrash
                    onClick={() => deleteproduct(Cart?.product?.id, Cart?.variant?.id, Cart?.id, Cart?.is_autoship)}
                  />
                </button>
              }
            </div>
          </div>
        </td>
      </tr>

    </>)
}
export default Product;
import React from "react";
import { FirstBonusMatchCss } from "./RewardStyle";

const FirstBonusMatch = (props) => {
  const { Validatelogin, userRank } = props
  return (
    <FirstBonusMatchCss>
      <div className="container">
        <div className="cstm-row">
          <div className="col-6">
            <div className="first-bonus-match-content">
              <h2>
                <span>FIRST ORDER BONUS MATCH</span>
              </h2>
              <ul>
                <li>
                  10% Match on First Order Bonus: Earn a generous 10% match on
                  the First Order Bonus commissions of your personally enrolled
                  members and their personally enrolled down 3 Levels!
                </li>
                <li>
                  Boosted Earnings: : Imagine your personally enrolled member
                  earns $500 in the First Order Bonus. You would receive a $50
                  match, boosting your earnings.
                </li>
                <li>
                  Unlimited Width: No limit to the number of matches you can
                  earn.
                </li>
                <li>
                  Build Your Team: Just enroll 2 active and qualified members to
                  qualify for the match.
                </li>
              </ul>
              <div className="btn-wrap">
                <a type="button" className="btn"
                  // href="https://commandconcept.csdevhub.com/media/pdf_files/Rewards_Plan_040324.pdf"
                  href="https://admin.shopkaire.com/media/pdf/Kaire_RewardsPlan_102924.pdf"
                  target="_blank">
                  The full rewards plan
                </a>
                {userRank?.user_data?.userdetails?.[0]?.qualified_as_rank !== 3 && <button type="button" className="btn btn-2" onClick={() => Validatelogin()}>
                  Signup
                </button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </FirstBonusMatchCss>
  );
};

export default FirstBonusMatch;

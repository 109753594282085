import styled from "styled-components";

const LoginStyle = styled.div`
  padding-top: 110px;
  height: 100vh;
  min-height: 700px;
  display: flex;
  align-items: center;
  background: url("/images/reward-banner.png") no-repeat; 
  background-size: cover;
  margin-bottom: -13px;
@media (max-width: 991px){
  height: auto;
  margin-bottom: -10px;
  background-position: center;
}
  & form.signupform.main-sign-frm {  
    margin: 0;
	width: 400px;
	background: rgb(182,145,230);
	background: -moz-linear-gradient(173deg, rgba(182,145,230,1) 0%, rgba(110,139,222,1) 100%);
	background: -webkit-linear-gradient(173deg, rgba(182,145,230,1) 0%, rgba(110,139,222,1) 100%);
	background: linear-gradient(173deg, rgba(182,145,230,1) 0%, rgba(110,139,222,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b691e6",endColorstr="#6e8bde",GradientType=1); 
	border-radius: 25px;
	overflow: hidden;
	position: absolute;
	right: 70px;
  padding: 25px;    
  left: auto;  
  top: 180px;
    @media (max-width: 991px){
      flex-wrap: wrap; 
   
    }
    @media (max-width: 767px){
      position: unset;
      max-width: 100%;
    }
        button.btn.btn-primary.sigup,
      a {
        color: #fff;
      }
    label {
      font-size: 16px;
      font-family: var(--common-font);
      font-weight: normal;
      color: #fff;
    }
    input {
      max-width: 100% !important;
      width: 100% !important;
      border: 0 none;
      border-bottom: 1px solid #e6e6e6;
      border-radius: 0px !important;
      padding: 0px 5px;
    }
    select {
      border-radius: 0px !important;
    }
    .sign-up-bnt {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      @media (max-width: 991px){
        width: 100%;
      }
      button {
        &:focus {
          outline: none;
        }
        width: 100%;
        max-width: 130px; 
        height: 40px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        transition: all 0.3s linear 0s;
        font-size: 16px;
        text-transform: uppercase;
        font-family: var(--common-font);
        margin: 0px !important;
        border-radius: 25px; 
        background: #fff !important;
        color: #000; 
        text-transform: uppercase;
        border: 2px solid #fff;
        line-height: 28px;
        &:hover {
          background: #06356a !important;
          color: #fff;
        } 
        @media (max-width: 767px){
         
        }
      }
    }
    h1 {
      text-align: center;
      text-transform: uppercase;
      margin: 0 0 25px;
      font-size: 30px;
      color: #fff;
      font-family: var(--common-font-bd);
      width: 100%;
      @media (max-width: 991px){
          font-size: 26px;
      }
    }
    input {
      &:focus {
        border: none;
      }
      border: 0;
      box-shadow: unset;
      /* border-bottom: 1px solid #e6e6e6; */
      border-radius: 9px !important;
      height: 51px;
      padding: 10px; 
    }
    .field-class {
      position: relative;
      width: 100%;
      svg {
        display: none;
      }
      span.error {
        color: #ff0000 ;
        padding: 0;
        margin-bottom: 20px;
        border-radius: 0;
        background-color: transparent;
        margin: 0;
        border: none;
        text-align: left;
      }
      @media (max-width: 767px) {
        .sign-up-bnt {
          width: 100%;
        }
      }
    }
        .signupandforget{
          button.btn.btn-primary.sigup {
        text-transform: capitalize;        
     }
    a {
        cursor:pointer;  
      }
    }
   
 
  }
  span.error:not(:empty) {
    background: #fff;
    display: block;
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid green;
    text-align: center;
}
  
`;



export const MagicLinkPopupStyle = styled.div`


  .MuiTypography-root {
    font-size: 23px;
    text-align: center;
    text-transform: uppercase;
    font-family: var(--common-font-bd);
    margin-top: 0;
    color: #06356a;
    width: 100%;
  }

  .MuiDialogContent-root {
    .MuiButton-root {
      white-space: nowrap;
      border-radius: 30px;
      height: 40px;
      font-size: 14px;
      font-family: "Graphik";
      max-width: 100%;
      background: rgb(0,53,106);
      border: 2px solid rgb(0,53,106);
      color: rgb(255,255,255);
      padding: 6px 12px;
      min-width: 110px;

      &:hover {
        background: #fff;
        color: #00356a;
      }
    }
  }
  .field-class {
    .form-label {
      font-size: 16px;
      font-family: var(--common-font);
      font-weight: normal;
      color: #000;
      margin-bottom: 5px;
      line-height: 1.45;
    }

    .form-control {
      height: 40px;
      box-shadow: none;
      outline: none;
      border: 1px solid #ddd;
      border-radius: 30px;
      font-family: var(--common-font);
      display: block;
      width: 100%;
    }
  }
`;
export default LoginStyle;
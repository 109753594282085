import React from 'react'
import { SecondSectionCss } from './Dashboard.style';

function SecondSection() {
    return (
        <SecondSectionCss>
            <div className="container">
                <div className="cstm-row">
                    <div className="left-img-sec">
                        <figure>
                            <img src="/images/journey-1.jpg" alt="Image" />
                        </figure>
                        <div className="text-content">
                            <p>
                                But that's not all! Kaire offers a unique rewards plan that allows you to
                                make extra income while improving your health. By becoming a Kaire
                                member, you can earn commissions on your sales and the sales of
                                others you refer to the program. It's a win-win situation - you'll be
                                helping others achieve their health goals while building your own
                                financial freedom.
                            </p>
                            <p>
                                So what are you waiting for? Start your journey to health and financial
                                freedom today with Kaire's Pycnogenol<span className='trade-mark'>®</span> supplements and rewards
                                plan. Your body and your wallet will thank you!
                            </p>
                        </div>
                    </div>
                    <div className="right-img-sec">
                        <div className="text-content">
                            <p>
                                Are you ready to embark on a journey towards health and financial
                                freedom? Look no further! Kaire's unnmatched Maritime Prime
                                Pycnogenol<span className='trade-mark'>®</span> supplements and flexible rewards are the perfect solution
                                to help you achieve both goals.
                            </p>
                            <p>
                                Unleash the power of Pycnogenol<span className='trade-mark'>®</span> with Maritime Prime, an
                                extraordinary antioxidant sourced from French maritime pine bark
                                extract, renowned for its impressive health benefits. Crafted with and
                                exclusive plant-based delivery system and the finest ingredients, our
                                supplements are backed by rigorous research to deliver unparalleled
                                results. Experience the transformation as Maritime Prime fortifies your
                                immune system, rejuvenates your skin, and enhances your
                                cardiovascular well-being, leaving you feeling your absolute best.
                            </p>
                        </div>
                        <figure>
                            <img src="/images/journey-2.jpg" alt="Image" />
                        </figure>
                    </div>
                </div>
            </div>

        </SecondSectionCss >
    )
}
export default SecondSection;

import React from "react";
import styled from "styled-components";

export const AppliedCouponCss = styled.div`
  .apply-coupn {
    text-align: center;
    margin-bottom: 50px;

    label {
      font-size: 17px;
      line-height: 1.5;
      margin-bottom: 6px;
      font-weight: 500;
      color: var(--cmn-blue);
    }
    .wrap-form {
      max-width: 300px;
      margin: 0 auto;

      .form-control {
        margin-bottom: 10px;
        text-align: center;

        &::placeholder {
          color: rgba(117,117,117,1);
          text-transform: uppercase;
        }
      }

      .btn {
        font-size: 16px;
        line-height: 1.9;
        width: 100%;
      }
    }
  }
  .coupam-enter {
    margin-bottom: 50px;
}
`;


import React from "react";
import { SmartshipBannerStyle } from "../../Signup/components/SmartshipStyle";

const SmartshipBanner = () => {
  return (
    <SmartshipBannerStyle style={{ backgroundImage: "/images/smartship.jpg" }}>
      <div className="container">
        <figure className="inner-logo">
          <a href="#">
            <img src="/images/logo.png" alt="Logo" />
          </a>
        </figure>
        <h3>SMARTSHIP</h3>
        <h5>AND MORE</h5>
        <ol>
          <li>
            <a href="javscript:void(0);">
              <span>1</span>. SIGNUP
            </a>
          </li>
          <li>
            <a href="javscript:void(0);">
              <span>2</span>. ENROLLMENT PACKS
            </a>
          </li>
          <li className="active">
            <a href="javscript:void(0);">
              <span>3</span>. SMARTSHIP
            </a>
          </li>
          <li>
            <a href="javscript:void(0);">
              <span>4</span>. REVIEW
            </a>
          </li>
        </ol>
      </div>
    </SmartshipBannerStyle>
  );
};

export default SmartshipBanner;

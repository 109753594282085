import { styled } from "styled-components";

export const ThankyStyle = styled.div`
  .thnku-sec {
    margin-top: 50px;

    .row {
      margin: 0 -15px;

      div[class^="col-"] {
        padding: 0 15px;
      }
    }

    .Cart_product {
      margin-bottom: 40px;
      background: #fff;
      padding: 10px 20px;
      border: 1px solid #ddd;

      @media only screen and (max-width: 767px) {
        padding: 10px;
      }

      h4 {
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
      }
      h3.tittle {
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 16px;
        text-align: center;
    }
      .table-wrap {
        & + .row {
          span.Discount,
          span.Total,
          span.amount_paid {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            text-align: right;
            padding-right: 16px;

            @media only screen and (max-width: 767px) {
              justify-content: center;
            }
          }

          span.Total {
            margin-bottom: 10px;
          }
        }
        table {
          border: 1px solid #eaeaea;
          width: 100%;

          thead {
            @media only screen and (max-width: 767px) {
              display: none;
            }
          }

          thead tr {
            background: linear-gradient(
              90deg,
              rgba(2, 55, 109, 1) 0%,
              rgba(94, 136, 236, 1) 44.7845%,
              rgba(209, 137, 168, 1) 100%
            );
          }

          tr {
            &.productDetailTable {
              &:nth-child(2n) {
                background: #f5f5f5;
              }
              td {
                padding: 20px;
                font-size: 15px;

                @media only screen and (max-width: 767px) {
                  display: block;
                  text-align: center;
                  padding: 10px;

                  &::before {
                    content: attr(data-title);
                    display: block;
                    font-size: 16px;
                    line-height: 1.5;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                    font-weight: 700;
                  }

                  &:not(:last-child) {
                    margin-bottom: 10px;
                  }
                }
              }
            }
          }

          th {
            color: rgb(255, 255, 255);
            text-align: left;
            padding: 20px;
            font-size: 15px;
            text-transform: capitalize;
          }

          td {
            padding: 20px;
            font-size: 15px;

            .cart-product-details {
              display: flex;
              align-items: center;

              @media only screen and (max-width: 767px) {
                justify-content: center;
              }
            }
            .cart-product-name {
              font-weight: 500;
            }
          }
        }
      }
    }

    .thn-lft,
    .thn-rgt {
      border: 1px solid #ddd;
      padding: 20px;
      background: #fff;
      margin-bottom: 30px;

      @media only screen and (max-width: 767px) {
        padding: 10px;
      }

      h4 {
        background: linear-gradient(
          90deg,
          rgba(2, 55, 109, 1) 0%,
          rgba(94, 136, 236, 1) 44.7845%,
          rgba(209, 137, 168, 1) 100%
        );
        padding: 10px 18px;
        color: #fff;
        font-size: 18px;
        margin: 0;

        @media only screen and (max-width: 767px) {
          font-size: 16px;
        }
      }

      .row {
        margin: 0;

        div[class^="col-"] {
          padding: 0;
          width: 100%;
          display: flex;
          /* align-items: center; */
          justify-content: space-between;
          h3,
          div {
            margin: 0;
            padding: 15px;
            width: 50%;
            border-bottom: 1px solid #ddd;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.2;

            @media only screen and (max-width: 767px) {
              padding: 10px;
              font-size: 14px;
            }
          }
          h3 {
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
          }
          div {
            border-right: 1px solid #ddd;
            text-transform: capitalize;
          }
        }
      }
    }

    .thnkyou-page {
      max-width: 640px;
      margin: 33px auto 80px;
      box-shadow: 0 0 10px 1px #ddd;
      padding: 15px;
      background: #fff;
      text-align: center;

      @media only screen and (max-width: 767px) {
        margin: 20px auto 40px;
        padding: 10px;
      }

      h3 {
        font-weight: 500;
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 16px;

        @media only screen and (max-width: 767px) {
          font-size: 15px;
          margin-top: 10px;
        }
      }
    }

    .mian-thank {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      svg {
        width: 90px;
        height: 90px;
        fill: #27a200;
        
        @media only screen and (max-width: 767px) {
            width: 70px;
            height: 70px;
        }
      }
      h2 {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 30px;
        font-weight: 500;

        @media only screen and (max-width: 767px) {
          margin-top: 10px;
          font-size: 24px;
        }
      }
    }

    h2 {
      text-align: center;
    }

    h4 {
      font-size: 26px;
      /* margin-bottom: 20px; */
      text-transform: uppercase;

      @media only screen and (max-width: 767px) {
        font-size: 20px;
        margin-bottom: 15px;
      }
    }

    .Cart_product {
      margin-bottom: 40px;

      .table-wrap + .row {
        margin-top: 10px;
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
`;

import { Step, StepLabel, Stepper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import api from "../../api/Apis";
import withRouter from "../../services/HOC/withRouterHOC";
import EnrollmentPack from "./components/EnrollmentPack";
import ReviewPack from "./components/ReviewPack";
// import EnrollmentPack from "../Enrollment/components/EnrollmentPack";
import SignupBanner from "./components/SignupBanner";

import { SignupBannerStyle, SignUpStyle } from "./components/SignupStyle";
import SignUpForm from "./components/SingupForm";
import SmartshipPack from "./components/SmartshipPack";
// import SignUpForm from "./components/SingupForm";
const steps = ['SignUp', 'Enrollment Pack', 'SmartShip', 'Review'];
// const steps = ['Enrollment Pack', 'SmartShip', 'Review'];

const SignUp = () => {
  const props = useOutletContext();
  const { setshowloader, setInnerLoader, setMemberPack, memberPack, setActiveStep, activeStep, counts, cartdata, setCartData, setshowminicart,
    setAddNotify, addNotify, setOpenRefer, openRefer, setPackProduct, packProduct, setDataRefer, dataReferer, getMiniCartProduct, ProfileFunction, isLoginToken, signupDefualtValue, setDefaultValue, autoshipOrderId, setAutoshipOrderId } = props;
  // const [signupDefualtValue, setDefaultValue] = React.useState({});
  const [stepsOption, setStepsOption] = React.useState(steps);
  const [memberShipProduct, setMemberShipProduct] = useState({});
  const [hideCustomPack, setHideCustomPack] = React.useState(true);
  const [minBV, setMinBv] = React.useState(150);
  const [userToken, setUserToken] = React.useState('');
  const [referralHide, setRefferalHide] = React.useState(false);
  const [checkCustomPack, setCheckCustomPack] = React.useState({ products: [], totalAmount: 0 });
  const [successMsg, setSuccessMeg] = useState()



  // useEffect(() => {
  //   const GetProfileData = localStorage.getItem("Token");
  //   if (GetProfileData) {
  //     setActiveStep(1)
  //   }
  // }, [])

  React.useEffect(() => {
    if (window.location.pathname === "member") {
      setStepsOption(steps);
    }
  }, [window.location])
  useEffect(() => {
    const data = localStorage.getItem('packProduct');
    if (data) {
      let values = JSON.parse(data);
      setCheckCustomPack(values?.customPack);
    }
    else {
      setCheckCustomPack({})
    }
    const profileData = localStorage.getItem('profileDetail');
    if (profileData) {
      let values = JSON.parse(profileData);
      setDefaultValue(values);
    }

    document.body.classList.add('bg-salmon');
  }, [activeStep]);

  async function _addToCart(formData) {
    // setInnerLoader(true);

    await api.addToCart(formData).then(res => {
      if (res?.data?.code === 0) {
        setInnerLoader(false);
      }
      else {
        setAddNotify([...addNotify, formData?.product_id])
        setSuccessMeg(res?.data?.message)
        getMiniCartProduct()
        setTimeout(() => {
          setSuccessMeg("")
        }, 2000);
      }
    })
  }
  function _currentStepData(value) {
    let DATA = "";
    let GET_CURRENT_STEP = stepsOption[value]
    switch (GET_CURRENT_STEP) {
      case "SignUp":
        DATA = <SignUpForm {...props}
          setUserToken={setUserToken}
          // setDataRefer={setDataRefer}
          // dataReferer={dataReferer}
          signupDefualtValue={signupDefualtValue}
          setActiveStep={setActiveStep}
          referralHide={referralHide}
          // setRefferalHide={setRefferalHide}
          setDefaultValue={setDefaultValue}
          userToken={userToken}
          ProfileFunction={ProfileFunction}
          isLoginToken={isLoginToken}
        />
        break;
      case "Enrollment Pack":
        DATA = <EnrollmentPack
          {...props}
          userToken={userToken}
          packProduct={packProduct}
          setPackProduct={setPackProduct}
          setUserToken={setUserToken}
          addToCart={(values) => _addToCart(values)}
          activeStep={activeStep}
          memberShipProduct={memberShipProduct}
          hideShowState={{ hideCustomPack, setHideCustomPack }}
          handleNext={handleNext}
          minBV={minBV}
          counts={counts}
          setMinBv={setMinBv}
          setMemberShipProduct={setMemberShipProduct}
          setActiveStep={setActiveStep}
          getMiniCartProduct={getMiniCartProduct}

        />
        break;
      case "SmartShip":
        DATA = <SmartshipPack
          {...props}
          addToCart={(values) => _addToCart(values)}
          // handleNext={handleNext}
          counts={counts}
          setActiveStep={setActiveStep}
          cartdata={cartdata}
          successMsg={successMsg}
          setSuccessMeg={setSuccessMeg}
        />
        break;
      case "Review":
        DATA = <ReviewPack
          {...props}
          addToCart={(values) => _addToCart(values)}
          checkCustomPack={checkCustomPack}
          setCheckCustomPack={setCheckCustomPack}
          setActiveStep={setActiveStep}
          handleBack={handleBack}
          stepsOption={stepsOption}
          signupDefualtValue={signupDefualtValue}
          setDefaultValue={setDefaultValue}
          setAutoshipOrderId={setAutoshipOrderId}
          autoshipOrderId={autoshipOrderId}
        // getMiniCartProduct={getMiniCartProduct}
        // getMiniCartProduct={getMiniCartProduct}
        />
        break;
      default:
        DATA = "default"
    }
    return DATA;
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      return prevActiveStep + 1
    });
  };

  const handleBack = (index) => {
    setActiveStep(index);
  };
  // useEffect(() => {
  //   if (window.location.pathname === "member") {
  //     if (checkCustomPack?.length > 0) {
  //       setStepsOption(steps);
  //     } else if (hideCustomPack) {
  //       const CHANGE_VALUE = _.reject(steps, (row) => row === 'Custom Pack');
  //       setStepsOption(CHANGE_VALUE);
  //     } else if (!hideCustomPack) {
  //       setStepsOption(steps);
  //     }
  //   }

  // }, [hideCustomPack, checkCustomPack, Router?.query])
  return (
    <>
      <SignUpStyle>

        <SignupBannerStyle style={{
          backgroundImage: activeStep === 0 ? 'url(/images/signup.jpg)' :
            activeStep === 1 ? 'url(/images/enrollment.jpg)' :
              activeStep === 2 ? 'url(/images/smartship.jpg)' :
                activeStep === 3 ? 'url(/images/review.jpg)' : 'none'
        }}>
          <div className="container">
            <figure className="inner-logo">
              <a href="/">
                <img src="/images/New-logo.png" alt="Logo" />
              </a>
              {/* <a href="#">
                <img src="/images/small-logo.png" alt="Logo" />
                ShareKaire
              </a> */}
            </figure>
            <h3>{activeStep === 0 ? "SIGNUP!" : activeStep === 1 ? "CHOOSE YOUR" : activeStep === 2 ? "SMARTSHIP" : activeStep === 3 ? "REVIEW" : ""}</h3>
            <h5>{activeStep === 0 ? "WHERE GOOD HEALTH PAYS OFF" : activeStep === 1 ? "ENROLLMENT PACK" : activeStep === 2 ? "AND MEMBERSHIP" : activeStep === 3 ? "AND START YOUR JOURNEY" : ""}</h5>
            <div className="cstm-stepper">
              <Stepper activeStep={activeStep} alternativeLabel>
                {stepsOption.map((label, index) => {
                  return (
                    <Step key={label} onClick={() => {
                      if (index !== 0) {
                        if ('first_name' in signupDefualtValue) {
                          if (window.location.pathname === "member") {
                            if (index === 3 && counts?.products?.length > 0) {
                              handleNext();
                            }
                            if (activeStep > index) {
                              handleBack(index)
                            }
                          }
                          else {
                            if (index === 2 && counts?.products?.length > 0) {
                              handleNext();
                            }
                            if (activeStep > index) {
                              handleBack(index)
                            }
                          }
                        }
                      }
                      else {
                        console.log(index)
                      }
                      if (index == 1) {
                        setHideCustomPack(true)
                      }
                    }}>
                      <StepLabel><span>{index + 1}</span>. {label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </div>
          </div>
        </SignupBannerStyle >

        <React.Fragment>
          {_currentStepData(activeStep)}
        </React.Fragment>
      </SignUpStyle>
    </>
  );
};

export default SignUp;

import React from 'react'
import { FreeBonusCss } from './RewardStyle'

const FreeBonus = (props) => {
  const { Validatelogin, userRank } = props
  return (
    <FreeBonusCss>
      <div className="container">
        <div className="cstm-row">
          <div className="col-6">
            <div className="free-bonus-content">
              <h2>
                <span>SHAREKAIRE BONUS (Two For Free)</span>
              </h2>
              <ul>
                <li>
                  Offers Kaire Cash toward free products to Members and Customers.
                </li>
                <li>
                  Qualification requires SmartShip participation with only two Customers.
                </li>
                <li>
                  Eligible individuals receive Kaire Cash based on the average of their two highest personal Customer’s SmartShip orders
                </li>
                <li>
                  Kaire Cash is automatically applied to SmartShip orders and can be used for approved products up to a value of $500!
                </li>
                <li>
                  Smartship products delivered every 30 days at a 10% discount.
                </li>
              </ul>
              <div className="btn-wrap">
                <a type="button" className="btn"
                  // href="https://commandconcept.csdevhub.com/media/pdf_files/Rewards_Plan_040324.pdf" 
                  href="https://admin.shopkaire.com/media/pdf/Kaire_RewardsPlan_102924.pdf"
                  target="_blank">The full rewards plan</a>
                {userRank?.user_data?.userdetails?.[0]?.qualified_as_rank !== 3 && <button type="button" className="btn btn-2 " onClick={() => Validatelogin()}>Signup</button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </FreeBonusCss>
  )
}

export default FreeBonus

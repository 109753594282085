import _ from "lodash";
import React, { useEffect, useState } from "react";
import api from "../../../api/Apis";
import apiPaths from "../../../services/apPaths";
import { EnrollmentPackStyle } from "./EnrollmentStyle";
import parse from "html-react-parser";


const EnrollmentPack = (props) => {
  const { setshowloder, userToken, getMiniCartProduct, setUserToken, memberShipProduct, setMemberShipProduct, setshowminicart, activeStep, hideShowState
  } = props;
  const [packProduct, setPackProduct] = useState([]);
  const [activeClass, setActiveClass] = useState();
  useEffect(() => {

    _promoterPackApi();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);
  async function _promoterPackApi() {
    // setshowloader(true);

    await api.promoterPackApi().then((res) => {
      const { code, promoter_packs } = res?.data;
      const allvalues = _.map(promoter_packs, (row) => ({
        id: row?.id,
        hidden: true,
        is_autoship: false
      }));
      if (res?.status === 200 && code === 1) {
        const data = _.find(promoter_packs, { name: "Membership" });
        // setMemberShipProduct(data);
        setPackProduct(_.reject(promoter_packs, { name: "Membership" }));
        // setshowloader(false);
        setActiveClass(allvalues);
        // pack(allvalues);
      }
    });


  }
  const addToCart = async (PromoterPack) => {
    // const formData = {
    //   product_id: id, variant_id: productvariantid,
    //   token: Logintoken, quantity: quantity, is_autoship: _.find(costtype, { id: id })?.value === 'AutoShip' ? true : false
    // };
    await api.addToCart(PromoterPack).then(res => {
      if (res?.data?.code === 1) {
        //   setActiveStep((prvStep) => {
        //     return prvStep + 1;
        // });
        setCartNotify("Added To Cart")

        setTimeout(() => {
          setCartNotify('');
        }, 3000)
        // toast.success(res.data.message, { duration: 5 })
        props?.setshowminicart(true)
      }
    })
  }

  function _handleSelect(id, is_autoship) {
    // getMiniCartProduct()


    const filterId = _.find(activeClass, { id: id });



    if (filterId) {

      filterId["hidden"] = !filterId["hidden"];
      const REMAIN_DATA = _.reject(activeClass, filterId);
      if (!filterId["hidden"]) {
        // _deletePromoterCustomPack({ product_id: selectedPack?.id, is_autoship: false }, "promoterPack_delete")
        // setSelectedPack(filterId);
        const PromoterPack = {
          product_id: id,
          variant_id: null,
          token: userToken,
          quantity: 1,
          is_autoship: false,
          promoterDetail: packProduct?.find((row) => row?.id === id)
        };
        localStorage.setItem("PromoterPack", JSON.stringify(PromoterPack));
        addToCart(PromoterPack);
        handleNext();
        localStorage.removeItem('packProduct')
        const dataSss = localStorage.getItem("packProduct") ? JSON.parse(localStorage.getItem("packProduct")) : null;

        let couuntValue = dataSss !== null ? PromoterPack + 1 : +PromoterPack;
        setcounts(couuntValue);
        // setcounts(PromoterPack)
        setshowminicart(true);
      } else {
        const formdata = { product_id: id, is_autoship: false };
        _deletePromoterCustomPack(formdata, "promoterPack_remove")
        setSelectedPack({});
      }
      setActiveClass([...REMAIN_DATA, filterId]);
    }
  }

  return (
    <EnrollmentPackStyle>
      <div className="container">
        <div className="cmn-packs-wrap">
          <div className="cmn-packs-heading">
            <h3>
              <small>MEMBER PACKS</small>
            </h3>
          </div>
          <div className="cmn-packs-outer">
            {_.map(packProduct, (row, index) => (
              <>

                <div className="cmn-packs">
                  {row?.product_images?.length > 0 && (
                    <figure>
                      <img src={apiPaths.DOC_URL + row?.product_images[0]?.image} alt="Image" />
                    </figure>
                  )}
                  <div className="cmn-pack-content">
                    <h5>{row?.name}</h5>
                    <p>{row?.description ? parse(row?.description) : ""}</p>
                    <h6> ${parseFloat(row?.autoship_cost_price).toFixed(2)}</h6>
                    <button type="button"

                      className="btn btn-6"
                      onClick={() => _handleSelect(row?.id, row?.is_autoship)}
                    >
                      SELECT PACK
                    </button>
                  </div>
                </div>
                {/* <div className="cmn-packs">
                  <figure>
                    <img src="/images/Pack-2.png" alt="Image" />
                  </figure>
                  <div className="cmn-pack-content">
                    <h5>MARITIME PRIME 180 PACK</h5>
                    <p>2 Prime 180, 1 Silverkaire, 1 BioticKaire+, Membership</p>
                    <h6>$215.00</h6>
                    <button type="button" className="btn btn-6">
                      SELECT PACK
                    </button>
                  </div>
                </div>
               </div><div className="cmn-packs-heading">
                <h3>
                  <small>PROMO PACKS</small>
                </h3>
               </div><div className="cmn-packs-outer">
                <div className="cmn-packs active-pack">
                  <figure>
                    <img src="/images/Pack-3.png" alt="Image" />
                  </figure>
                  <div className="cmn-pack-content">
                    <h5>SUPER 120 PROMO PACK</h5>
                    <p>3 Super 120, 2 Silverkaire, 2 BioticKaire+, Membership</p>
                    <h6>$500.00</h6>
                    <button type="button" className="btn btn-6">
                      SELECT PACK
                    </button>
                  </div>
                </div>
                <div className="cmn-packs">
                  <figure>
                    <img src="/images/Pack-4.png" alt="Image" />
                  </figure>
                  <div className="cmn-pack-content">
                    <h5>MARITIME 180 PROMO PACK</h5>
                    <p>4 Prime 180, 3 Silverkaire, 3 BioticKaire+, Membership</p>
                    <h6>$505.00</h6>
                    <button type="button" className="btn btn-6">
                      SELECT PACK
                    </button>
                  </div>
                </div> */}
              </>
            ))}
          </div>
          <div className="continue-btn">
            <a href="/smartship" className="text-btn">No thanks, continue</a>
          </div>

        </div>
      </div>
    </EnrollmentPackStyle>
  );
};

export default EnrollmentPack;


import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ViewCartPopup from '../../Components/Common/ViewCartPopup';
import withRouter from '../../services/HOC/withRouterHOC';
import { FirstSection, SecondSection, ThirdSection, FourthSection } from './components';
import { CommonDashboardStyle } from './components/Dashboard.style';

const HomePage = (props) => {
    const { subDomain, customerCheck } = props;
    const [open, setOpen] = useState(false);
    const [getProfileData, setGetProfileData] = useState();
    const navigate = useNavigate()
    useEffect(() => {
        const GetProfileData = localStorage.getItem("Token");
        setGetProfileData(GetProfileData);
        // const GetRef = localStorage.getItem('referral_code');
    }, [getProfileData])
    const popRef = React.useRef(null);
    const Validatelogin = (e) => {
        // if (getProfileData) {
        //     navigate('/signup');
        // }
        const DATAREF = localStorage.getItem("referral_code") ? localStorage.getItem("referral_code") : ""

        if (DATAREF) {
            navigate('/signup');
        }
        else if (localStorage.getItem("Token")) {
            navigate('/signup');
        }
        else {
            popRef.current.setRefferalPopup(true);
            //   popRef.current.setGoToPromoterPack(true);
            popRef.current.setCorrectRefferal({ status: false, data: {} });
            setOpen(true);
        }
    };
    return (
        <>
            <div className="dashbord_main_class">
                <CommonDashboardStyle />
                <FirstSection Validatelogin={Validatelogin} />
                <SecondSection />
                <ThirdSection Validatelogin={Validatelogin} customerCheck={customerCheck} />
                <FourthSection />
            </div>
            <ViewCartPopup
                ref={popRef}
                content={{
                    open: open,
                    setOpen: setOpen,
                    ...props,
                }} />
        </>
    )
}

export default withRouter(HomePage);

import React from "react";
import styled from "styled-components";

export const EnrollmentStyle = styled.div`
  background-color: var(--signup-bg);
`;

export const EnrollmentBannerStyle = styled.section`
  background-image: url("images/enrollment.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0 375px;

  .inner-logo {
    max-width: 280px;
    display: block;
    margin: 0 0 70px;

    a {
      display: block;
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  h3 {
    margin-bottom: 4px;
    color: var(--cmn-white);
  }

  h5 {
    color: var(--cmn-white);
  }

  ol {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    li {
      &.active {
        a {
          color: var(--cmn-white);
        }
      }
      a {
        display: block;
        color: var(--grey-text);
        font-weight: 300;
        font-size: 19px;
        line-height: 1.2;

        span {
          font-weight: 600;
        }
      }
    }
  }
`;

export const EnrollmentPackStyle = styled.section`
  margin-top: -340px;
  padding-bottom: 155px;

  .cmn-packs-wrap {
    padding: 40px 85px;
    background-color: var(--cmn-white);
    border: 1px solid var(--form-border);

    .cmn-packs-heading {
      text-align: center;
      margin-bottom: 20px;

      h3 {
        margin: 0;
      }
    }

    .cmn-packs-outer {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      margin-bottom: 20px;
      color: var(--cmn-black);

      .cmn-packs {
        width: calc(100% / 2 - 12px);
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #000;
        border-radius: 20px;
        padding: 24px;

        &.active-pack {
          background-color: var(--active-pack);

          .cmn-pack-content {
            .btn {
              background-color: var(--cmn-blue);
              color: var(--cmn-white);
            }
          }
        }

        figure {
          width: 222px;
          height: 200px;
          margin: 0 10px 0 0;
        }

        .cmn-pack-content {
          width: calc(100% - 232px);
          text-align: center;

          p {
            font-size: 14px;
            margin-bottom: 11px;
          }

          h5 {
            margin-bottom: 5px;
            line-height: 1.1;
          }

          h6 {
            margin-bottom: 14px;
          }

          .btn {
            font-size: 15px;
            line-height: 1.4;
            min-width: unset;
            border-width: 1px;
            padding: 5px 29px;
            min-width: 180px;
          }
        }
      }
    }
  }

  .continue-btn {
    text-align: center;
    padding-top: 60px;
    margin-bottom: 10px;
  }
`;

import React from 'react'
import { FirstSectionCss } from './Dashboard.style';

function FirstSection() {
    return (
        <FirstSectionCss>
            <div className="container">
                <div className="cstm-row">
                    <div className="left-sec">
                        <h1>SHARE YOUR  KAIRE LIFESTYLE, & EARN REWARDS!</h1>
                        <p>
                            Empowering others is great, but earning while doing it
                            is exceptional. Join as a Member and earn commissions
                            when your referrals buy Kaire products!
                        </p>
                    </div>
                    <div className="right-sec">
                        <figure>
                            <img src="/images/ProductImg.png" alt="Image" />
                        </figure>
                    </div>
                </div>
            </div>
        </FirstSectionCss >
    )
}
export default FirstSection;

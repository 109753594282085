import React, { useEffect, useState } from 'react'
import { ThirdSectionCss } from './Dashboard.style';
function ThirdSection(props) {
    const { Validatelogin, customerCheck } = props;

    return (
        <>
            <ThirdSectionCss>
                <div className="container">
                    <div className="cstm-row">
                        {/* <div className="col-12">
                        <h2>GETTING STARTED IS EASY</h2>
                    </div> */}
                        <div className="col-4">
                            <div className="easy-wrap bg-1">
                                <h4>
                                    <span>1</span>
                                    ENROLL AS A MEMBER
                                </h4>
                                <figure>
                                    <img src='/images/easy-1.png' alt="Image" />
                                </figure>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="easy-wrap bg-2">
                                <h4>
                                    <span>2</span>
                                    CHOOSE YOUR PACK
                                </h4>
                                <figure>
                                    <img src='/images/easy-2.png' alt="Image" />
                                </figure>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="easy-wrap bg-3">
                                <h4>
                                    <span>3</span>
                                    SMARTSHIPS & MORE
                                </h4>
                                <figure>
                                    <img src='/images/easy-3.png' alt="Image" />
                                </figure>
                            </div>
                        </div>
                        {customerCheck !== 3 &&
                            <div className="col-12">
                                <div className="btn-wrap">
                                    <button type="button" className='btn' onClick={() => Validatelogin()}>JOIN NOW</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>

            </ThirdSectionCss >


        </>
    )
}
export default ThirdSection;

import React from 'react';
import { FourthSectionCss } from './Dashboard.style';
import { useNavigate } from 'react-router-dom';


function FourthSection() {
    const navigate=useNavigate()
    return (

        <FourthSectionCss className="main_class_of_section_fourth">
            <div className="container">
                <div className="cstm-row">
                    <div className="col-12">
                        <div className="reward-plan">
                            <h2>THE BEST REWARD PLAN!</h2>
                            <p>Kaire provides the opportunity for you to set and achieve your own goals. Set them as large or small as you like! </p>
                            <p>Kaire has committed and will continue to offer, the highest quality products to ensure that our proprietary formulas
                                positively impact the health and lives for those who use them.</p>
                            <h6>All this with a 100% satisfaction guarantee for 90 days!</h6>
                            <figure>
                                <img src='/images/reward-plan.png' />
                            </figure>
                            <div>
                                <button type='button' className='btn btn-2'onClick={()=>navigate("/reward")}>SHOW ME THE REWARDS!</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </FourthSectionCss>


    )
}

export default FourthSection

import React from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

function DetailSection({
  detail,
  setBillingAddress,
  _onChange,
  _onhandleChange,
  isShippingSame,
  setIsShippingSame,
  dataName,
  setDataName,
  countryValue,
  setCountryValue,
  stateValue,
  setStateValue,
  countryError,
  stateError,
  INITIALVALUES,
  _onBlur,
  formik,
}) {
  const whitelistedCountries = ['CA', 'US', 'AU', 'NZ'];
  return (
    <div className="detailDisplay_section">
      <div className="detailInner_section">
        <h3>MEMBERSHIP DETAILS</h3>
        <table>
          <tbody>
            <tr>
              <td>Email</td>
              <td>{detail?.email}</td>
            </tr>
            <tr>
              <td>First Name</td>
              <td>{detail?.first_name}</td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>{detail?.last_name}</td>
            </tr>

            <tr>
              <td>Primary Address</td>
              <td>{detail?.street_address_1 ? detail?.street_address_1 : detail?.address_line1}</td>
            </tr>
            {detail?.address_line2 && (
              <tr>
                <>
                  <td> Address 2</td>
                  <td>{detail?.address_line2}</td>
                </>
              </tr>
            )}
            <tr>
              <td>City</td>
              <td>{detail?.city}</td>
            </tr>
            <tr>
              <td>State</td>
              <td>{detail?.state}</td>
            </tr>
            <tr>
              <td>Zip</td>
              <td>{detail?.postal_code ? detail?.postal_code : detail?.zipcode}</td>
            </tr>
            <tr>
              <td>Country</td>
              <td>{detail?.country}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="biillingForm_Section">
        <h3>Billing Address</h3>
        <div className="check_data">
          <input
            type="checkbox"
            onClick={() => _onhandleChange(!isShippingSame)}
          />{" "}
          Same as shipping address
        </div>
        <div className="biillingForm_wrap">
          <div className="field">
            <div className="text_field">
              <input
                type="text"
                name="first_name"
                placeholder="First Name"
                onChange={_onChange}
                value={formik?.values?.first_name}
                onBlur={_onBlur}
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <div className="error">{formik.errors.first_name}</div>
              ) : null}
            </div>
          </div>
          <div className="field">
            <div className="text_field">
              <input
                type="text"
                name="last_name"
                placeholder="Last Name"
                onChange={_onChange}
                value={formik?.values?.last_name}
              />

              {formik.touched.last_name && formik.errors.last_name ? (
                <div className="error">{formik.errors.last_name}</div>
              ) : null}
            </div>
          </div>
          <div className="field">
            <div className="text_field">
              <input
                type="text"
                name="address_line1"
                placeholder="Address"
                value={formik?.values?.address_line1 ? formik?.values?.address_line1 : isShippingSame ? detail?.address_line1 : ""}
                onChange={_onChange}
              />
              {formik.touched.address_line1 && formik.errors.address_line1 ? (
                <div className="error">{formik.errors.address_line1}</div>
              ) : null}
            </div>
          </div>
          <div className="field">
            <div className="text_field">
              <input
                type="text"
                name="address_line2"
                placeholder="Address 2"
                value={formik?.values?.address_line2 ? formik?.values?.address_line2 : isShippingSame ? detail?.address_line2 : ""}
                onChange={_onChange}
              />
            </div>
          </div>
          <div className="field">
            <div className="text_field">
              <CountryDropdown
                className="form-control"
                value={countryValue}
                name="country"
                priorityOptions={["US", "CA"]}
                whitelist={whitelistedCountries}
                defaultOptionLabel="Select Country"
                valueType="short"
                onChange={(val) => setCountryValue(val)}
              />
              {countryValue === undefined ||
                (countryValue === "" && (
                  <span className="error">{countryError}</span>
                ))}
            </div>
          </div>
          <div className="field">
            <div className="text_field">
              <RegionDropdown
                className="form-control"
                country={countryValue || "US"}
                value={stateValue}
                name="state"
                valueType="full"
                defaultOptionLabel="Select State"
                disabled={!countryValue}
                countryValueType="short"
                onChange={(val) => setStateValue(val)}
              />
              {stateValue === undefined ||
                (stateValue === "" && (
                  <span className="error">{stateError}</span>
                ))}
            </div>
          </div>
          <div className="field">
            <div className="text_field">
              <input
                type="text"
                name="city"
                placeholder="Town/City"
                value={formik?.values?.city}
                onChange={_onChange}
              />
              {formik.touched.city && formik.errors.city ? (
                <div className="error">{formik.errors.city}</div>
              ) : null}
            </div>
          </div>
          <div className="field">
            <div className="text_field">
              <input
                type="text"
                name="zipcode"
                placeholder="Zip Code"
                value={formik?.values?.zipcode ? formik?.values?.zipcode : isShippingSame ? detail?.zipcode : ""}
                onChange={_onChange}
              />
              {formik.touched.zipcode && formik.errors.zipcode ? (
                <div className="error">{formik.errors.zipcode}</div>
              ) : null}
            </div>
          </div>
          <div className="field">
            <div className="text_field">
              <input
                type="text"
                name="phone_no"
                placeholder="Phone "
                value={formik?.values?.phone_no ? formik?.values?.phone_no : isShippingSame ? detail?.phone_no || detail.phone_number : ""}
                onChange={_onChange}
              />
              {formik.touched.phone_no && formik.errors.phone_no ? (
                <div className="error">{formik.errors.phone_no}</div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DetailSection;

import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useLocation } from 'react-router-dom';
import api from '../../../api/Apis';
import NumberFormatComp from '../../../Components/Common/NumberFormatComp';
import apiPaths from '../../../services/apPaths';
import withRouter from '../../../services/HOC/withRouterHOC';
import { ThankyStyle } from './ThankyStyle';

const ThankYou = (props) => {
    let location = useLocation();
    const pathParts = location.pathname.split('/');
    // const orderId = pathParts[pathParts.length - 1].split('=')[1];
    const orderId = pathParts[pathParts.length - 1].split('=')[1].split('&')[0];
    const [data, setdata] = useState();
    const [autoshipOrderData, setautoshipOrderData] = useState();
    const [orderid, setorderid] = useState();
    const [shippingAmount, setShippingAmount] = useState(null);
    const [autoShipShippingAmount, setAutoShipShippingAmount] = useState(null);
    const [totalCash, setTotalCash] = useState({ name: "", value: null });
    const [autoshipCheck, setAutoShipCheck] = useState('')

    const pathParts1 = location.pathname.split('&');
    const autoshipOrderId = pathParts1[pathParts1.length - 1].split('=')[1];

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        props?.setCartData()

        const token = localStorage.getItem('Token') ? localStorage.getItem('Token') : '';
        const update_data = {
            token: token,
            order_id: orderId,
            autoship_order_id: autoshipOrderId
        }
        if (orderId) {
            api.GetOrderDetail(update_data).then(res => {
                if (res?.data?.orders?.wallet_amount_used) {
                    localStorage.removeItem('packProduct')
                    setTotalCash({ name: "Wallet Cash Used", value: res?.data?.orders?.wallet_amount_used })
                }
                else if (res?.data?.orders?.kaire_amount_used) {
                    setTotalCash({ name: "Kaire Cash Used", value: res?.data?.orders?.kaire_amount_used })
                }
                setdata(res?.data?.orders);
                setautoshipOrderData(res?.data?.autoship_order)
                let Data = [];
                res?.data?.orders?.order_details?.map((data) => {
                    let formData = { id: data?.product?.id, isautoship: data?.product?.is_active }
                    Data.push(formData)
                })
                setAutoShipCheck(Data)
                setorderid(res?.data?.orders?.public_order_id);
                setShippingAmount(res?.data?.orders?.shipping_amount)
                setAutoShipShippingAmount(res?.data?.autoship_order?.shipping_amount)
            })

        }

    }, [orderId, autoshipOrderId]);
    return (
        <>
            <ThankyStyle>
                <title>ThankYou</title>
                <div className="thnku-sec">
                    <div className="container">
                        <div className='mian-thank'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                        </svg>

                            <h2>Thank You </h2></div>
                        <div className="thnkyou-page">
                            <h3>Your order has been placed successfully</h3>
                            <h3>Your order ID {orderid && orderid}</h3>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="thn-lft">
                                    <h4>Billing Address
                                    </h4>
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <h3> First Name</h3>
                                            <div> {data?.billing_address?.first_name}</div>
                                        </div>
                                        <div className='col-md-12'>
                                            <h3> Last Name</h3>
                                            <div>
                                                {data?.billing_address?.last_name}
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <h3> Address 1</h3>
                                            <div>
                                                {data?.billing_address?.street_address_1}
                                            </div>
                                        </div>
                                        {data?.billing_address?.street_address_2 &&
                                            <div className='col-md-12'>
                                                <h3>Address 2</h3>
                                                <div>
                                                    {data?.billing_address?.street_address_2}
                                                </div>
                                            </div>}
                                        {data?.billing_address?.company_name &&
                                            <div className='col-md-12'>
                                                <h3>Company Name</h3>
                                                <div>
                                                    {data?.billing_address?.company_name}
                                                </div>
                                            </div>}
                                        <div className='col-md-12'>
                                            <h3> City</h3>
                                            <div>{data?.billing_address?.city}</div>
                                        </div>
                                        <div className='col-md-12'>
                                            <h3> State</h3>
                                            <div>{data?.billing_address?.state}</div>
                                        </div>
                                        <div className='col-md-12'>
                                            <h3> Zip Code</h3>
                                            <div>{data?.billing_address?.postal_code} </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <h3> Country</h3>
                                            <div>{data?.billing_address?.country} </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <h3> Phone</h3>
                                            <div>{data?.billing_address?.phone_number} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="thn-rgt thn-lft">
                                    <h4>Shipping Address</h4>
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <h3> First Name</h3>
                                            <div> {data?.shipping_address?.first_name}</div>
                                        </div>
                                        <div className='col-md-12'>
                                            <h3> Last Name</h3>
                                            <div>
                                                {data?.shipping_address?.last_name}
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <h3> Address 1</h3>
                                            <div>
                                                {data?.shipping_address?.street_address_1}
                                            </div>
                                        </div>
                                        {data?.shipping_address?.street_address_2 &&
                                            <div className='col-md-12'>
                                                <h3>Address 2</h3>
                                                <div>
                                                    {data?.shipping_address?.street_address_2}
                                                </div>
                                            </div>}
                                        {data?.shipping_address?.company_name &&
                                            <div className='col-md-12'>
                                                <h3>Company Name</h3>
                                                <div>
                                                    {data?.shipping_address?.company_name}
                                                </div>
                                            </div>}
                                        <div className='col-md-12'>
                                            <h3> City</h3>
                                            <div>{data?.shipping_address?.city}</div>
                                        </div>
                                        <div className='col-md-12'>
                                            <h3> State</h3>
                                            <div>{data?.shipping_address?.state}</div>
                                        </div>
                                        <div className='col-md-12'>
                                            <h3> Zip Code</h3>
                                            <div>{data?.shipping_address?.postal_code} </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <h3> Country</h3>
                                            <div>{data?.shipping_address?.country} </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <h3> Phone</h3>
                                            <div>{data?.shipping_address?.phone_number} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Cart_product">
                            <h4 className="tittle">Order details</h4>

                            <>
                                <div className='table-wrap'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>product Image</th>
                                                <th>product name</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.order_details?.map((datas, index) => {
                                                return (
                                                    <tr className="productDetailTable" key={index + 1}>
                                                        <td data-value="Product Image" data-title="product Image">
                                                            <div className="cart-product-details">
                                                                <img src={`${apiPaths.DOC_URL}${datas.product?.product_images[0]?.image}`} height='100' widt='100' />
                                                            </div>
                                                        </td>
                                                        <td data-value="Product name" data-title="product name">
                                                            <div className="cart-product-name">
                                                                {datas.product.name}
                                                            </div>
                                                        </td>
                                                        <td data-value="Quantity" data-title="Quantity">
                                                            <div className="cart-product-qty">
                                                                {datas.product_quantity}
                                                            </div>
                                                        </td>
                                                        <td data-value="Price" data-title="Price"><div className="cart-product-price">
                                                            <NumberFormat value={parseFloat(datas.price_per_unit).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} />

                                                        </div></td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </>

                            <div className="row">
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-4">
                                    <span className="Total"><strong>Subtotal : </strong>&nbsp;
                                        <NumberFormatComp value={data?.amount} />
                                    </span>
                                    {data?.discount_amount > 0 &&
                                        <span className="Total"><strong>Discount : </strong>&nbsp;
                                            -<NumberFormatComp value={data?.discount_amount} />
                                        </span>
                                    }
                                    {shippingAmount &&
                                        <span className="Total"><strong>Shipping Amount : </strong>&nbsp;
                                            <NumberFormatComp value={+shippingAmount} />
                                        </span>
                                    }
                                    {totalCash?.name &&
                                        <span className="Total"><strong>{totalCash?.name} : </strong>&nbsp;
                                            <NumberFormatComp value={+totalCash?.value} />
                                        </span>
                                    }
                                    {(() => {
                                        let totalAmount = parseFloat(+data?.amount) + parseFloat((shippingAmount !== null) ? +shippingAmount : 0) - parseFloat(+data?.discount_amount);
                                        totalAmount = (totalAmount - totalCash?.value)
                                        return (
                                            <span className="amount_paid"><strong>Total : </strong>&nbsp;
                                                <NumberFormatComp value={totalAmount} />
                                            </span>
                                        )
                                    })()}

                                </div>
                            </div>
                        </div>



                        {autoshipOrderId &&
                            <div className="Cart_product">
                                <h4 className="tittle">Autoship Order details</h4>
                                <h3 className="tittle">Your order ID {autoshipOrderData?.public_order_id}</h3>
                                <>
                                    <div className='table-wrap'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>product Image</th>
                                                    <th>product name</th>
                                                    <th>Quantity</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {autoshipOrderData?.autoship_order_details?.map((datas, index) => {
                                                    return (
                                                        <tr className="productDetailTable" key={index + 1}>
                                                            <td data-value="Product Image" data-title="product Image">
                                                                <div className="cart-product-details">
                                                                    <img src={`${apiPaths.DOC_URL}${datas.product?.product_images[0]?.image}`} height='100' widt='100' />
                                                                </div>
                                                            </td>
                                                            <td data-value="Product name" data-title="product name">
                                                                <div className="cart-product-name">
                                                                    {datas.product.name}
                                                                </div>
                                                            </td>
                                                            <td data-value="Quantity" data-title="Quantity">
                                                                <div className="cart-product-qty">
                                                                    {datas.product_quantity}
                                                                </div>
                                                            </td>
                                                            <td data-value="Price" data-title="Price"><div className="cart-product-price">
                                                                <NumberFormat value={parseFloat(datas.price_per_unit).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} />

                                                            </div></td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </>

                                <div className="row">
                                    <div className="col-md-8">
                                    </div>
                                    <div className="col-md-4">
                                        <span className="Total"><strong>Subtotal : </strong>&nbsp;
                                            <NumberFormatComp value={autoshipOrderData?.amount} />
                                        </span>
                                        {autoshipOrderData?.discount_amount > 0 &&
                                            <span className="Total"><strong>Discount : </strong>&nbsp;
                                                -<NumberFormatComp value={autoshipOrderData?.discount_amount} />
                                            </span>
                                        }
                                        {shippingAmount &&
                                            <span className="Total"><strong>Shipping Amount : </strong>&nbsp;
                                                <NumberFormatComp value={+autoShipShippingAmount} />
                                            </span>
                                        }
                                        {totalCash?.name &&
                                            <span className="Total"><strong>{totalCash?.name} : </strong>&nbsp;
                                                <NumberFormatComp value={+totalCash?.value} />
                                            </span>
                                        }
                                        {(() => {
                                            let totalAmount = parseFloat(+autoshipOrderData?.amount) + parseFloat((autoShipShippingAmount !== null) ? +autoShipShippingAmount : 0) - parseFloat(+autoshipOrderData?.discount_amount);
                                            totalAmount = (totalAmount - totalCash?.value)
                                            return (
                                                <span className="Total"><strong>Total : </strong>&nbsp;
                                                    <NumberFormatComp value={totalAmount} />
                                                </span>
                                            )
                                        })()}
                                        <span className="Total"><strong>Next Shipping Date : </strong>&nbsp;
                                            <div>{autoshipOrderData?.next_shipping_date}</div>
                                        </span>
                                    </div>
                                </div>
                            </div>


                        }

                    </div>
                </div>
            </ThankyStyle>

        </>)
}
export default withRouter(ThankYou) 
import React from "react";
import { SmartshipStyle } from "../Signup/components/SmartshipStyle";
import SmartshipBanner from "./components/SmartshipBanner";
import SmartshipPack from "../Signup/components/SmartshipPack";

const Smartship = () => {
  return (
    <>
      <SmartshipStyle>
        <SmartshipBanner />
        <SmartshipPack />
      </SmartshipStyle>
    </>
  );
};

export default Smartship;

import React from "react";
import styled from "styled-components";

export const EnrollmentStyle = styled.div`
  background-color: var(--signup-bg);
`;

export const EnrollmentBannerStyle = styled.section`
  background-image: url("images/enrollment.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0 375px;

  .inner-logo {
    max-width: 280px;
    display: block;
    margin: 0 0 70px;

    a {
      display: block;
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  h3 {
    margin-bottom: 4px;
    color: var(--cmn-white);
  }

  h5 {
    color: var(--cmn-white);
  }

  ol {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    li {
      &.active {
        a {
          color: var(--cmn-white);
        }
      }
      a {
        display: block;
        color: var(--grey-text);
        font-weight: 300;
        font-size: 19px;
        line-height: 1.2;

        span {
          font-weight: 600;
        }
      }
    }
  }
`;

export const EnrollmentPackStyle = styled.section`
  margin-top: -340px;
  padding-bottom: 155px;
  position: relative;
  z-index: 2;

  @media only screen and (max-width: 1399px) {
    margin-top: -275px;
    padding-bottom: 100px;
  }

  @media only screen and (max-width: 767px) {
    margin-top: -200px;
    padding-bottom: 70px;
  }

  .cmn-packs-wrap {
    padding: 40px 85px;
    background-color: var(--cmn-white);
    border: 1px solid var(--form-border);

    @media only screen and (max-width: 1199px) {
      padding: 50px;
    }

    @media only screen and (max-width: 991px) {
      padding: 40px;
    }

    @media only screen and (max-width: 767px) {
      padding: 30px;
    }

    @media only screen and (max-width: 575px) {
      padding: 20px 15px;
    }

    .cmn-packs-heading {
      text-align: center;
      margin-bottom: 20px;

      h3 {
        margin: 0;
      }
    }

    .cmn-packs-outer {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      margin-bottom: 20px;
      color: var(--cmn-black);

      .cmn-packs {
        width: calc(100% / 2 - 12px);
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #000;
        border-radius: 20px;
        padding: 24px;
        position: relative;
        
        @media only screen and (max-width:1199px) {
          padding-top: 100px;          
        }
        
        @media only screen and (max-width: 991px) {
          width: 100%;
          padding: 20px 15px;
          padding-top: 90px;          
        }
        
        &.active-pack {
          background-color: var(--active-pack);

          .cmn-pack-content {
            .btn {
              background-color: var(--cmn-blue);
              color: var(--cmn-white);
            }
          }
        }
        
        
        .save_tag {
          /* background: linear-gradient(77deg, rgba(88,112,216,1) 0%, rgba(190,114,135,1) 32%, rgba(175,118,19,1) 92%); */
          background: linear-gradient(17deg, rgba(88, 112, 216, 1) 14%, rgba(190, 114, 135, 1) 50%, rgba(175, 118, 19, 1) 92%);
          display: block;
          max-width: 152px;
          width: 100%;
          padding: 20px 5px;
          text-align: center;
          font-size: 20px;
          line-height: 1.01;
          color: #ffffff;
          font-family: var(--common-font);
          font-weight: 700;
          position: absolute;
          left: 59px;
          bottom: 0;
          clip-path: polygon(50% 10%, 100% 0, 100% 100%, 0 100%, 0 0);
          
          @media only screen and (max-width: 1199px) {
            top: 20px;
            bottom: unset;
            left: 50%;
            transform: translateX(-50%);
            font-size: 18px;
            padding: 15px 5px;
          }
        }

        figure {
          width: 222px;
          height: 200px;
          margin: 0 10px 0 0;

          @media only screen and (max-width: 1199px) {
            width: 100%;
            margin: 0 0 10px;
          }

          @media only screen and (max-width: 991px) {
            height: auto;
            max-height: 200px;
          }
        }


        .cmn-pack-content {
          width: calc(100% - 232px);
          text-align: center;

          @media only screen and (max-width: 1199px) {
            width: 100%;
          }

          p {
            font-size: 14px;
            margin-bottom: 11px;
          }

          h5 {
            margin-bottom: 5px;
            line-height: 1.1;
          }

          h6 {
            margin-bottom: 14px;

            span {
              font-size: 14px;
              display: block;
              margin-top: 2px;
              font-weight: 400;

              @media only screen and (max-width: 767px) {
                font-size: 12px;
              }
            }
          }

          .btn {
            font-size: 15px;
            line-height: 1.4;
            min-width: unset;
            border-width: 1px;
            padding: 5px 29px;
            min-width: 180px;

            @media only screen and (max-width: 1399px) {
              padding: 5px 20px;
            }

            &[disabled] {
              background-color: #ddd;
              border-color: #ddd;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .continue-btn {
    text-align: center;
    padding-top: 60px;
    margin-bottom: 10px;

    @media only screen and (max-width: 1199px) {
      padding-top: 40px;
    }

    @media only screen and (max-width: 767px) {
      padding-top: 20px;
    }
  }
`;

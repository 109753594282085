import { useOutletContext, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import api from '../../../api/Apis';
import _ from 'lodash';
import AccordionSec, { AddCardButtonText, FrequentlyProducts, ReviewSection, SupplimentPopUp } from '../common';
import { Button } from '@mui/material';
import Rating from '@mui/material/Rating';
import VedioPlayer from '../../../Components/Common/VedioPlayer';
// import { Star } from '@mui/icons-material';
import SvgAnimationBackground from '../../../Components/Common/SvgAnimationBackground';
import { SecondSection, UniqueDeliveryComp } from '../common';
import ReviewComponent from '../../../Components/Common/RatingSection/ReviewComponent';
import ProductPageDesign, { DialogSectionCss, ProductPageFooter } from '../productPage.style';
import DialogComponent from '../../../Components/Common/DialogComponent';
import parse from 'html-react-parser';
import apiPaths from '../../../services/apPaths';
import StarIcon from '@mui/icons-material/Star';

export default function Product() {
  const props = useOutletContext();

  const { customerCheck, minCartAutoshipCheck, smartShipCheck, setInnerLoader } = props;
  let location = useParams();
  const { id, name, page } = location;
  const [productqty, setQty] = useState(1);
  const [product, setproduct] = useState();
  const [productvariantid, setproductvariantid] = useState();
  const [costtype, setcosttype] = useState([]);
  const [variantdetals, setvariantdetails] = useState();
  const [Logintoken, setToken] = useState()
  const [relatedData, setRelatedData] = useState();
  const [productDetailContent, setProductDetailContent] = useState();
  const [reviewData, setReviewData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const [openKosher, setOpenKosher] = useState(false)
  const [cartNotify, setCartNotify] = useState('')
  const [playingVideo, setPlayingVideo] = useState(null);
  const [showImage, setShowImage] = useState(true);
  const [scrolled, setScrolled] = useState(false);

  // For add to cart product/variant
  const myRef = useRef(null);
  const seeRef = useRef(null);
  const addToCart = async (id, quantity) => {
    const formData = {
      product_id: id, variant_id: productvariantid,
      token: Logintoken, quantity: quantity, is_autoship: _.find(costtype, { id: id })?.value === 'AutoShip' ? true : false
    };
    await api.addToCart(formData).then(res => {
      if (res?.data?.code === 1) {
        setCartNotify("Added To Cart")
        setTimeout(() => {
          setCartNotify('');
        }, 3000)
        // toast.success(res.data.message, { duration: 5 })
        props?.getMiniCartProduct("onLoadData", formData);
        props?.setshowminicart(true)
      }
    })
  }
  // for add product to wishlist
  const addToWishList = (e) => {
    const formData = { product_id: id, variant_id: productvariantid, token: Logintoken, quantity: productqty };
    api.addToWishlist(formData).then(res => {
      if (res?.data?.code === 1) {
        props?.setupdatecartdata(true)
        // toast.success('product added in wishlist successfully')
      }
    })
  }

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const ProductDataFunction = async (id) => {
    try {
      const res = await api.getProductByProductid(id);
      if (res?.data?.code === 1) {
        // setInnerLoader(false);
        setProductDetailContent(res?.data?.product_detail_data);
        setvariantdetails(res?.data?.products?.variants);
        setReviewData(res?.data?.reviews);
        setproduct(res?.data);
        setQty(1);
        setRelatedData(res?.data?.frequently_bought_products);
      } else {
        console.error('API returned error code:', res?.data?.code);
      }
    } catch (error) {
      console.error('Error fetching product data:', error);
      router.back();
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('Token') || null;
    setToken(token);
    // setInnerLoader(true);
    setcosttype([{ id: id, value: "Normal" }]);
    if (id) {
      ProductDataFunction(id);
    }
  }, [location, id]);


  const ForRatingStar = (count = 0) => {
    const Rating = [];
    for (let i = 1; i <= count; i++) {
      Rating.push(<i className="fa fa-star" aria-hidden="true" key={i + 1}></i>);
    }
    return Rating;
  }

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const slidersBanner = useRef();
  const settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    pauseOnHover: false,
  };

  const BiotickKaireVideos = [
    {
      urlData: `https://admin.shopkaire.com///media/products/2024/08/05/BioticKaire-min-2.png`,
      thumbnail: 'https://admin.shopkaire.com///media/products/2024/08/05/BioticKaire-min-2.png',
      isImage: true,
    },
    {
      urlData: `https://player.vimeo.com/video/1005957632?autoplay=false&loop=0&autopause=1&controls=true`,
      thumbnail: 'https://i.vimeocdn.com/video/1922605922-8e262966734fde637e0ca45b6e6b94814ab95670c5bedca4e5142585a59690d5-d?mw=300&mh=536',
    },
    {
      urlData: "https://player.vimeo.com/video/1010759116?autoplay=false&loop=0&autopause=1&controls=true",
      thumbnail: 'https://i.vimeocdn.com/video/1928181056-b629ce9815bb516a499292326d10b82dd809846777d92c15c1a6e8b3cabf658e-d?mw=300&mh=536',
    },
    {
      urlData: "https://player.vimeo.com/video/1005901656?autoplay=false&loop=0&autopause=1&controls=true",
      thumbnail: 'https://i.vimeocdn.com/video/1922540731-c2e48aabf635b4dda5fc2ea611e4b6a6c45d913828fa491293312c7e1e4f4439-d?mw=300&mh=536',
    },

  ]
  const handleThumbnailClick = (index) => {
    if (BiotickKaireVideos[index].isImage) {
      setShowImage(prev => !prev);
      setPlayingVideo(null);
    } else {
      setPlayingVideo(index);
      setShowImage(false);
    }
  };

  return (
    <>
      <head><title>{name?.replace(/_/g, ' ')}</title></head>
      {productDetailContent &&
        <ProductPageDesign bannerImage={productDetailContent?.banner_dummy_image} section2Image={productDetailContent?.section2_dummyImage}>
          {/* first section bannner */}

          {/* {product?.products?.promotion_image_desktop?.length === 0 &&
            <div className={`product-section-banner extra_${name?.toLowerCase()}_class`}>
              {productDetailContent?.banner_bgVedioUrl && <VedioPlayer url={productDetailContent?.banner_bgVedioUrl} />}
              <div className="Content-banner">
                <h2>{product?.products?.name && product?.products?.name}
                  {product?.products?.name !== "BioticKaire+" && product?.products?.name !== "SilverKaire" &&
                    <sup>TM</sup>}
                </h2>

                <p>{productDetailContent?.blurb_description && productDetailContent?.blurb_description}</p>
              </div>
              {(product?.products?.promotion_image_desktop?.length > 0) &&
                <div className='discount_image'>
                  <img src={`${apiPaths.DOC_URL}/${product?.products?.promotion_image_desktop}`} />
                  <img src={`${apiPaths.DOC_URL}/${product?.products?.promotion_image_mobile}`} className="for_mob" />
                </div>
              }
            </div>
          } */}

          {(product?.products?.promotion_image_desktop?.length > 0) &&
            <div className='discount_image'>
              <img src={`${apiPaths.DOC_URL}/${product?.products?.promotion_image_desktop}`} />
              <img src={`${apiPaths.DOC_URL}/${product?.products?.promotion_image_mobile}`} className="for_mob" />
            </div>
          }
          {/* {second section} */}
          <div className={`${product?.products?.promotion_image_desktop?.length > 0 ? "product-section-slider discount_image_active" : "product-section-slider"}`}>
            <div className="background-gradient-svg">
              <svg class="animated-gradients__svg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><rect width="100%" height="150%" x="-65%" y="-65%" fill="url(#gradient1a)"></rect><rect width="150%" height="200%" x="10%" y="-80%" fill="url(#gradient1b)"></rect><rect width="190%" height="150%" x="-50%" y="30%" fill="url(#gradient1c)"></rect><defs><radialGradient id="gradient1a"><stop offset="0%" stop-color="#f1baba"><animate attributeName="stop-color" values="#f1baba;#FFFFFF;#c9bef2;#ffdeaa;#FFFFFF;#ffdeaa;#f1baba" dur="20s" repeatCount="indefinite"></animate></stop><stop offset="100%" stop-color="#FFFFFF"></stop></radialGradient><radialGradient id="gradient1b"><stop offset="0%" stop-color="#b0e4ec"><animate attributeName="stop-color" values="#b0e4ec;#c9bef2;#FFFFFF;#b0e4ec;#c9bef2;#FFFFFF;#b0e4ec" dur="20s" repeatCount="indefinite"></animate></stop><stop offset="100%" stop-color="#FFFFFF" stop-opacity="0"></stop></radialGradient><radialGradient id="gradient1c"><stop offset="0%" stop-color="#FFFFFF"><animate attributeName="stop-color" values="#FFFFFF;#ffdeaa;#f1baba;#FFFFFF;#b0e4ec;#f1baba;#FFFFFF" dur="20s" repeatCount="indefinite"></animate></stop><stop offset="100%" stop-color="#FFFFFF" stop-opacity="0"></stop></radialGradient></defs></svg>
            </div>
            <div className="product-section-details">
              <div className='container'>
                <div className="row product_detail_container">
                  <div className="col-md-5">
                    <div className="product-image">
                      {product?.products?.product_images?.map((productDataimage, index) => (
                        <img src={`${apiPaths.DOC_URL}/${productDataimage['image']}`} key={index + 1} className="product_img" style={{ display: playingVideo === null ? 'block' : 'none' }} />
                      ))}
                      {productDetailContent?.id === 19 &&
                        playingVideo !== null && (
                          <div className="video-player">
                            <iframe
                              src={BiotickKaireVideos[playingVideo].urlData.replace('autoplay=false', 'autoplay=true')}
                              width="1000"
                              height="800"
                              frameBorder="0"
                              allow="autoplay; fullscreen;"
                              allowFullScreen
                            ></iframe>
                          </div>
                        )
                      }
                      {productDetailContent?.id === 19 &&
                        <div className="thumbnails">
                          {BiotickKaireVideos.map((video, index) => (

                            <div
                              // className={`${playingVideo === index ? "biotickakire-video active" : "biotickakire-video"}`} 
                              className={`biotickakire-video ${playingVideo === index || (showImage && index === 0) ? "active" : ""}`}
                              key={index}

                            >
                              <img
                                src={video.thumbnail}
                                alt={`Thumbnail video ${index + 1}`}
                                style={{ cursor: 'pointer', width: '100%', height: 'auto' }}
                                onClick={() => handleThumbnailClick(index)}
                              />
                            </div>
                          ))}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="custom-cont">
                      <div className="first_section">
                        <h2 className="title">{product?.products?.name &&
                          `${product?.product_name || ""} ${product?.products?.name || ""}`}
                          {product?.products?.name !== "BioticKaire+" && product?.products?.name !== "SilverKaire" &&
                            <sup style={{ fontSize: '18px' }}>TM</sup>}
                        </h2>
                        <p>{productDetailContent?.asked_section && productDetailContent?.asked_section}</p>
                      </div>
                      <SecondSection data={{
                        smartShipCheck,
                        costtype,
                        setcosttype,
                        id: id,
                        minCartAutoshipCheck,
                        productCost: product?.products.cost_price,
                        productSmartShip: product?.products.autoship_cost_price
                      }} />
                      <div className="third_section">
                        <div className="quantity_section box">
                          <span><b>Qty:</b></span>
                          <div id="qty">
                            <button type="button" id="sub" className="sub" onClick={(e) => {
                              setQty(productqty > 1 ? productqty - 1 : productqty)
                            }}>-</button>
                            <input type="text" value={productqty} name="qty" onChange={(e) => {
                              setQty(e.target.value)
                            }} min="1" max={product?.products?.quantity} readOnly />
                            <button type="button" id="add" className="add" onClick={(e) => {
                              setQty(productqty + 1);
                            }}>+</button>
                          </div>
                        </div>
                        <div className="add_button_div">
                          <div className={`btn-sec Add_to_cart ${product?.products?.is_stock_available === 'True' ? '' : 'out-of-stock'}`} >
                            <button disabled={product?.products?.is_stock_available === 'True' ? false : true}
                              type="button" id={product?.products?.id}
                              onClick={(e) => addToCart(id, productqty)}>
                              {AddCardButtonText(product?.products?.is_stock_available, costtype, id)}
                            </button>
                          </div>
                        </div>

                      </div>
                      <div className="fourth_section">
                        <div className="review-section-now">
                          {props.isLogin || Logintoken !== null &&
                            <p>BV: {(_.find(costtype, { id: id })?.value === 'AutoShip' || minCartAutoshipCheck === "True") ? product?.products?.autoship_bonus_value.split()[0] : product?.products?.bonus_value} </p>
                          }
                          <ul>
                            <li>
                              <Rating
                                name="rating"
                                value={4}
                                readOnly
                                precision={0.5}
                                sx={{ color: "black" }}
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                              />
                            </li>
                            <li className="review_list" onClick={() => myRef.current.scrollIntoView()}>
                              <a href="#" style={{ pointerEvents: "none" }}>{reviewData?.length} reviews</a>
                            </li>
                            {props.isLogin || Logintoken !== null &&
                              <>
                                <li><span className="breaker">|</span><button onClick={() => setOpenReview(true)} style={{ background: "none", padding: 0 }}>Write a review</button></li>
                                <ReviewComponent productId={id} productName={name?.replaceAll('_', " ")} section="product_page" open={openReview} handleClose={setOpenReview} setOpen={setOpen} reviewData={reviewData} setReviewData={setReviewData} />
                              </>
                            }
                          </ul>
                        </div>
                        {productDetailContent?.supplement_facts &&
                          <>
                            <div className="supplement-fact">
                              <Button className="subpplement_button" onClick={() => handleClickOpen()}>Supplement Facts</Button>
                              {/*{props.isLogin === true &&
                            <Button id={product?.products?.id} onClick={(e) => addToWishList(e)}>Add to WishList</Button>
                          }*/}
                              <SupplimentPopUp handleClose={handleClose} open={open} image={productDetailContent?.supplement_facts} />
                            </div>
                            {productDetailContent?.id !== 19 && productDetailContent?.id !== 7 &&
                              <div className="Kosher">
                                <a style={{ cursor: 'pointer' }} onClick={() => setOpenKosher(true)} className="par-logo">  <img src="/KosherLogo-ai.png" /><p><span>?</span>
                                  what is Kosher</p>
                                </a>
                              </div>
                            }
                            <DialogComponent opend={openKosher} handleClose={() => setOpenKosher(false)} title="Description" classFor="order_details">
                              <DialogSectionCss>
                                <div className="head_kosher">
                                  <h3>What is Kosher & What Does It Mean?</h3>
                                </div>

                                <p> According to the kldbkosher.org website, “Kosher is a Hebrew word that means fit, proper, or correct.
                                  Nowadays, it is mostly used to describe food and drink that complies with Jewish religious dietary law.”</p>

                                <div className="certified">
                                  <h3> What is Kosher Certified?</h3>
                                </div>
                                <p> For a product to be kosher certified, and to qualify for a kosher certificate, each ingredient, food additive,
                                  and processing aid used in its production must also be kosher. Additionally, to be kosher certified,
                                  the production process must be suitable for kosher requirements and therefore it must be approved by a kosher auditor.
                                  Products may be rendered non-kosher if their production lines and equipment are also used to manufacture non-kosher products.</p>

                              </DialogSectionCss>
                            </DialogComponent>
                          </>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {productDetailContent?.id === 19 &&
              <div className='biotickakire-video-sec'>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="biotickakire-video-wrap">
                        <h2>
                          WHAT ARE PEOPLE SAYING
                        </h2>
                        <div className="biotickakire-video-innerwrap">
                          {
                            BiotickKaireVideos?.slice(1)?.map((video, index) => {
                              const videoUrl = playingVideo === index
                                ? video?.urlData?.replace('autoplay=false', 'autoplay=true')
                                : video?.urlData;
                              return (
                                <div className="biotickakire-video"
                                  key={index}
                                // onMouseEnter={() => setPlayingVideo(index)}
                                // onMouseLeave={() => setPlayingVideo(null)}
                                >
                                  <iframe src={video?.urlData}
                                    width="1000" height="800" frameborder="0" allow="autoplay; fullscreen;"
                                    allowfullscreen ></iframe>

                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className="kaire_live_longer">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className='product-desc text-center'>
                      <h3>{productDetailContent?.product_detail_heading && parse(productDetailContent?.product_detail_heading)}</h3>
                      <p>{productDetailContent?.product_detail_content}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="second_vedio_section">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    {productDetailContent?.section2_bgVedioUrl &&
                      <>
                        {/*  <Parallax y={[-20, 5]} tagOuter="figure" className="custom-class">0*/}
                        <div className='our-unique'>
                          <VedioPlayer url={productDetailContent?.section2_bgVedioUrl} />
                        </div>
                        {/*  </Parallax>*/}
                      </>
                    }
                    {(productDetailContent?.section2_title !== "" && productDetailContent?.section2_content !== "") &&
                      <UniqueDeliveryComp
                        proven_button_link={productDetailContent?.section2_button_link}
                        image={product?.products?.product_images?.length > 0 && product?.products?.product_images[0]?.image}
                        heading={productDetailContent?.section2_title}
                        content={productDetailContent?.section2_content}
                        productName={product?.products?.name && product?.products?.name}
                        seeMoreFunction={() => seeRef.current.scrollIntoView()}
                      >
                        <div className={`btn-sec Add_to_cart ${product?.products?.is_stock_available === 'True' ? '' : 'out-of-stock'}`} >
                          <button
                            disabled={product?.products?.is_stock_available === 'True' ? false : true}
                            type="button" id={product?.products?.id}
                            onClick={(e) => addToCart(id, productqty)}>
                            {product?.products?.is_stock_available === 'True' ? 'Add to Cart' : 'Sold Out'}
                          </button>
                        </div>

                      </UniqueDeliveryComp>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="accordian_section clearfix">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <AccordionSec product={product} ref={seeRef} />
                  </div>
                </div>
              </div>
            </div>
            <div className="frequently_product_section">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    {relatedData?.length > 0 &&
                      <FrequentlyProducts
                        data={{
                          smartShipCheck,
                          minCartAutoshipCheck,
                          addToCart,
                          relatedData,
                          costtype, setcosttype,
                        }}
                        cartNotify={cartNotify} />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {third section} */}

          {/* {section 4} */}
          <div className="customer-review-now" ref={myRef}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="review-customer">
                    <h5>What are people saying about this product?</h5>
                    <h2>Customer Reviews</h2>
                  </div>
                </div>
              </div>
              <div id="review">
                <ReviewSection ForRatingStar={ForRatingStar} reviewData={reviewData} setReviewData={setReviewData} />
              </div>
            </div>
            <div className="main-qus">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="question-text">
                      <div className="stay-touch-part">
                        <h5>Stay in Touch</h5>
                      </div>
                      <div className="form-subscribe">
                        <form className="form-inline">
                          <div className="form-group">
                            <label className="col-form-label">Subscribe</label>
                            <input type="text" readOnly className="form-control-plaintext" id="staticEmail" value="*Your email address " />
                          </div>
                          <button type="submit" className="btn btn-primary">Subscribe</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-text-sec">
              <div className="container">
                <div className="box-text-bnner">
                  {/* <p>Pregnant or nursing mothers, children under 18, and individuals with a known medical condition should consult
                    a physician before using this or any dietary supplement.
                  </p> */}
                  <h4>*Individual results may vary and are not guaranteed</h4>
                </div>
              </div>
            </div>
          </div>
          <SvgAnimationBackground />
          <ProductPageFooter>
            <div className={`${scrolled ? 'price_section_footer scrolled' : 'price_section_footer'}`}>
              <div className='price-sec'>
                <div className="price-inner-sec">
                  <SecondSection data={{
                    smartShipCheck,
                    costtype,
                    setcosttype,
                    id: id,
                    minCartAutoshipCheck,
                    productCost: product?.products.cost_price,
                    productSmartShip: product?.products.autoship_cost_price
                  }} />
                  {/* {(customerCheck > 1) && */}
                  {props.isLogin &&
                    <h5 className="bonus_value">BV: {(_.find(costtype, { id: id })?.value === 'AutoShip' || minCartAutoshipCheck === "True") ? product?.products?.autoship_bonus_value.split()[0] : product?.products?.bonus_value} </h5>
                  }
                  {/* } */}
                </div>
                <div className="footer-merge">
                  <div className="quality">
                    <div className="main-qty-sec">
                      <div className="box">
                        <div id="qty">
                          <button type="button" id="sub" className="sub" onClick={(e) => {
                            setQty(productqty > 1 ? productqty - 1 : productqty)
                          }}>-</button>
                          <input type="text" value={productqty} name="qty" onChange={(e) => {
                            setQty(e.target.value)
                          }} min="1" max={product?.products?.quantity} readOnly />
                          <button type="button" id="add" className="add" onClick={(e) => {
                            setQty(productqty + 1);
                          }}>+</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cart-add">
                    {/* {operation === 'showpopup' && <Popups message={addtocartmessage} />} */}
                    <div className="add_button_div">
                      <div className={`btn-sec Add_to_cart ${product?.products?.is_stock_available === 'True' ? '' : 'out-of-stock'}`} >
                        <button className="add_margin" disabled={product?.products?.is_stock_available === 'True' ? false : true}
                          type="button" id={product?.products?.id}
                          onClick={(e) => addToCart(id, productqty)}>
                          {AddCardButtonText(product?.products?.is_stock_available, costtype, id)}
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ProductPageFooter>
        </ProductPageDesign >
      }
    </>
  )
}

export const getStaticPaths = async ({ id }) => {
  let paths;
  const defaultValues = {
    paths: [],
    fallback: true,
  };

  try {
    // const allSlugs = ["us/product/Test2%20Product/12/", "us/product/Test3%20Product/13/"];
    const res = await fetch(`${apiPaths.DOC_URL.getAllProduct}?slug=us`)
    let data_page = await res?.json();
    data_page = data_page?.products
    paths = data_page?.map((c) => {
      let names = c?.name.replace('25%', '')
      let catname = names.replace('\t', '')
      catname = catname.replace(/\s/g, '_');
      return (`/us/product/${catname}/${c?.id}`)
    });    // paths = allSlugs.map((slug) => "/" + slug);

  } catch (e) { return defaultValues; }
  return {
    paths,
    fallback: true,
  };
};

export const getStaticProps = async ({ params: { page, id } }) => {
  const res = await fetch(`${apiPaths.DOC_URL.getAllProduct}?slug=us`)
  let data_page = await res.json();
  // data_page = data_page//?.products
  return {
    props: {
      id, data_page
    },
    revalidate: 60,
  };
};
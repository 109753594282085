// import { useEffect, useState } from "react";
// import api from "../../../api/Apis";

import _ from "lodash";
import { useEffect, useState } from "react";
import api from "../../api/Apis";
import { AppliedCouponCss } from "./AppliedCouponCss";


const Discount = ({
  cartData,
  coupon,
  setcoupon,
  amountCalculations,
  setAmountCalculations,
  logintoken,
  setToken,
  amountAfterDiscount,
  setAmountAfterDiscount,
  discount_amount1,
  setdiscount_amount,
  setcopanerror,
  totalPrice,
  setTotalPrice,
  setCouponData
}) => {
  const [couponCheck, setCouponCheck] = useState("");
  const [shippingId, setShippingId] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("Token")
      ? localStorage.getItem("Token")
      : "";
    setToken(token);
    const SHIPPING_ADDRESS = localStorage.getItem("shippingAddress");
    setShippingId(SHIPPING_ADDRESS)
    if (localStorage.getItem("coupon_name")?.length > 0) {
      const alreadyExistCoupon = localStorage.getItem("coupon_name");
      setCouponCheck(alreadyExistCoupon);
    }
  }, [shippingId]);

  const onSubmit = (data) => {
    const TrueValues = _.map(_.filter(cartData?.products, (row) => row?.is_autoship === "True"), (product) => product.is_autoship === "True");
    if (couponCheck !== "") {
      let orderdata = {
        is_autoship: cartData?.is_autoship_user === "True" || TrueValues.length >= 1 ? "True" : "False",
        coupon: couponCheck,
        shipping_id: shippingId ? shippingId : "",
        product: cartData?.products?.map((items) => ({
          ID: items?.id,
          QTY: items?.quantity,
        })),
        is_member: true
      };
      api.verifyCopan(orderdata, logintoken).then((res) => {
        let freeShippingStatus = false;
        if (res?.data?.code === 1) {
          setCouponData(res?.data);
          setcopanerror("");
          localStorage.setItem("coupon_name", res.data.coupon_details?.code);
          setAmountCalculations(res?.data?.amountCalculations)

          const { amountCalculations } = res?.data;
          const discountedAmounts = (amountCalculations || []).map((amount) => {
            return {
              discountAmount: amount?.discountAmount || 0,
              productAmountAfterDiscount: amount?.productAmountAfterDiscount || 0,
            };
          });
          if (discountedAmounts.length) {
            const totalDiscountAmount = discountedAmounts.reduce((acc, curr) => acc + curr.discountAmount, 0);
            setdiscount_amount(totalDiscountAmount);
            setAmountAfterDiscount(discountedAmounts.map((amount) => amount.productAmountAfterDiscount));
            const newTotal = totalPrice.total - totalDiscountAmount;
            const newSubtotal = totalPrice.subtotal;
            setTotalPrice({ total: newTotal, subtotal: newSubtotal });
          }


          setcoupon(res?.data?.coupon_details);
        } else {
          setcopanerror(res?.data?.message);
        }
      });
    }
  }



  return (

    <AppliedCouponCss>
      {(discount_amount1 !== undefined || discount_amount1 !== null) &&
        coupon?.name ? (
        ""
      ) : (
        <>
          <div className="apply-coupn">
            <label>
              <b>COUPON CODE</b>
            </label>
            <div className="row">
              <div className="col-md-12">
                <div className="wrap-form">
                  <input
                    type="text"
                    className="form-control"
                    name="coupon_id"
                    placeholder="Coupon"
                    id="coupon_id"
                    value={couponCheck}
                    onChange={(e) => setCouponCheck(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => onSubmit()}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </AppliedCouponCss>
  )

}


export default Discount;

import React from 'react';
import withRouter from '../services/HOC/withRouterHOC';

import Header from './Header'
import Footer from './Footer'

const MainLayout = ({ children, ...props }) => {
    const pathName = window.location.pathname

    return (
        <div className='main_layout_section'>
            {pathName !== "/signup" &&
                <Header customerCheck={props?.customerCheck} {...props} />}
            {children}
            {pathName !== "/signup" &&
                <Footer {...props} />}
        </div>
    )
}

export default MainLayout;
import React from 'react'
import { OurStoryStyle } from './OurStoryStyle'

const OurStory = () => {
  return (
    <OurStoryStyle>
        <section className="story-banner-sec">
            <figure>
                <img src="images/story-banner.jpg" alt="Banner Image" />
            </figure>
        </section>

        <section className="story-main-sec">
            <div className="container">
                <h2>OUR STORY</h2>
                <div className="story-main-inner">
                    <div className="story-main">
                        <figure>
                            <img src="images/story.jpg" alt="Image" />
                        </figure>
                        <p>
                            The Lightfoot family shares a deep connection with Kaire that spans over three plus decades. Their journey with Kaire International, Inc. began in 1992 in the United States and expanded to Canada in 1993, shaping their family's destiny. Little did they know, this journey would become a lifelong commitment.
                        </p>
                        <p>
                            In 1997, a pivotal moment arrived as Michael took on the leadership of Kaire USA, marking their family’s deeper involvement with the company.
                        </p>
                        <p>
                            Throughout these years, Michael, Linda, and their children, Matthew, and Jana, dedicated themselves to every aspect of the company, immersing in the world of referral sales, gaining invaluable experience.
                        </p>
                        <p>
                            In 2011, Kaire transitioned to an online e-commerce sales model to meet the evolving needs of its distributors.
                        </p>
                    </div>
                    <p>
                        After Michael’s unexpected passing in 2020, Jana, Matthew, and Linda remained committed to fulfilling Michael's vision of reintroducing referral sales to Kaire.  With their deep love for Kaire and its life-transforming products, they aim to empower thousands to reclaim their health and redefine their lives.  They have put the sharing back in Kaire to make the ShareKaire Program.
                    </p>
                    <h6>
                        <small>
                            They are here to support you every step of the way on your journey to success.  Let’s embark on this exciting adventure together and make a positive impact on the world. 
                        </small>
                    </h6>
                </div>
            </div>
        </section>
    </OurStoryStyle>
  )
}

export default OurStory

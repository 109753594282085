import React from "react";
import ReviewBanner from "./components/ReviewBanner";
import { ReviewStyle } from "../Signup/components/ReviewStyle";
import ReviewPack from "../Signup/components/ReviewPack";

const Review = () => {
  return (
    <>
      <ReviewStyle>
        <ReviewBanner />
        {/* <ReviewPack /> */}
      </ReviewStyle>
    </>
  );
};

export default Review;

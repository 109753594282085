let baseurl = import.meta.env.VITE_REACT_APP_API_URL;
let DOC_URL = import.meta.env.VITE_REACT_APP_DOC_URL;
let SHOPKAIRE_URL = import.meta.env.VITE_REACT_APP_SHOPKAIRE_SITE_URL;
const apiPaths = {
    domainName: "https://shopkaire.com",
    API_URL: 'https://admin.shopkaire.com/',
    DOC_URL: DOC_URL,
    SHOKAIRE_URL: SHOPKAIRE_URL,
    API_BASE_URL: 'https://admin.shopkaire.com/api/v1/',
    MEDIA_URL: "https://admin.shopkaire.com/media/",
    Date_Format: 'MM-DD-YYYY',
    Check_email_newuser: baseurl + "check_email_newuser/",
    supportDesk: baseurl + '/support_desk/',
    supportDeskReply: baseurl + '/support_desk_reply/',
    promoterPackApi: baseurl + '/get_promoter_packs/',
    menuSection: baseurl + '/menu-content/',
    clickedapi: baseurl + 'update_lead_onclick/',
    couponListApi: baseurl + '/get_FO_coupon/',
    sendCouponApi: baseurl + '/send_FO_coupon/',
    subDomainCheck: baseurl + '/check-validuser/',
    legendPageUrl: baseurl + '/get_theLegend_content/',
    pycnogenol: baseurl + '/get_pycnogenol_content/',
    calculateTaxAmount: baseurl + "calculate_tax_amount/",
    sinUpApi: baseurl + 'signup/',
    sinUpUpdateApi: baseurl + 'update_signup/',
    loginApi: baseurl + 'login/',
    logoutApi: baseurl + 'logout/',
    getKaireUsers: baseurl + "get_kaire_users",
    getActiveSmartships: baseurl + "get_active_smartships",
    getKairePageDetails: baseurl + "get_kaire_page_details",
    calculateShipping: baseurl + "calculate_shipping/",
    autoshipCardUpdate: baseurl + "autoship_card_update/",
    availableShippingModules: baseurl + "available_shipping_modules/",
    getBanners: baseurl + 'store_banner/',
    getHomepageContent: baseurl + 'get_homepage_content/',
    getSavedCards: baseurl + 'get_saved_cards/',
    deleteCard: baseurl + "delete_card/",
    saveNewCard: baseurl + 'save_new_card/',
    saveCustomerReview: baseurl + 'save_customer_review/',
    getProductByCategories: baseurl + 'get_products_by_category/',
    multiProductAddToCart: baseurl + 'add_to_cart_multiple/',
    getProductByProductid: baseurl + 'get_product_details/',
    upComingOrders: baseurl + 'upcoming_orders/',
    getAllProduct: baseurl + 'get_all_products/',
    getdashboardpopup: baseurl + 'get-dashboard-popup/',
    addToCart: baseurl + 'add_to_cart/',
    getAllCartProduct: baseurl + 'get_cart_details',
    deleteProductFromAddToCart: baseurl + 'delete_cart_product/',
    updateProductQty: baseurl + 'update_product_qty/',
    getProfilePageData: baseurl + 'profile_details/',
    changeUserPassword: baseurl + 'change_password/',
    addToWishlist: baseurl + 'add_to_wishlist/',
    getAllWishListProduct: baseurl + 'get_wishlist_details/',
    deleteProductFromwishlist: baseurl + 'delete_wishlist_product/',
    manageAddress: baseurl + 'manage_address/',
    getProductByVariantid: baseurl + 'get_variant_details/',
    createOrder: baseurl + 'create_order/',
    verifyCopan: baseurl + 'apply_coupon/',
    getAllCategory: baseurl + 'get_all_categories/',
    getproductsbycategory: baseurl + 'get_products_by_category/',
    updateCart: baseurl + 'update_cart/',
    invitationLink: baseurl + 'save_lead_data/',
    EditSmartshipData: baseurl + 'edit_autoship/',
    getUserOrder: baseurl + 'order_history/',
    getAddressDetails: baseurl + 'get_address_details/',
    GetOrderDetail: baseurl + 'get_order_details/',
    deleteAddress: baseurl + 'delete_address/',
    reorderProducts: baseurl + 're_order/',
    getAddressList: baseurl + 'get_addresses/',
    autoshipOrderHistory: baseurl + 'autoship_order_history/',
    autoshipOrderById: baseurl + 'get_autoship_order_details/',
    autoshipUpdate: baseurl + 'autoship_update/',
    autoshipDelete: baseurl + 'autoship_delete/',
    autoshipSkip: baseurl + 'autoship_skip/',
    updateAddress: baseurl + 'update_address/',
    getUserNotifications: baseurl + 'get_user_notifications/',
    autoshipProductDelete: baseurl + 'autoship_product_delete/',
    autoshipProductUpateproduct: baseurl + 'autoship_order_add_product/',
    addressUpdate: baseurl + 'autoship_address_update/',
    resetPassword: baseurl + 'api/password_reset/',
    verifyOtp: baseurl + 'verify_otp/',
    passwordResetConfirm: baseurl + 'api/password_reset/confirm/',
    permalinkAPI: baseurl + "perma-link/",
    cancelOrder: baseurl + 'cancle_order/',
    refundOrder: baseurl + 'refund_order/',
    getDownlineUsers: baseurl + 'get_downline_users/',
    getRefundHistory: baseurl + 'get_refund_history',
    getBundleProduct: baseurl + 'get_bundle_details/',
    redeemKaireCash: baseurl + 'redeem_kaire_cash/',
    getCommissionReport: baseurl + 'get_commissions_report/',
    getCommissionsFilter: baseurl + 'get_commissions_filter/',
    getRefundReport: baseurl + 'get_clawbacks/',
    getRefundReportFilter: baseurl + 'get_clawbacks_filter/',
    getMyProfileDetails: baseurl + 'get_my_profile_details/',
    getDashboardCommissions: baseurl + 'get_dashboard_commissions/',
    GetCommissionsApproved: baseurl + 'get_commissions_approved/',
    LoginCheck: baseurl + 'login_check/',
    getOrderDetailForRefund: baseurl + 'get_new_order_details/',
    profileUpdate: baseurl + 'profile_update/',
    getAllTranaction: baseurl + 'get_commission_transactions/',
    getNewDownlineUsers: baseurl + 'get_new_downline_users/',
    getDownlineUserAddress: baseurl + 'get_downline_user_addresses/',
    getKaireCashTranaction: baseurl + 'get_kaire_cash_transactions/',
    getDashboardNews: baseurl + 'get_dashboard_news/',
    profileImageUpload: baseurl + 'profile_image_upload/',
    getDocuments: baseurl + 'get_documents/',
    getProvenbenefitsContent: baseurl + 'get_provenbenefits_content/',
    getMaritimePrimeApiUrl: baseurl + 'get_maritimePrime_content/',
    defaultCard: baseurl + 'select_default_card/',
    getOrderPQVandGV: baseurl + 'get_order_PQVandgv/',
    JWT_KEY: 'gdfghfgh-fgfdgfdgfdhdsasafraeeyjyukktd-dfghdfhfgdhrdt',
    CSRF_SECRET: "screetenvvalue",
    payoutRegister: baseurl + "ipyout-user-register/",
    payoutAddEwalletBalance: baseurl + "ipyout-add-ewallet-balance/",
    orderInvoice: baseurl + "order-print-out-details/",
    unsubscribedEmail: baseurl + "unsubscribe_from_email/",
    magicLinkEmail: baseurl + "send_magic_link/",
}

export default apiPaths;
import styled from "styled-components";



export const MemberHighlightCss = styled.section`
  height: 100%;
  width: 100%;
  padding: 50px 0;
  display: flex;
  align-items: center;
  position: relative;

  .cstm-row {
    .col-5 {
      @media only screen and (max-width: 767px) {
        order: 2;
      }
    }
    .col-7 {
      @media only screen and (max-width: 767px) {
        order: 1;
      }
    }
  }

  .member-highlight-content {
    max-width: 400px;
    margin-top: 20px;
    position: relative;
    z-index: 2;

    @media only screen and (max-width: 991px) {
      max-width: 100%;
      padding: 30px;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 10px;
    }

    @media only screen and (max-width: 767px) {
      /* padding: 20px 15px; */
      padding: 0;
      background: none;
    }

    @media only screen and (max-width: 575px) {
      /* padding: 15px 12px; */
      padding: 0;
    }

    h2 {
      margin-bottom: 20px;

      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    ul {
      list-style: disc;
      padding-left: 15px;
      li {
        &:not(:last-child) {
          margin-bottom: 10px;
          
          @media only screen and (max-width: 767px) {
            margin-bottom: 5px;
          }
        }
        
        ul {
          list-style: none;
          li {
            position: relative;

            &:first-child {
              margin-top: 10px;
            }

            &::before {
              content: "";
              width: 5px;
              border-bottom: 1px solid;
              position: absolute;
              left: -15px;
              top: 14px;

              @media only screen and (max-width: 1199px) {
                left: -12px;
                top: 12px;
              }
            }
          }
        }
      }
    }
    .btn-wrap {
      margin-top: 35px;
      justify-content: flex-start;

      @media only screen and (max-width: 767px) {
        margin-top: 16px;
      }

      .btn {
        font-size: 16px;
        line-height: 1.75;
        min-width: unset;
        border-width: 1px;
        padding: 5px 29px;

        @media only screen and (max-width: 767px) {
          font-size: 14px;
          padding: 5px 20px;
        }
      }
    }
  }

  .member-highlight-img {
    margin: 0;
    max-width: 590px;
    width: 100%;
    margin-left: auto;

    @media only screen and (max-width: 991px) {
      margin: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    @media only screen and (max-width: 767px) {
      position: static;
      transform: unset;
    }
  } 
`;

export const RewardBannerCss = styled.section`
  /* padding: 90px 0 100px; */
  /* background-image: url("images/reward-banner.png"); */
  /* background-image: url("images/reward-banner-new.jpg"); */
  text-align: left;
  /* min-height: 100vh; */
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  padding: 50px 0;
  display: flex;
  align-items: center;

  @media only screen and (min-width: 1599px) {
    /* min-height: 1081px; */
  }

  .reward-banner-text {
    /* color: var(--cmn-white); */

    @media only screen and (max-width: 991px) {
      max-width: 100%;
      padding: 30px;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 10px;
    }

    @media only screen and (max-width: 767px) {
      /* padding: 20px 15px; */
      padding: 0;
      background: none;
    }

    @media only screen and (max-width: 575px) {
      /* padding: 15px 12px; */
      padding: 0;
    }

    p {
      /* max-width: 490px; */
      max-width: 550px;

      @media only screen and (max-width: 991px) {
        max-width: 100%;
      }
    }

    .btn-wrap {
      padding-top: 19px;
      justify-content: flex-start;

      @media only screen and (max-width: 767px) {
        padding-top: 16px;
      }

      .btn {
        font-size: 16px;
        line-height: 1.75;
        min-width: unset;
        border-width: 1px;
        padding: 5px 29px;

        @media only screen and (max-width: 767px) {
          font-size: 14px;
          padding: 5px 20px;
        }
      }
    }
  }
`;

export const HighlightSectionCss = styled.section`
  text-align: left;
  /* padding: 141px 0 454px; */
  /* background-image: url("images/highlight-bg.png"); */
  /* min-height: 100vh; */
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  padding: 50px 0;
  display: flex;
  align-items: center;

  @media only screen and (min-width: 1599px) {
    /* min-height: 1081px; */
  }

  .manual-background {
    @media only screen and (max-width: 767px){
      display: none;
    }
  }

  .col-6 {
    margin-left: auto;
  }

  .highlight-text {
    max-width: 452px;
    padding-left: 30px;

    @media only screen and (max-width: 991px) {
      /* padding-left: 0px; */
      max-width: 100%;
      padding: 30px;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 10px;
    }

    @media only screen and (max-width: 767px) {
      /* padding: 20px 15px; */
      padding: 0;
      background: none;
    }

    @media only screen and (max-width: 575px) {
      /* padding: 15px 12px; */
      padding: 0;
    }

    h2 {
      margin-bottom: 42px;

      @media only screen and (max-width: 991px) {
        margin-bottom: 20px;
      }

      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    ul {
      list-style: disc;
      padding-left: 15px;
      li {
        &:not(:last-child) {
          margin-bottom: 20px;

          @media only screen and (max-width: 991px) {
            margin-bottom: 10px;
          }

          @media only screen and (max-width: 767px) {
            margin-bottom: 5px;
          }
        }
      }
    }

    .btn-wrap {
      margin-top: 35px;
      justify-content: flex-start;

      @media only screen and (max-width: 767px) {
        margin-top: 16px;
      }

      .btn {
        font-size: 16px;
        line-height: 1.75;
        min-width: unset;
        border-width: 1px;
        padding: 5px 29px;

        @media only screen and (max-width: 767px) {
          font-size: 14px;
          padding: 5px 20px;
        }
      }
    }
  }
`;

export const CustomerBonusCss = styled.section`
  background-image: url("images/customer-bonus-bg.png");
  /* padding: 290px 0; */
  /* min-height: 100vh; */
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  padding: 50px 0;
  display: flex;
  align-items: center;

  @media only screen and (min-width: 1599px) {
    /* min-height: 1081px; */
  }

  .customer-bonus-content {
    max-width: 470px;

    @media only screen and (max-width: 991px) {
      max-width: 100%;
      padding: 30px;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 10px;
    }

    @media only screen and (max-width: 767px) {
      padding: 20px 15px;
    }

    @media only screen and (max-width: 575px) {
      padding: 15px 12px;
    }

    h2 {
      margin-bottom: 20px;

      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
    ul {
      list-style: disc;
      padding-left: 15px;
      li {
        &:not(:last-child) {
          margin-bottom: 10px;

          @media only screen and (max-width: 767px) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .btn-wrap {
    justify-content: flex-start;
    margin-top: 32px;

    @media only screen and (max-width: 767px) {
      margin-top: 16px;
    }

    .btn {
      font-size: 16px;
      line-height: 1.75;
      min-width: unset;
      border-width: 1px;
      padding: 5px 29px;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
        padding: 5px 20px;
      }
    }
  }
`;

export const SmartshipBonusCss = styled.section`
  /* background-image: url("images/smartship-bonus-bg.png"); */
  /* padding: 109px 0; */
  /* min-height: 100vh; */
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  padding: 50px 0;
  display: flex;
  align-items: center;

  @media only screen and (min-width: 1599px) {
    /* min-height: 1081px; */
  }

  .col-6 {
    margin-left: auto;
  }

  .smartship-bonus-content {
    max-width: 485px;
    margin-left: auto;

    @media only screen and (max-width: 991px) {
      max-width: 100%;
      padding: 30px;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 10px;
    }

    @media only screen and (max-width: 767px) {
      /* padding: 20px 15px; */
      padding: 0;
      background: none;
    }

    @media only screen and (max-width: 575px) {
      /* padding: 15px 12px; */
      padding: 0;
    }

    h2 {
      margin-bottom: 20px;

      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    ul {
      list-style: disc;
      padding-left: 15px;
      li {
        &:not(:last-child) {
          margin-bottom: 10px;

          @media only screen and (max-width: 767px) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .btn-wrap {
    justify-content: flex-start;
    margin-top: 32px;

    @media only screen and (max-width: 767px) {
      margin-top: 16px;
    }

    .btn {
      font-size: 16px;
      line-height: 1.75;
      min-width: unset;
      border-width: 1px;
      padding: 5px 29px;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
        padding: 5px 20px;
      }
    }
  }
`;

export const FreeBonusCss = styled.section`
  background-image: url("images/free-bonus-bg-new.jpg");
  /* padding: 312px 0; */
  /* min-height: 100vh; */
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  padding: 50px 0;
  display: flex;
  align-items: center;

  @media only screen and (min-width: 1599px) {
    /* min-height: 1081px; */
  }

  @media only screen and (max-width: 767px) {
    background: none;
  }

  .free-bonus-content {
    max-width: 555px;

    @media only screen and (max-width: 991px) {
      max-width: 100%;
      padding: 30px;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 10px;
    }

    @media only screen and (max-width: 767px) {
      /* padding: 20px 15px; */
      padding: 0;
      background: none;
    }

    @media only screen and (max-width: 575px) {
      /* padding: 15px 12px; */
      padding: 0;
    }

    h2 {
      margin-bottom: 20px;

      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    ul {
      list-style: disc;
      padding-left: 15px;
      li {
        &:not(:last-child) {
          margin-bottom: 10px;

          @media only screen and (max-width: 767px) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .btn-wrap {
    justify-content: flex-start;
    margin-top: 32px;

    @media only screen and (max-width: 767px) {
      margin-top: 16px;
    }

    .btn {
      font-size: 16px;
      line-height: 1.75;
      min-width: unset;
      border-width: 1px;
      padding: 5px 29px;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
        padding: 5px 20px;
      }
    }
  }
`;

export const FirstBonusCss = styled.section`
  /* background-image: url("images/first-bonus-bg.png"); */
  padding: 108px 0;
  /* min-height: 100vh; */
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  padding: 50px 0;
  display: flex;
  align-items: center;

  @media only screen and (min-width: 1599px) {
    /* min-height: 1081px; */
  }

  .col-6 {
    margin-left: auto;
  }

  .first-bonus-content {
    margin-left: auto;
    margin-right: 30px;
    /* max-width: 360px; */
    max-width: 400px;

    @media only screen and (max-width: 991px) {
      max-width: 100%;
      margin-right: 0px;
      padding: 30px;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 10px;
    }

    @media only screen and (max-width: 767px) {
      /* padding: 20px 15px; */
      padding: 0;
      background: none;
    }

    @media only screen and (max-width: 575px) {
      /* padding: 15px 12px; */
      padding: 0;
    }

    h2 {
      margin-bottom: 20px;

      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    ul {
      list-style: disc;
      padding-left: 15px;
      li {
        &:not(:last-child) {
          margin-bottom: 10px;

          @media only screen and (max-width: 767px) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .btn-wrap {
    justify-content: flex-start;
    margin-top: 32px;

    @media only screen and (max-width: 767px) {
      margin-top: 16px;
    }

    .btn {
      font-size: 16px;
      line-height: 1.75;
      min-width: unset;
      border-width: 1px;
      padding: 5px 29px;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
        padding: 5px 20px;
      }
    }
  }
`;

export const FirstBonusMatchCss = styled.section`
  background-image: url("images/first-bonus-match-bg.png");
  /* padding: 190px 0; */
  /* min-height: 100vh; */
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  padding: 50px 0;
  display: flex;
  align-items: center;

  @media only screen and (min-width: 1599px) {
    /* min-height: 1081px; */
  }

  @media only screen and (max-width: 767px) {
    background: none;
  }

  .first-bonus-match-content {
    max-width: 500px;

    @media only screen and (max-width: 991px) {
      max-width: 100%;
      padding: 30px;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 10px;
    }

    @media only screen and (max-width: 767px) {
      /* padding: 20px 15px; */
      padding: 0;
      background: none;
    }

    @media only screen and (max-width: 575px) {
      /* padding: 15px 12px; */
      padding: 0;
    }

    h2 {
      margin-bottom: 20px;

      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    ul {
      list-style: disc;
      padding-left: 15px;
      li {
        &:not(:last-child) {
          margin-bottom: 10px;

          @media only screen and (max-width: 767px) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .btn-wrap {
    justify-content: flex-start;
    margin-top: 32px;

    @media only screen and (max-width: 767px) {
      margin-top: 16px;
    }

    .btn {
      font-size: 16px;
      line-height: 1.75;
      min-width: unset;
      border-width: 1px;
      padding: 5px 29px;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
        padding: 5px 20px;
      }
    }
  }
`;

export const UnilevelComissionCss = styled.section`
  /* background-image: url("images/unilevel-commission-bg_new.jpg"); */
  /* padding: 175px 0; */
  /* min-height: 100vh; */
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  padding: 50px 0;
  display: flex;
  align-items: center;

  @media only screen and (min-width: 1599px) {
    /* min-height: 1081px; */
  }

  .col-6 {
    margin-left: auto;
  }

  .unilevel-commission-content {
    margin-left: 30px;
    max-width: 550px;

    @media only screen and (max-width: 991px) {
      max-width: 100%;
      padding: 30px;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 10px;
      margin-left: 0px;
    }

    @media only screen and (max-width: 767px) {
      /* padding: 20px 15px; */
      padding: 0;
      background: none;
    }

    @media only screen and (max-width: 575px) {
      /* padding: 15px 12px; */
      padding: 0;
    }

    h2 {
      margin-bottom: 20px;

      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    ul {
      list-style: disc;
      padding-left: 15px;
      li {
        &:not(:last-child) {
          margin-bottom: 10px;

          @media only screen and (max-width: 767px) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .btn-wrap {
    justify-content: flex-start;
    margin-top: 32px;

    @media only screen and (max-width: 767px) {
      margin-top: 16px;
    }

    .btn {
      font-size: 16px;
      line-height: 1.75;
      min-width: unset;
      border-width: 1px;
      padding: 5px 29px;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
        padding: 5px 20px;
      }
    }
  }
`;

export const UnilevelComissionMatchCss = styled.section`
  background-image: url("images/unilevel-commission-match-bg.png");
  /* padding: 200px 0; */
  /* min-height: 100vh; */
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  padding: 50px 0;
  display: flex;
  align-items: center;

  @media only screen and (min-width: 1599px) {
    /* min-height: 1081px; */
  }

  @media only screen and (max-width: 767px) {
    background: none;
  }

  .unilevel-commission-match-content {
    max-width: 500px;

    @media only screen and (max-width: 991px) {
      max-width: 100%;
      padding: 30px;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 10px;
    }

    @media only screen and (max-width: 767px) {
      /* padding: 20px 15px; */
      padding: 0;
    }

    @media only screen and (max-width: 575px) {
      /* padding: 15px 12px; */
      padding: 0;
    }

    h2 {
      margin-bottom: 20px;

      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    ul {
      list-style: disc;
      padding-left: 15px;
      li {
        &:not(:last-child) {
          margin-bottom: 10px;

          @media only screen and (max-width: 767px) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .btn-wrap {
    justify-content: flex-start;
    margin-top: 32px;

    @media only screen and (max-width: 767px) {
      margin-top: 16px;
    }

    .btn {
      font-size: 16px;
      line-height: 1.75;
      min-width: unset;
      border-width: 1px;
      padding: 5px 29px;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
        padding: 5px 20px;
      }
    }
  }
`;

// export const BannerTopCss = styled.section`
//   background-image: url("images/unilevel-commission-bg.png");
//   height: 100%;
//   width: 100%;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: right center;
//   padding: 50px 0;
//   display: flex;
//   align-items: center;

//   .col-6 {
//     margin-left: auto;
//   }

//   .unilevel-commission-content {
//     margin-left: 30px;
//     max-width: 550px;

//     @media only screen and (max-width: 991px) {
//       max-width: 100%;
//       padding: 30px;
//       background-color: rgba(255, 255, 255, 0.6);
//       border-radius: 10px;
//       margin-left: 0px;
//     }

//     @media only screen and (max-width: 767px) {
//       padding: 20px 15px;
//     }

//     h2 {
//       margin-bottom: 20px;

//       @media only screen and (max-width: 767px) {
//         margin-bottom: 10px;
//       }
//     }
//   }
// `;



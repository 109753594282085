import React, { useState, useRef, useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import { SignupFormStyle } from './SignupStyle'
// import { SignupFormStyle } from './SignupStyle'
// import { Visibility, VisibilityOff } from "@material-ui/icons";
import { FaInfo } from "react-icons/fa";
import {
    CountryDropdown,
    RegionDropdown,
    CountryRegionData,

} from "react-country-region-selector";
import api from '../../../api/Apis';
// import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import _ from 'lodash';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Tooltip } from '@mui/material';

const SignUpForm = ({
    // setDefaultValue,
    setActiveStep,
    signUpRefferal,
    referralHide,
    ProfileFunction,
    ...props
}) => {

    const {
        signupDefualtValue,
        setUserToken,
        setDefaultValue,
        // ProfileFunction,
        isLoginToken,
        userToken,
        setProfileData,
        setCustomerCheck
    } = props;
    const { correctRefferal, setCorrectRefferal } = signUpRefferal;
    const [passwordShow, setPasswordShow] = useState(false);
    const [stateError, setStateError] = useState();
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [showConfirmpass, setShowConfirmPass] = useState(false);
    const [countryError, setCountryError] = useState();
    const [credential, setCredential] = useState({
        email: "",
        password: "",
    });
    const [refferalCode, setRefferalCode] = useState({
        value: "",
        error: "",
    });
    const [hide, setHide] = useState({ loginLink: false });
    const [emailAlready, setEmailAlready] = useState()
    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const whitelistedCountries = ['CA', 'US', 'AU', 'NZ'];

    useEffect(() => {
        // setshowloader(true);
        // setInnerLoader(true)
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, [])
    function convertToIndianFormat(phoneNumber) {
        const cleanedNumber = phoneNumber?.replace(/\D/g, '');
        const formattedNumber = `${cleanedNumber}`
        return formattedNumber;
    }
    const INITIALVALUES = {
        "first_name": signupDefualtValue.first_name ? signupDefualtValue.first_name : "",
        "last_name": signupDefualtValue.last_name ? signupDefualtValue.last_name : "",
        "email": signupDefualtValue.email ? signupDefualtValue.email : "",
        "address_line1": signupDefualtValue.street_address_1 ? signupDefualtValue.street_address_1 : "",
        "address_line2": signupDefualtValue.street_address_2 ? signupDefualtValue.street_address_2 : "",
        'country': signupDefualtValue.country ? signupDefualtValue.country : "",
        "state": signupDefualtValue.state ? signupDefualtValue.state : "",
        "city": signupDefualtValue.city ? signupDefualtValue.city : "",
        "zipcode": signupDefualtValue.postal_code ? signupDefualtValue.postal_code : "",
        "phone_no": signupDefualtValue.phone_number ? convertToIndianFormat(signupDefualtValue.phone_number) : "",
        "password": signupDefualtValue.password ? signupDefualtValue.password : "",
        "confirm_password": signupDefualtValue.confirm_password ? signupDefualtValue.confirm_password : "",
        "referral_code": signupDefualtValue.referral_code ? signupDefualtValue.referral_code : "",
        "company_name": signupDefualtValue.company_name ? signupDefualtValue.company_name : "",
        "termscondition": true,
        "termscondition1": true,
    }
    const createValidationSchema = (isLoginToken) => {
        const schema = yup.object().shape({
            first_name: yup.string().trim().required('First name is required.').min(3, "Enter minimum 3 character."),
            last_name: yup.string().trim().required('Last name is required.').min(3, "Enter minimum 3 character."),
            email: yup.string().trim().required('Email is required.').email('Please enter valid email.'),
            phone_no: yup.string().typeError('Please enter only number')
                .required('Phone number is required')
                .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Phone number is not valid"),
            address_line1: yup.string().trim().required('This field is required'),
            city: yup.string().trim().required('City is required'),
            zipcode: yup.string().trim().required('Zip Code is required'),
            company_name: yup.string().trim().notRequired(),
            password: isLoginToken ? yup.string().notRequired() : yup.string().trim().required('Password is required.').min(6, "Please enter minimum 6 characters."),
            confirm_password: isLoginToken ? yup.string().notRequired() : yup.string().trim().required('Confirm Password is required.')
                .test('confirm_password', 'Password and confirm password should be same', function (val) {
                    const password = this.resolve(yup.ref('password'));
                    if (val !== password) return false;
                    return true;
                }),
            referral_code: yup.string().trim().required('Referrer Code is required')

        });
        return schema;
    };
    useEffect(() => {

        if (signupDefualtValue?.country) {
            setCountry(signupDefualtValue?.country);
            if (signupDefualtValue?.state) {
                setState(signupDefualtValue?.state);
            }
        }
        if (Object.keys(signupDefualtValue).length > 0) {
            setPasswordVisible(true)
        }
    }, [signupDefualtValue]);


    const handleCountryStateError = () => {

        if (
            country === undefined ||
            (country === "" && countryError === undefined)
        ) {
            setCountryError("Please Select The Country");
        }
        if (state === undefined || (state === "" && stateError === undefined)) {
            setStateError("Please Select The State");
        }
    };
    useEffect(() => {
        const refferal = localStorage.getItem("referral_code")
            ? localStorage.getItem("referral_code")
            : "";
        setRefferalCode({ value: refferal, error: "" });
        if (refferal) {
            const RefferalData = localStorage.getItem("correctRefferal") ? JSON.parse(localStorage.getItem("correctRefferal")) : "";
            setCorrectRefferal(RefferalData);
        }

    }, []);
    async function onSubmit(data) {
        if (hide?.loginLink === true) {
            return
        }
        if (country !== undefined && state !== undefined && country !== "" && state !== "") {
            data.country = country;
            data.state = state;
            data["confirm_password"] = data.password;
            data["parentrefferalPromoter"] = refferalCode.value;

            // data['referral_code'] = subDomain !== ""
            //     ?
            //     subDomain
            //     :
            //     parentrefferalPromoter
            //         ?
            //         parentrefferalPromoter
            //         :
            //         data['referral_code'];

            data["designation"] =
                window.location.pathname === "member" ? window.location.pathname : "customer";
            data["leadid"] = localStorage.getItem("leadid") ? JSON.parse(localStorage.getItem("leadid")) : null;
            props?.setshowloader(true);
            // if (captchaToken === null) {
            // toast.warn("please fill the captcha")
        }



        // if (captchaToken !== null) {
        props?.setInnerLoader(true);
        if (!isLoginToken) {
            await api.signUp(data).then((res) => {
                if (res?.data?.code === 1) {
                    const data_sign = { email: data.email, password: data.password };
                    setCredential({ email: data.email, password: data.password });
                    // api.loginUser(data_sign).then((res) => {
                    //     if (res?.data?.code === 1) {
                    //         setUserToken(res.data.token);
                    //         localStorage.setItem("Token", res.data.token);
                    //         props?.setInnerLoader(false);
                    //         setActiveStep((prvStep) => {
                    //             return prvStep + 1;
                    //         });
                    //     }
                    // });
                    // props?.setshowloader(false);
                    setUserToken(res.data.token);
                    localStorage.setItem("Token", res.data.token);
                    localStorage.setItem("profileDetail", JSON.stringify(data));
                    localStorage.setItem("shippingAddress", res?.data?.shipping_address_id);
                    setDefaultValue(data);
                    // toast.success(res?.data?.message, { duration: 5 })
                    // router.push('/')
                    // setEmailError("");
                    setRefferalCode("");
                    // setParentReferral("");
                    props?.setInnerLoader(false);
                    setActiveStep((prvStep) => {
                        return prvStep + 1;
                    });
                } else {
                    props?.setInnerLoader(false);
                }
                if (res?.data?.code === 0 && res?.data?.message) {
                    props?.setInnerLoader(true);

                    setEmailAlready(res?.data?.message)
                    props?.setInnerLoader(false);
                }
            });
        }

        else {
            await api.updateSignup(data).then((res) => {
                const token = localStorage.getItem("Token")
                if (res?.data?.code === 1) {
                    api.getMyProfileDetails(isLoginToken).then((res) => {
                        if (res?.data?.code === 1) {
                            props?.setInnerLoader(false)
                            localStorage.setItem("profileData", JSON.stringify(res?.data));
                            localStorage.setItem("profileDetail", JSON.stringify(res?.data?.user_data?.user_addresses[0]));
                            localStorage.setItem("shippingAddress", res?.data?.user_data?.user_addresses[0]?.id);
                            setProfileData(res?.data);
                            setCustomerCheck(
                                +res?.data?.user_data?.userdetails?.[0]?.qualified_as_rank
                            );
                            props?.setInnerLoader(false)
                            setActiveStep((prvStep) => {
                                return prvStep + 1;
                            });
                        } else {
                            props?.setInnerLoader(false)
                        }
                    });


                    // ProfileFunction(token);
                    // props?.setInnerLoader(false)

                    // localStorage.setItem("profileDetail", JSON.stringify(data));
                    // localStorage.setItem("shippingAddress", res?.data?.shipping_address_id);
                    // setDefaultValue(data);
                    // toast.success(res?.data?.message, { duration: 5 })
                    // router.push('/')
                    // setEmailError("");
                    setRefferalCode("");
                    // setActiveStep((prvStep) => {
                    //     return prvStep + 1;
                    // });
                    // setParentReferral("");
                } else {
                    props?.setInnerLoader(false);
                }
                if (res?.data?.code === 0 && res?.data?.message) {
                    // setErrors(res?.data?.field, {
                    //     type: res?.data?.field,
                    //     message: res?.data?.message,
                    // });
                    props?.setInnerLoader(true);

                    setEmailAlready(res?.data?.message)
                    props?.setInnerLoader(false);
                }
            });
        }
    }

    function showPassword() {
        setPasswordShow(!passwordShow);
    }
    function handleshowConfirmPassword() {
        setShowConfirmPass(!showConfirmpass);
    }
    async function onBlurChange(e) {
        const email = e.target.value;
        await api
            .CheckUserEmail({ email })
            .then((res) => {
                if (res?.status === 200) {
                    setHide({ loginLink: true });
                }
                if (res?.status === 401) {
                    setHide({ loginLink: false });
                }
            })
            .catch((err) => {
            });
    }

    const handleClick = () => {
        sessionStorage.setItem('redirectAfterLogin', location.pathname);
        navigate('/login');
    };

    return (
        <SignupFormStyle>
            <div className="container">

                <div className="form-wrap">
                    <Formik
                        enableReinitialize
                        initialValues={
                            INITIALVALUES
                        }
                        // validationSchema={schema}
                        validationSchema={createValidationSchema(isLoginToken)}
                        onSubmit={(values) => onSubmit(values)}
                    >
                        {({ values, handleChange, setFieldValue, setValues, handleSubmit, setErrors }) => (
                            <Form>
                                {correctRefferal?.data?.name && !referralHide &&
                                    <div className="main-cstm">
                                        <a>
                                            Enroller: {correctRefferal?.status &&
                                                `${correctRefferal?.data?.name}`}
                                        </a>

                                    </div>
                                }
                                <div className="form-row">
                                    <div className="form-group">

                                        <Field
                                            type="text"
                                            className="form-control"
                                            name="first_name"
                                            id="f_name"
                                            aria-describedby="nameHelp"
                                            placeholder="First Name"

                                        />
                                        <ErrorMessage name={'first_name'} render={(msg) => <span className='error'>{msg}</span>} />

                                    </div>
                                    <div className="form-group">
                                        <Field
                                            type="text"
                                            className="form-control"
                                            name="last_name"
                                            id="l_name"
                                            aria-describedby="nameHelp"
                                            placeholder="Last Name"

                                        />
                                        <ErrorMessage name={'last_name'} render={(msg) => <span className='error'>{msg}</span>} />
                                    </div>
                                    <div className="form-group">

                                        <Field
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            id="email"
                                            aria-describedby="emailnoHelp"
                                            placeholder="Email Address"
                                            disabled={passwordVisible}
                                            onBlur={(e) => onBlurChange(e)}
                                        />
                                        <ErrorMessage name={'email'} render={(msg) => <span className='error'>{msg}</span>} />

                                        {emailAlready !== "" && (
                                            <span className="error">{emailAlready}</span>
                                        )}

                                        {hide?.loginLink && (
                                            <div className="already_exist_customer">
                                                <span className="error">
                                                    You are already registered with this email please
                                                </span>
                                                &nbsp;
                                                <button type='button' className='btn_register' onClick={handleClick}>Login</button>
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group">

                                        <Field
                                            type="text"
                                            className="form-control"
                                            name="phone_no"
                                            id="phone_no"
                                            aria-describedby="emailnoHelp"
                                            placeholder="Phone Number"

                                        />
                                        <ErrorMessage name={'phone_no'} render={(msg) => <span className='error'>{msg}</span>} />
                                    </div>
                                    {!passwordVisible &&
                                        <><div className="form-group">
                                            <Field
                                                type={passwordShow ? "text" : "password"}
                                                name="password"
                                                className="form-control pass-show"
                                                id="password"
                                                placeholder="Password" />
                                            <span className='eye-icon'>
                                                {passwordShow ? (
                                                    <Visibility onClick={() => showPassword()} />
                                                ) : (
                                                    <VisibilityOff onClick={() => showPassword()} />
                                                )}
                                            </span>
                                            <ErrorMessage name={'password'} render={(msg) => <span className='error'>{msg}</span>} />
                                        </div><div className="form-group">

                                                <Field
                                                    type={showConfirmpass ? "text" : "password"}
                                                    className="form-control pass-show"
                                                    name="confirm_password"
                                                    id="exampleFieldPassword3"
                                                    placeholder="Confirm Password" />
                                                <span className='eye-icon'>
                                                    {showConfirmpass ? (
                                                        <Visibility onClick={() => handleshowConfirmPassword()} />
                                                    ) : (
                                                        <VisibilityOff onClick={() => handleshowConfirmPassword()} />
                                                    )}
                                                </span>

                                                <ErrorMessage name={'confirm_password'} render={(msg) => <span className='error'>{msg}</span>} />
                                            </div></>
                                    }
                                    <div className="form-group select-drop">
                                        <CountryDropdown
                                            value={country}
                                            defaultOptionLabel="Country"
                                            name="country"
                                            priorityOptions={['US', 'CA']}
                                            whitelist={whitelistedCountries}
                                            valueType="short"
                                            onChange={(val) => setCountry(val)}
                                            className='form-control'
                                        />
                                        {/* {country === undefined && <span className="error">{countryError}</span>} */}
                                        {country === undefined ||
                                            (country === "" && (
                                                <span className="error">{countryError}</span>
                                            ))}
                                    </div>
                                    <div className="form-group select-drop">
                                        <RegionDropdown
                                            country={country || "USA"}
                                            disabled={!country}
                                            value={state}
                                            name="state"
                                            valueType="full"
                                            defaultOptionLabel="State"
                                            countryValueType="short"
                                            onChange={(val) => setState(val)}

                                            className='form-control'
                                        />
                                        {/* {state === undefined && <span className="error">{stateError}</span>} */}
                                        {state === undefined ||
                                            (state === "" && <span className="error">{stateError}</span>)}

                                    </div>
                                    <div className="form-group">
                                        <Field
                                            type="text"
                                            name="company_name"
                                            className="form-control"
                                            id="company_name"
                                            placeholder="Company Name (optional)"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <Field
                                            type="text"
                                            name="address_line1"
                                            className="form-control"
                                            id="address1"
                                            placeholder="Address Line"

                                        />
                                        <ErrorMessage name={'address_line1'} render={(msg) => <span className='error'>{msg}</span>} />
                                    </div>
                                    <div className="form-group">

                                        <Field
                                            type="text"
                                            name="address_line2"
                                            className="form-control"
                                            id="address2"
                                            placeholder="Address 2 (optional)"
                                        />
                                    </div>
                                    <div className="form-group">

                                        <Field
                                            type="text"
                                            name="city"
                                            className="form-control"
                                            id="city"
                                            placeholder="City"

                                        />
                                        <ErrorMessage name={'city'} render={(msg) => <span className='error'>{msg}</span>} />
                                    </div>
                                    <div className="form-group">

                                        <Field
                                            type="text"
                                            name="zipcode"
                                            className="form-control"
                                            id="zipcode"
                                            placeholder="Zip Code"

                                        />
                                        <ErrorMessage name={'zipcode'} render={(msg) => <span className='error'>{msg}</span>} />
                                    </div>
                                    <div className="form-group">
                                        <div className="cstm-referral">

                                            <Field
                                                type="text"
                                                name="referral_code"
                                                id="referral_code"
                                                aria-describedby="emailnoHelp"

                                                placeholder="Set Your Referral Code"
                                            />
                                            <span className='extra-content'>
                                                .sharekaire.com
                                                {/* <span className="info-icon">
                                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 192 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M20 424.229h20V279.771H20c-11.046 0-20-8.954-20-20V212c0-11.046 8.954-20 20-20h112c11.046 0 20 8.954 20 20v212.229h20c11.046 0 20 8.954 20 20V492c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20v-47.771c0-11.046 8.954-20 20-20zM96 0C56.235 0 24 32.235 24 72s32.235 72 72 72 72-32.235 72-72S135.764 0 96 0z"></path></svg>
                                                </span> */}
                                                <Tooltip
                                                    classes={{ popper: "distributer_tooltip" }}
                                                    title={
                                                        <>
                                                            <div className='tooltip-wrap'>
                                                                <h4>What is a Referral Code?</h4>
                                                                <p>A Referral Code is a unique identifier you share with someone you refer. It helps track your referrals as customers or members.</p>
                                                            </div>
                                                            <div className='tooltip-wrap'>
                                                                <h4>Referral Link</h4>
                                                                <p>A Referral URL Link combines a Referral Code with a website, like <a href='https://code.shopkaire.com' target='_blank'>code.shopkaire.com</a> or <a href='https://code.sharekaire.com' target='_blank'>code.sharekaire.com</a>. Share it with potential customers or members to link them to you.</p>
                                                            </div>
                                                        </>
                                                    }
                                                >
                                                    <div>
                                                        <FaInfo />
                                                    </div>
                                                </Tooltip>

                                            </span>
                                        </div>
                                        <ErrorMessage name={'referral_code'} render={(msg) => <span className='error'>{msg}</span>} />
                                    </div>
                                    <Field
                                        type="hidden"
                                        className="form-control"
                                        name="designation"
                                        id="designation"
                                        defaultValue={
                                            window.location.pathname === "member" ? window.location.pathname : "customer"
                                        }

                                        disabled={true}
                                    />
                                    <div className="form-group w-100">
                                        <p>
                                            We would like to send you occasional news and information. Please select if and how you would like to hear from us.
                                        </p>
                                    </div>
                                    <div className="form-group w-100">
                                        <div className="cstm-checkbox-wrap">
                                            <label>
                                                <Field
                                                    type="checkbox"
                                                    className="cstm-checkbox-input"
                                                    id="Terms-Condition"
                                                    name="termscondition"
                                                    checked={values.termscondition}
                                                />
                                                <span className='cstm-checkbox'>
                                                    I would like to receive communications by mobile (text message)
                                                </span>
                                            </label>
                                        </div>
                                        <div className="cstm-checkbox-wrap">
                                            <label>
                                                <Field
                                                    type="checkbox"
                                                    id="Terms-Condition1"
                                                    name="termscondition1"
                                                    className='cstm-checkbox-input'
                                                    checked={values.termscondition1}
                                                />
                                                <span className='cstm-checkbox'>
                                                    I would like to receive communications by email
                                                </span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group w-100 form-btn-wrap">
                                        <button type="submit" className='btn btn-2'
                                            onClick={() => handleCountryStateError()}
                                        >Next</button>
                                    </div>

                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </SignupFormStyle >
    )
}

export default SignUpForm

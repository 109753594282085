import styled from 'styled-components';
import { createGlobalStyle } from "styled-components";

export const DBBannerCss = styled.div`
    background: ${props => props.bgImage ? `url(${props?.bgImage})` : "none"};
    width: 100%;
    min-height:100vh;
    background-size: cover;
    background-position: 100% 27%;
  
    h1{
        position: absolute;
        bottom: 100px;
        left: 50%;  
        font-size: 100px;
        color:  var(--cmn-white);  
        transform: translateX(-50%);
        width: 100%;
        margin-bottom: 0;
        font-family: var(--common-font);
        z-index: 99;
        @media (max-width: 991px){
            font-size: 60px;
            bottom: 60px;
        }
        @media (max-width: 767px){
            font-size: 30px;
            bottom: 60px;
        }
    }
    /* iframe {
        max-width: 480px;
        height: 350px;
        min-width: 610px; 
        width: 100%;
        overflow: hidden;
        margin-bottom: -3px;
    } */
    .become {
        position: absolute;
        left: 10%;
        top: 50%;
        padding: 10px 20px;
        background: rgb(30 69 116 / 70%);
        span {
        font-size: 50px;
        color:  var(--cmn-white);
    }
    }
     iframe {
        max-width: 130vw;
        height: 200%;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 99;
        width: 100%;
        transform: translate(-50%,-50%);  
}
`;



export const SectionOneCss = styled.div`
   
    display:flex;  
    align-items: center; 
    background: var(--grey);
    padding: 40px 0 0;
   
    .secondBlock_sectionOne {
        img {
            width:100%
        }
    } 

    .firstBlock_sectionOne { 
    width: 100%;
    .first-block { 
        text-align: center;
        max-width: 1220px;
        margin: 0 auto;
        h4 {
            font-size: 30px;
            @media (max-width: 767px){
                font-size: 24px
            }
          }
        font-family: var(--common-font);
        p {
            font-size: 17px;
        }
    } 
}

  .secondBlock_sectionOne {
    background: url("/images/sectionOneImage.png") no-repeat;
    width: 100%;
    display: block;
    line-height: 1;
    max-width: 600px;background-size: contain;
}

.secondBlock_sectionOne  iframe {
    max-width: 480px;
    height: 340px;
    min-width: 600px; 
    width: 100%;
    overflow: hidden;
    margin-bottom: -3px;
}

.first-block { 
            ul {
            padding: 0;
            list-style: none;
                li span {
                position: relative;
                padding-left: 17px;
                    :before {
                    content: "";
                    width: 6px;
                    height: 6px;
                    background:  var(--cmn-black);
                    position: absolute;
                    left: 0;
                    top: 4px;
                    border-radius: 50%;
                }
            }
        }
    }
      
`;

export const SectionTwoCss = styled.div`
    position: relative;    
        padding-top: 240px; 
        @media (max-width: 767px){
            padding-top: 200px;
        }
    ::before { 
        background: rgb(237,237,237);
        background: -moz-linear-gradient(175deg, rgba(237,237,237,1) 50%, rgba(255,255,255,0) 50%);
        background: -webkit-linear-gradient(175deg, rgba(237,237,237,1) 50%, rgba(255,255,255,0) 50%);
        background: linear-gradient(175deg, rgba(237,237,237,1) 50%, rgba(255,255,255,0) 50%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ededed",endColorstr="#ffffff",GradientType=1);
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 180px;
        z-index: 99;
        width: 100%; 
    }
    .section-two{
            @media (max-width: 991px){
                .row{
                    .col-md-4{
                        width: 100%;
                        &:nth-child(2){
                            margin: 40px 0;
                        }
                    }
                }
            }
        }
 
    .main-bx {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
        border-radius: 20px;
        padding: 20px;
        font-family: var(--common-font); 
        position: relative; 
        @media (max-width: 1280px){
            h2{
                font-size: 30px;
            } 
        }
        @media (max-width: 767px){
            h2{
                font-size:  26px;
            }
           
        }
        
          p, h3 {
            font-size: 17px;
            margin-bottom: 0;
          } 
            ul {
            padding: 0;
            list-style: none;
                li span {
                position: relative;
                padding-left: 17px;
                    :before {
                    content: "";
                    width: 6px;
                    height: 6px;
                    background:  var(--cmn-black);
                    position: absolute;
                    left: 0;
                    top: 4px;
                    border-radius: 50%;
                }
            }
        } 
       
    }
    .btn-primary.btn{
            margin-top: 30px;
        }
    .main-two {
        position: absolute;
        /* background: url(images/graydiant.jpg); */
        max-width: 600px;
        min-height: 300px;
        left: 16%;
        bottom: -190px;
        padding: 30px;
        background-size: cover;
        z-index: 99;
        h2 {
            font-size: 25px !important;
            font-weight: 500;
            font-weight: 600;
            margin-bottom: 20px;
            text-align: left;
        } 
    }

 
    .first_block_sectionTwo {
        text-align: left;
        font-size: 18px;
    }
    .animated-gradients  {
        height: 100%;
        overflow: hidden;
        width: 100%;
        z-index: -1;
        top: 0;
        position: absolute ;
        left: 0;
    }
    .animated-gradients svg.animated-gradients__svg {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 0;
    }

`;

export const SectionThreeCss = styled.div`
/*     
    background: ${props => `url(${props.bgImg})`};
    background-size: cover; */
    /* min-height: 860px;  */
    /* .react-parallax:after {
        content: "";
        position: absolute;
        clip-path: polygon(36% 100%, 100% 89%, 100% 100%, 0 100%, 0 87%);  
        width: 100%;
        height: 100%;
        left: 0;
        background: #fff;
        z-index: 0;
        bottom: 0px;
    }
   */
    margin: 50px 0;
     img{
         width: 100%;
     }
    .firstContainer_sectionThird {
        margin-bottom: 30px;
    } 
 
    
`;

export const SectionFourCss = styled.div` 
    .sec-four {
        position: relative;
         z-index: 9;
        &:after {
        content: "";
        position: absolute;
        height: 100%;
        left: 50%;
        width: 100%; 
        /* background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 10%, rgba(255,255,255,0.01) 100%); */
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,1) 38%, rgba(255,255,255,1) 62%, rgba(255,255,255,0) 100%); 
        top: 0;
        z-index: -1; 
        transform: translateX(-50%);
    }
    
     h4 {
        font-size: 24px;
        font-family: var(--common-font);
        margin-bottom: 25px;
        @media (max-width: 767px){
            font-size: 22px;
        }
        }    
     p {
        font-size: 17px;
        max-width: 910px;
        margin: 30px auto 20px;
        font-family: var(--common-font);
        } 
       @media (max-width: 991px){
        br {
  display: none;
}
       }
    }  

`;

export const SectionFiveCss = styled.div`
    background: ${props => `url(${props.BgImage})`};
    .fifth-top {
        background: rgb(68 107 147 / 60%);
        padding: 20px 0px;
        margin: 0 0 100px;
        @media (max-width: 991px){
            margin: 0 0 60px;
        }
           @media (max-width: 767px){
            margin: 0 0 10px;

        }
    } 

.fifth-sec {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 1100px;
            width: 100%;
            margin: 0 auto;
            gap: 30px;
            padding: 0 15px;
            @media (max-width:767px){
                flex-direction: column;
            }
            .section_fiveText {
            max-width: calc(100% - 300px);
            flex: 100%;
            text-align: left;  
            color:  var(--cmn-white);
            font-weight:400;
            @media (max-width:767px){
                max-width: 100%;
            }
            h3 {
            font-size: 49px;
            line-height: 58px;
            font-family: var(--common-font);
            text-transform: uppercase;
            @media (max-width: 1280px){
                font-size: 38px;
                line-height: normal;
            }
            @media (max-width: 991px){
                font-size: 26px;
            }
            @media (max-width: 767px){
            font-size: 24px;
                line-height: 1.5;
                }
        }
        p{
            font-size: 19px;
            font-family: var(--common-font);  
            @media (max-width: 991px){
                font-size: 18px;
            }  
        }
    }
.section_fiveImage {
  max-width: 250px;
}
} 
`;

export const CommonDashboardStyle = createGlobalStyle`
    .last-inner-sec {
        position: relative;
        overflow: hidden;
        .section-banner iframe{
        width: 250vw;
        height: 140%;
        position: absolute;
        left: 50%; 
        top: 50%;
        transform: translate(-50% , -50%);
        z-index: -1; 
        @media (max-width: 991px){
            width: 300vw;
        }
        @media (max-width: 767px){
            width: 740vw;
        }
    }

    }
 
    .last-inner-sec div +div+button{
        margin-top: 60px;
    }
    .secondBlock_sectionOne { 
      margin: 60px auto; 
      position: relative;
       padding-bottom: 54% ;
                  iframe {
                    height: 100%;
                    position: absolute;
                    left: 50%;
                    width: 100vw;
                    top: 50%;
                    transform: translate(-50% , -50%);
                    }
        }
  
    

`;
export const FirstSectionCss = styled.div`
    padding: 40px 0; 

    .cstm-row {
        align-items: center;

        .left-sec, .right-sec {
            width:calc(100%/2);
            padding: 0 15px;

            @media only screen and (max-width: 991px) {
                width:calc(100%);
            }
        }

        .left-sec {
            text-align: left;
            
            @media only screen and (max-width: 991px) {
                order: 2;
                text-align: center;
            }

            h1 {
                max-width: 461px;
                margin-bottom: 30px;

                @media only screen and (max-width: 1399px) {
                    max-width: 430px;
                    margin-bottom: 20px;
                }

                @media only screen and (max-width: 991px) {
                    max-width: unset;
                }
            }
            
            p {
                font-size: 22px;
                line-height: 1.3;

                @media only screen and (max-width: 1399px) {
                    font-size: 20px;
                }

                @media only screen and (max-width: 1199px) {
                    font-size: 17px;
                }
            }
        }

        .right-sec {
            @media only screen and (max-width: 991px) {
                order: 1;
                margin-bottom: 24px;
            }
            figure {
                margin: 0 auto;
                width: 100%;
                display: block;
                max-width: 410px;

                img {
                    width: 100%;
                }
            }
        }
    }
  
`
export const SecondSectionCss = styled.div`
    border-top: 2px solid #c2c2c2;
    text-align: left;
    padding: 60px 0;
    background: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(248,248,248,1) 100%);

    @media only screen and (max-width: 1199px) {
        padding: 50px 0;
    }
    
    .cstm-row {
        .left-img-sec, .right-img-sec {
            width:calc(100%/2);
            padding: 0 15px;
            display: flex;
            flex-direction: column;
            gap: 60px;
            
            @media only screen and (max-width: 991px) {
                width:calc(100%);
                gap: 30px;                
            }

            figure {
                margin: 0;
                width: 100%;
                display: block;
                
                img {
                    width: 100%;
                    display: block;
                }
            }

            .text-content {
                @media only screen and (max-width: 991px) {
                    text-align: center;
                }
            }
        }

        .left-img-sec {
            @media only screen and (max-width: 991px) {
                margin-bottom: 50px;
            }
        }

        .right-img-sec {
            .text-content {
                @media only screen and (max-width: 991px) {
                    order: 2;
                }
            }

            figure {
                @media only screen and (max-width: 991px) {
                    order: 1;
                }
            }
        }
    }
`
export const ThirdSectionCss = styled.div`
    text-align: center;
    padding: 40px 0 80px;
    background: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(248,248,248,1) 100%);

    @media only screen and (max-width: 1199px) {
        padding: 40px 0 70px;
    }

    @media only screen and (max-width: 767px) {
        padding: 40px 0 50px;
    }

    h2 {
        margin-bottom: 35px;
        
        @media only screen and (max-width: 1399px) {
            margin-bottom: 30px;            
        }

        @media only screen and (max-width: 1199px) {
            margin-bottom: 20px;            
        }
    }

    .easy-wrap {
        text-align: left;
        border-radius: 10px;
        overflow: hidden;

        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
        
        h4 {
            padding: 20px;
            display: flex;
            line-height: 1.2;
            color:  var(--cmn-white);
            font-weight: 500;
            margin-bottom: 0px;

            @media only screen and (max-width: 991px) {
                flex-direction: column;
                align-items: center;
            }
            
            @media only screen and (max-width: 991px) {
                padding: 15px;
            }
            
            span {
                line-height: 0.8;
                margin-right: 15px;
                min-width: 90px;
                font-weight: 300;

                @media only screen and (max-width:1399px) {
                    margin-right: 12px;
                    min-width: 70px;
                }

                @media only screen and (max-width: 991px) { 
                    margin-right: 0px;
                    min-width: unset;
                    margin-bottom: 5px;
                }
            }
        }

        figure {
            display: block;
            margin: 0;
            height: 272px;

            @media only screen and (max-width: 991px) {
                height: auto;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

    }

    .btn-wrap {
        margin-top: 60px;
        
        @media only screen and (max-width: 991px) {
            margin-top: 0px;            
        }
    }

    .cstm-row {
        margin: 0 -8px;
    }

    .col-4 {
        padding: 0 8px;
        width: calc(100%/3);

        @media only screen and (max-width: 991px) {
            width: calc(100%);
        }
    }

    .bg-1 {
        background: linear-gradient(90deg, rgba(2,54,108,1) 0%, rgba(71,109,182,1) 50%);
    }

    .bg-2 {
        background: linear-gradient(90deg, rgba(76,113,186,1) 0%, rgba(119,146,230,1) 50%, rgba(144,141,209,1) 100%);
    }

    .bg-3 {
        background: linear-gradient(90deg, rgba(147,140,206,1) 0%, rgba(205,128,156,1) 50%);
    }
  
`
export const FourthSectionCss = styled.div`
    text-align: center;
    padding: 40px 0 152px;
    background: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(248,248,248,1) 100%);
    
    @media only screen and (max-width: 1399px) {
        padding: 40px 0 120px;
    }

    @media only screen and (max-width: 1199px) {
        padding: 40px 0 100px;
    }

    @media only screen and (max-width: 1199px) {
        padding: 40px 0 80px;
    }

    @media only screen and (max-width: 1199px) {
        padding: 40px 0 50px;
    }

    .col-12 {
        padding: 0 15px;
    }

    .reward-plan {
        h2 {
            margin-bottom: 24px;
        }

        p {
            max-width: 950px;
            margin-left: auto;
            margin-right: auto;
        }

        h6 {
            margin-bottom: 0px;
        }

        figure {
            margin: 50px 0 60px;

            @media only screen and (max-width: 991px) {
                margin: 30px 0 40px;
            }

            @media only screen and (max-width: 991px) {
                margin: 20px 0 30px;
            }
        }
    }
  
`
import React from "react";
import styled from "styled-components";

export const SmartshipStyle = styled.div`
  background-color: var(--signup-bg);
`;

export const SmartshipBannerStyle = styled.section`
  background-image: url("images/smartship.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0 375px;

  .inner-logo {
    max-width: 280px;
    display: block;
    margin: 0 0 70px;

    a {
      display: block;
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  h3 {
    margin-bottom: 4px;
    color: var(--cmn-white);
  }

  h5 {
    color: var(--cmn-white);
  }

  ol {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    li {
      &.active {
        a {
          color: var(--cmn-white);
        }
      }
      a {
        display: block;
        color: var(--grey-text);
        font-weight: 300;
        font-size: 19px;
        line-height: 1.2;

        span {
          font-weight: 600;
        }
      }
    }
  }
`;

export const SmartshipPackStyle = styled.section`
  margin-top: -340px;
  padding-bottom: 155px;
  position: relative;
  z-index: 2;

  @media only screen and (max-width: 1399px) {
    margin-top: -275px;
    padding-bottom: 100px;
  }

  @media only screen and (max-width: 767px) {
    margin-top: -200px;
    padding-bottom: 70px;
  }

  .cmn-packs-wrap {
    padding: 40px 60px;
    background-color: var(--cmn-white);
    border: 1px solid var(--form-border);
    .success_msg {
      display:block;
      text-align: center;
      background: linear-gradient(90deg, rgba(2, 55, 109, 1) 0%, rgba(94, 136, 236, 1) 50%, rgba(209, 137, 168, 1) 100%);
      color: #fff;
      padding: 10px 20px;
      margin-top: 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.2;
      text-transform: uppercase;
    }
    @media only screen and (max-width: 1199px) {
      padding: 50px;
    }

    @media only screen and (max-width: 991px) {
      padding: 40px;
    }

    @media only screen and (max-width: 767px) {
      padding: 30px;
    }

    @media only screen and (max-width: 575px) {
      padding: 20px 15px;
    }

    .cmn-packs-heading {
      text-align: center;
      margin-bottom: 20px;

      h3 {
        margin: 0;
      }
    }

    .cmn-packs-outer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 24px;
      margin-bottom: 20px;
      color: var(--cmn-black);

      .cmn-packs {
        width: calc(100% / 2 - 12px);
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #000;
        border-radius: 20px;
        padding: 24px;

        @media only screen and (max-width: 991px) {
          width: 100%;
          padding: 20px 15px;
        }

        &.active-pack {
          background-color: var(--active-pack);

          .cmn-pack-content {
            .btn {
              background-color: var(--cmn-blue);
              color: var(--cmn-white);
            }
          }
        }

        figure {
          width: 222px;
          height: 200px;
          margin: 0 10px 0 0;

          @media only screen and (max-width: 1199px) {
            width: 100%;
            margin: 0 0 10px;
          }

          @media only screen and (max-width: 991px) {
            height: auto;
            max-height: 200px;
          }
        }

        .cmn-pack-content {
          width: calc(100% - 232px);
          text-align: center;

          @media only screen and (max-width: 1199px) {
            width: 100%;
          }

          .edit-del {
            justify-content: center;
            gap: 10px;

            .btn-primary-btn {
              font-size: 14px;
              color: var(--cmn-blue);
              display: flex;
              align-items: center;
              gap: 5px;

              &.dlt-btn {
                color:  #ff0000;
              }

              svg {
                height: 14px;
                width: 14px;
                margin-top: -2px;
              }
            }
          }

          p {
            font-size: 14px;
            margin-bottom: 11px;
          }

          h5 {
            margin-bottom: 5px;
            line-height: 1.1;
          }

          h6 {
            margin-bottom: 14px;
          }

          .btn {
            font-size: 15px;
            line-height: 1.4;
            min-width: unset;
            border-width: 1px;
            padding: 5px 29px;
            min-width: 180px;
          }
        }
      }
    }
  }

  .smartship-products-wrap {
    padding-top: 34px;
    &>p {
      /* max-width: 670px; */
      margin: 0 auto 24px;
      /* text-align: center; */
    }
    .smartship-products-outer {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -20px;

      .smartship-products {
        width: calc(100% / 4);
        padding: 20px;

        @media only screen and (max-width: 1199px) {
          width: calc(100% / 2);
        }

        @media only screen and (max-width: 767px) {
          width: 100%;
        }

        figure {
          width: 100%;
          height: 216px;
          margin-bottom: 5px;
        }

        .smartship-product-details {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: calc(100% - 216px);
          
          .smartship-product-heading {
            text-align: center;
            margin-bottom: 6px;

            h6 {
              font-weight: 400;
              margin: 0;
              text-transform: uppercase;
              font-size: 20px;
            }

            svg {
              height: 24px;
              width: 24px;
              margin: auto;
              fill: var(--cmn-blue);
            }
          }

          .smartship-product-pricing {
            .form-btn-wrap {
              margin-top: 20px;
              text-align: center;
              .btn {
                font-size: 15px;
                line-height: 1.4;
                min-width: unset;
                border-width: 1px;
                padding: 6px 29px;
                &[disabled] {
                background-color: #ddd;
                border-color: #ddd;
                color: #fff;
            }
              }
            }
            .form-group {
              border-bottom: 1px solid var(--continue-text);

              &:first-child {
                border-top: 1px solid var(--continue-text);
              }

              &.one-time-order {
                background-color: var(--signup-bg);
              }

              label {
                position: relative;
                display: flex;
                padding: 7px 5px;
                min-height: 68px;
                cursor: pointer;

                input[type="radio"] {
                  position: absolute;
                  opacity: 0;
                  visibility: hidden;

                  &:checked {
                    & ~ .cst-smartship-product {
                      &::before {
                        border: 1px solid var(--cstm-radio);
                      }

                      &:after {
                        opacity: 1;
                        transform: translateY(-50%) scale(1);
                      }
                    }
                  }
                }
                .cst-smartship-product {
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 20px;
                  font-weight: 700;
                  padding-left: 25px;
                  font-size: 15px;

                  &::before {
                    content: "";
                    height: 13px;
                    width: 13px;
                    border: 1px solid var(--cmn-black);
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    border-radius: 100%;
                    transition: all ease-in-out 0.3s;
                  }
                  &::after {
                    content: "";
                    height: 7px;
                    width: 7px;
                    background-color: var(--cstm-radio);
                    position: absolute;
                    top: 50%;
                    left: 3px;
                    transform: translateY(-50%) scale(0);
                    border-radius: 100%;
                    transition: all ease-in-out 0.3s;
                    opacity: 0;
                  }

                  span {
                    display: block;
                  }

                  .order-type {
                    white-space: nowrap;

                    small {
                      font-size: 14px;
                      line-height: 1.2;
                      font-weight: 400;
                      display: block;
                    }
                  }
                }
              }
            }

            .second_section {
              .custom-radio {
                display: flex;
                align-items: center;
                justify-content: space-between; 
                gap: 20px;
                font-size: 15px;
                line-height: 1.5;
                padding: 7px 5px 7px 30px;
                min-height: 68px;
                position: relative;
                border-bottom: 1px solid var(--continue-text);

                &:not(:last-child) {
                  border-top: 1px solid var(--continue-text);
                }

                .allproduct_autoship {
                    white-space: nowrap;
                }

                .radio_section {
                  position: absolute;
                  top: 50%;
                  left: 5px;
                  transform: translateY(-50%);

                  .MuiButtonBase-root {
                    padding: 0;

                    svg {
                      font-size: 16px;
                    }
                  }
                }
                .title_section {
                  .order {
                    white-space: nowrap;
                    margin: 0;
                    font-size: 15px;
                    font-weight: 600;
                  }
                  
                  p {
                    font-size: 14px;
                    line-height: 1.2;
                    font-weight: 400;
                    display: block;
                  }
                }
                .allproduct_normal {

                }
              }
            }
          }
        }
      }
    }
  }

  .continue-btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 36px;
    padding-top: 60px;
    margin-bottom: 10px;

    @media only screen and (max-width: 1199px) {
      padding-top: 40px;
      gap: 30px;
    }

    @media only screen and (max-width: 767px) {
      padding-top: 20px;
      gap: 20px;
    }

    @media only screen and (max-width: 575px) {
      gap: 15px;
    }

    .btn {
      font-size: 15px;
      line-height: 1.4;
      min-width: 171px;
      border-width: 1px;
      padding: 5px 29px;
      text-transform: uppercase;
      
      @media only screen and (max-width: 1199px) {
        min-width: 151px;
      }
      
      @media only screen and (max-width: 767px) {
        min-width: 100px;
        padding: 5px 20px;
      }
    }
  }
`;

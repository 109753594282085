import React from "react";
import styled from "styled-components";

export const ReviewStyle = styled.div`
  background-color: var(--signup-bg);
`;

export const ReviewBannerStyle = styled.section`
  background-image: url("images/review.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0 375px;

  .inner-logo {
    max-width: 280px;
    display: block;
    margin: 0 0 70px;

    a {
      display: block;
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  h3 {
    margin-bottom: 4px;
    color: var(--cmn-white);
  }

  h5 {
    color: var(--cmn-white);
  }

  ol {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    li {
      &.active {
        a {
          color: var(--cmn-white);
        }
      }
      a {
        display: block;
        color: var(--grey-text);
        font-weight: 300;
        font-size: 19px;
        line-height: 1.2;

        span {
          font-weight: 600;
        }
      }
    }
  }
`;

export const ReviewPackStyle = styled.section`
  margin-top: -340px;
  padding-bottom: 155px;
  position: relative;
  z-index: 2;
  
  @media only screen and (max-width: 1399px) {
    margin-top: -275px;
    padding-bottom: 100px;
  }

  @media only screen and (max-width: 767px) {
    margin-top: -200px;
    padding-bottom: 70px;
  }

  .autoship-method-wrap {
    margin-bottom: 50px; 
    
    .cmn-autoship-packs-heading {
      text-align: center;
      margin-bottom: 20px;
    }

    .autoship-payment-table {
      width: 100%;
      padding: 27px;
      border: 1px solid var(--form-border);
      border-radius: 20px;

      @media only screen and (max-width: 1199px) {
        padding: 20px 20px 27px;
      }

      @media only screen and (max-width: 991px) {
        width: 100%;
      }

      @media only screen and (max-width: 767px) {
        padding: 15px 15px 20px;
      }

      table {
        width: 100%;

        tr {
          @media only screen and (max-width: 767px) {
            display: block;
          }

          &:not(:last-child) {
            border-bottom: 1px solid var(--form-border);

            @media only screen and (max-width: 767px) {
              margin-bottom: 10px;
              padding-bottom: 5px;
            }
          }
          td {
            color: var(--cmn-blue);
            /* white-space: nowrap; */
            padding: 15px 0;
            font-size: 16px;
            vertical-align: top;

            @media only screen and (max-width: 767px) {
              display: block;
              padding: 0;
            }

            &:last-child {
              text-align: right;
              color: var(--cmn-black);

              @media only screen and (max-width: 767px) {
                text-align: left;
                margin-top: 3px;
              }
            }

            &.discount-wrap {
              &>span,&>div {
                display: inline-block;
              }
            }

            &>span {
              display: block;
            }

            .radio_option {
              display: block;

              .MuiFormControlLabel-root {
                margin: 0;

                .MuiButtonBase-root {
                  padding: 0;
                  margin-right: 2px;

                  svg {
                    font-size: 16px;
                  }
                }

                .MuiTypography-root {
                  p {
                    margin: 0;
                    font-size: 16px;
                    color: var(--cmn-black);
                    line-height: 1.5;
                  }
                }
              }
            }

            .table-radio {
              display: block;

              input {
                margin-right: 3px;
              }
            }
          }
        }
      }
    }
  }

  .price-beforediscount>div {
    text-decoration: line-through;
    color: rgb(255, 0, 0);
  }

  .cmn-packs-wrap {
    padding: 50px 70px;
    background-color: var(--cmn-white);
    border: 1px solid var(--form-border);

    @media only screen and (max-width: 1199px) {
      padding: 50px;
    }

    @media only screen and (max-width: 991px) {
      padding: 40px;
    }

    @media only screen and (max-width: 767px) {
      padding: 30px;
    }

    @media only screen and (max-width: 575px) {
      padding: 20px 15px;
    }

    form {
      .error {
        font-size: 14px;
        /* margin-top: 5px; */
        margin-top: 2px;
        display: block;
        color: #ff0000;
      }
    }

    .detailDisplay_section {
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
      margin-bottom: 50px;

      @media only screen and (max-width: 1199px) {
        gap: 30px;
      }

      .detailInner_section,
      .biillingForm_Section {
        width: calc(100% / 2 - calc(40px / 2));
        padding: 27px 27px 50px;
        border: 1px solid var(--form-border);
        border-radius: 20px;

        @media only screen and (max-width: 1199px) {
          width: calc(100% / 2 - calc(30px / 2));
          padding: 20px 20px 27px;
        }

        @media only screen and (max-width: 991px) {
          width: 100%;
        }

        @media only screen and (max-width: 767px) {
          padding: 15px 15px 20px;
        }

        h3 {
          font-size: 17px;
          line-height: 1.5;
          margin-bottom: 16px;
          font-weight: 500;
          color: var(--cmn-blue);
          width: 100%;
        }
      }
      .biillingForm_Section {
        .check_data {
          margin-bottom: 16px;
        }

        .biillingForm_wrap {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;

          .field {
            width: calc(100% / 2 - calc(20px / 2));

            @media only screen and (max-width: 1199px) {
              width: 100%;
            }

            &.select-drop {
              position: relative;
              &::after {
                content: "";
                width: 8px;
                height: 8px;
                border: 2px solid;
                position: absolute;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg);
                right: 15px;
                top: 15px;
                pointer-events: none;
              }

              .form-control {
                padding-right: 30px;
              }
            }

            .text_field {
              input {
                border: 1px solid var(--form-border);
                border-radius: 50px;
                padding: 9px 12px;
                font-size: 14px;
                line-height: 1.4;
                box-shadow: none;
                outline: none;
                width: 100%;
                display: block;
              }
            }
          }
        }
      }

      .detailInner_section {
        table {
          table-layout: fixed;
          width: 100%;

          tr {
            @media only screen and (max-width: 767px) {
              display: block;
            }

            &:not(:last-child) {
              border-bottom: 1px solid var(--form-border);

              @media only screen and (max-width: 767px) {
                margin-bottom: 10px;
                padding-bottom: 5px;
              }
            }

            td {
              padding: 8px 0;
              font-size: 16px;
              line-height: 1.45;
              font-weight: 400;
              color: var(--cmn-blue);
              word-break: break-word;

              @media only screen and (max-width: 767px) {
                display: block;
                padding: 0;
              }

              &:last-child {
                color: var(--cmn-black);

                @media only screen and (max-width: 767px) {
                  margin-top: 3px;
                }
              }
            }
          }
        }
      }
    }

    .form-control {
      border: 1px solid var(--form-border);
      border-radius: 50px;
      padding: 9px 12px;
      font-size: 14px;
      line-height: 1.4;
      box-shadow: none;
      outline: none;
      color: var(--cmn-black);

      &::placeholder {
        color: var(--cmn-black);
      }
    }

    .product-table-wrap {
      max-width: 810px;
      margin: 0 auto 100px;

      

      table {
        width: 100%;

        thead {
          background-color: var(--active-pack);

          tr {
            th {
              color: var(--cmn-black);
              padding: 7px 18px;
              font-size: 14px;
              line-height: 1.2;
              font-weight: 500;
              text-align: center;

              &:first-child {
                text-align: left;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              color: var(--cmn-blue);
              padding: 7px 18px;
              font-size: 14px;
              line-height: 1.2;
              font-weight: 400;
              text-align: center;

              &:first-child {
                text-align: left;
              }

              .product-price {
                span {
                  display: block;
                }
              }

              .product-table-img-wrap {
                display: flex;
                align-items: center;

                span {
                  width: calc(100% - 100px);
                }

                .product-table-img {
                  height: 100px;
                  width: 100px;
                  margin-right: 10px;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }
              }

              .product-icons {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;

                a,
                button {
                  display: block;
                  padding: 0;
                  border: none;
                  background-color: transparent;
                }
                svg {
                  font-size: 16px;
                }
                .delete {
                  svg {
                    fill: #ff0000;
                  }
                }
              }
            }
          }
        }
      }
    }

    .privacy-terms-wrap {
      max-width: 845px;
      margin: 0px auto 20px;

      .cstm-checkbox-wrap {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        label {
          display: block;
          input {
            position: absolute;
            pointer-events: none;
            opacity: 0;
            visibility: hidden;

            &:checked {
              & ~ .cstm-checkbox {
                &::before {
                  background-color: var(--cmn-blue);
                  border-color: var(--cmn-blue);
                }

                &::after {
                  opacity: 1;
                }
              }
            }
          }
        }
        .cstm-checkbox {
          font-size: 14px;
          line-height: 1.5;
          color: var(--cmn-black);
          position: relative;
          display: block;
          transition: all ease-in-out 0.3s;
          cursor: pointer;
          padding-left: 21px;

          &::before {
            content: "";
            height: 18px;
            width: 18px;
            border: 2px solid var(--cmn-black);
            display: block;
            border-radius: 100%;
            margin-right: 4px;
            transition: all ease-in-out 0.3s;
            position: absolute;
            top: -1px;
            left: 0;
          }

          &::after {
            content: "";
            position: absolute;
            top: 4px;
            left: 3px;
            height: 5px;
            width: 11px;
            border-left: 2px solid var(--cmn-white);
            border-bottom: 2px solid var(--cmn-white);
            transform: rotate(-45deg);
            transition: all ease-in-out 0.3s;
            opacity: 0;
          }

          a {
            display: inline-block;
            color: var(--cmn-blue);
            font-weight: 500;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .payment-method-wrap {
      .payment-method {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        margin-bottom: 50px;

        @media only screen and (max-width: 1199px) {
          gap: 30px;
        }

        .cmn-payment-wrap {
          width: calc(100% / 2 - calc(40px / 2));
          padding: 27px;
          border: 1px solid var(--form-border);
          border-radius: 20px;

          @media only screen and (max-width: 1199px) {
            width: calc(100% / 2 - calc(30px / 2));
            padding: 20px;
          }

          @media only screen and (max-width: 991px) {
            width: 100%;
          }

          @media only screen and (max-width: 767px) {
            padding: 15px;
          }

          .credit-card-img {
            text-align: right;
            margin-bottom: 20px;

            img {
              max-width: 150px;
              margin-left: auto;
              display: block;
            }
          }

          .payment-table {
            table {
              width: 100%;

              tr {
                @media only screen and (max-width: 767px) {
                  display: block;
                }

                &:not(:last-child) {
                  border-bottom: 1px solid var(--form-border);

                  @media only screen and (max-width: 767px) {
                    margin-bottom: 10px;
                    padding-bottom: 5px;
                  }
                }
                td {
                  color: var(--cmn-blue);
                  /* white-space: nowrap; */
                  padding: 15px 0;
                  font-size: 16px;
                  vertical-align: top;

                  @media only screen and (max-width: 767px) {
                    display: block;
                    padding: 0;
                  }

                  &:last-child {
                    text-align: right;
                    color: var(--cmn-black);

                    @media only screen and (max-width: 767px) {
                      text-align: left;
                      margin-top: 3px;
                    }
                  }

                  &.discount-wrap {
                    &>span,&>div {
                      display: inline-block;
                    }
                  }

                  &>span {
                    display: block;
                  }

                  .radio_option {
                    display: block;

                    .MuiFormControlLabel-root {
                      margin: 0;

                      .MuiButtonBase-root {
                        padding: 0;
                        margin-right: 2px;

                        svg {
                          font-size: 16px;
                        }
                      }

                      .MuiTypography-root {
                        p {
                          margin: 0;
                          font-size: 16px;
                          color: var(--cmn-black);
                          line-height: 1.5;
                        }
                      }
                    }
                  }

                  .table-radio {
                    display: block;

                    input {
                      margin-right: 3px;
                    }
                  }
                }
              }
            }
          }

          .payment-method-form {
            display: flex;
            flex-wrap: wrap;
            gap: 10px 20px;

            .form-group {
              width: calc(100% / 2 - calc(20px / 2));

              @media only screen and (max-width: 1199px) {
                width: 100%;
              }
            }
          }

          p {
            margin-bottom: 16px;

            strong {
              font-weight: 500;
              color: var(--cmn-blue);
            }
          }

          .shipping-check {
            label {
              cursor: pointer;

              font-size: 14px;
              input[type="checkbox"] {
                vertical-align: baseline;
              }

              span {
                margin-left: 3px;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }

    .review-address-wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
      margin-bottom: 50px;

      .cmn-address {
        width: calc(100% / 2 - calc(40px / 2));
        padding: 27px 27px 50px;
        border: 1px solid var(--form-border);
        border-radius: 20px;

        p {
          margin-bottom: 16px;

          strong {
            font-weight: 500;
            color: var(--cmn-blue);
          }
        }

        ul {
          li {
            font-size: 16px;
            line-height: 1.45;
            font-weight: 400;
            padding: 8px 0;
            display: flex;
            color: var(--cmn-blue);

            &:not(:last-child) {
              border-bottom: 1px solid var(--form-border);
            }

            span {
              color: var(--cmn-black);
              width: 50%;
              margin-left: auto;
            }
          }
        }

        .shipping-check {
          margin-bottom: 16px;

          label {
            cursor: pointer;

            font-size: 14px;
            input[type="checkbox"] {
              vertical-align: sub;
            }

            span {
              margin-left: 3px;
              vertical-align: middle;
            }
          }
        }

        .shipping-form {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;

          .form-group {
            width: calc(100% / 2 - calc(20px / 2));
          }
        }
      }
    }

    .cmn-packs-heading {
      text-align: center;
      margin-bottom: 20px;

      h3 {
        margin: 0;
      }
    }

    .cmn-packs-outer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 24px;
      margin-bottom: 50px;
      color: var(--cmn-black);

      .cmn-packs {
        width: calc(100% / 2 - 12px);
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #000;
        border-radius: 20px;
        padding: 24px;

        @media only screen and (max-width: 991px) {
          width: 100%;
          padding: 20px 15px;
        }

        &.active-pack {
          background-color: var(--active-pack);

          .cmn-pack-content {
            .btn {
              background-color: var(--cmn-blue);
              color: var(--cmn-white);
            }
          }
        }

        figure {
          width: 222px;
          height: 200px;
          margin: 0 10px 0 0;

          @media only screen and (max-width: 1199px) {
            width: 100%;
            margin: 0 0 10px;
          }

          @media only screen and (max-width: 991px) {
            height: auto;
            max-height: 200px;
          }
        }

        .cmn-pack-content {
          width: calc(100% - 232px);
          text-align: center;

          @media only screen and (max-width: 1199px) {
            width: 100%;
          }
          
          .edit-del {
            justify-content: center;
            gap: 10px;

            .btn-primary-btn {
              font-size: 14px;
              color: var(--cmn-blue);
              display: flex;
              align-items: center;
              gap: 5px;

              &.dlt-btn {
                color:  #ff0000;
              }

              svg {
                height: 14px;
                width: 14px;
                margin-top: -2px;
              }
            }
          }

          p {
            font-size: 14px;
            margin-bottom: 11px;
          }

          h5 {
            margin-bottom: 5px;
            line-height: 1.1;
          }

          h6 {
            margin-bottom: 14px;
          }

          .btn {
            font-size: 15px;
            line-height: 1.4;
            min-width: unset;
            border-width: 1px;
            padding: 5px 29px;
            min-width: 180px;
          }
        }
      }
    }
  }

  .smartship-products-wrap {
    padding-top: 34px;
    & > p {
      max-width: 670px;
      margin: 0 auto 24px;
      text-align: center;
    }
    .smartship-products-outer {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -20px;

      .smartship-products {
        width: calc(100% / 4);
        padding: 20px;

        figure {
          width: 100%;
          height: 216px;
          margin-bottom: 5px;
        }

        .smartship-product-details {
          .smartship-product-heading {
            text-align: center;
            margin-bottom: 6px;

            h6 {
              font-weight: 400;
              margin: 0;
              text-transform: uppercase;
              font-size: 20px;
            }

            svg {
              height: 24px;
              width: 24px;
              margin: auto;
              fill: var(--cmn-blue);
            }
          }

          .smartship-product-pricing {
            .form-btn-wrap {
              margin-top: 20px;
              text-align: center;
              .btn {
                font-size: 15px;
                line-height: 1.4;
                min-width: unset;
                border-width: 1px;
                padding: 6px 29px;
              }
            }
            .form-group {
              border-bottom: 1px solid var(--continue-text);

              &:first-child {
                border-top: 1px solid var(--continue-text);
              }

              &.one-time-order {
                background-color: var(--signup-bg);
              }

              label {
                position: relative;
                display: flex;
                padding: 7px 5px;
                min-height: 68px;
                cursor: pointer;

                input[type="radio"] {
                  position: absolute;
                  opacity: 0;
                  visibility: hidden;

                  &:checked {
                    & ~ .cst-smartship-product {
                      &::before {
                        border: 1px solid var(--cstm-radio);
                      }

                      &:after {
                        opacity: 1;
                        transform: translateY(-50%) scale(1);
                      }
                    }
                  }
                }
                .cst-smartship-product {
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 20px;
                  font-weight: 700;
                  padding-left: 25px;
                  font-size: 15px;

                  &::before {
                    content: "";
                    height: 13px;
                    width: 13px;
                    border: 1px solid var(--cmn-black);
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    border-radius: 100%;
                    transition: all ease-in-out 0.3s;
                  }
                  &::after {
                    content: "";
                    height: 7px;
                    width: 7px;
                    background-color: var(--cstm-radio);
                    position: absolute;
                    top: 50%;
                    left: 3px;
                    transform: translateY(-50%) scale(0);
                    border-radius: 100%;
                    transition: all ease-in-out 0.3s;
                    opacity: 0;
                  }

                  span {
                    display: block;
                  }

                  .order-type {
                    white-space: nowrap;

                    small {
                      font-size: 14px;
                      line-height: 1.2;
                      font-weight: 400;
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .continue-btn {
    display: flex;
    justify-content: center;
    gap: 36px;
    padding-top: 60px;
    margin-bottom: 10px;

    @media only screen and (max-width: 1199px) {
      padding-top: 40px;
      gap: 30px;
    }

    @media only screen and (max-width: 767px) {
      padding-top: 20px;
      gap: 20px;
    }

    @media only screen and (max-width: 575px) {
      gap: 15px;
    }

    .btn {
      font-size: 15px;
      line-height: 1.4;
      min-width: 171px;
      border-width: 1px;
      padding: 5px 29px;
      text-transform: uppercase;

      @media only screen and (max-width: 1199px) {
        min-width: 151px;
      }

      @media only screen and (max-width: 767px) {
        min-width: 100px;
        padding: 5px 20px;
      }
    }
  }

  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      input {
        border: 1px solid var(--form-border);
        border-radius: 50px;
        padding: 9px 12px;
        font-size: 14px;
        line-height: 1.4;
        box-shadow: none;
        outline: none;
        color: var(--cmn-black);
        text-align: center;
      }
    }
  }
`;

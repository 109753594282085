import React from "react";
import styled from "styled-components";


export const OurStoryStyle = styled.div`
    .story-banner-sec {
        figure {
            display: block;
            margin: 0;
            width: 100%;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .story-main-sec {
        padding: 40px 0 120px;

        @media only screen and (max-width: 1199px) {
            padding: 40px 0 70px;
        }
        
        
        
        h2 {
            text-align: center;
            margin-bottom: 60px;

            @media only screen and (max-width: 1399px) {
                margin-bottom: 40px;
            }

            @media only screen and (max-width: 1199px) {
                margin-bottom: 30px;
            }

            @media only screen and (max-width: 767px) {
                margin-bottom: 24px;
            }
        }

        .story-main {
            float: left;

            figure {
                height: 344px;
                width: 271px;
                float: left;
                margin-right: 35px;
                margin-bottom: 30px;
                
                @media only screen and (max-width: 991px) {
                    float: unset;
                    margin: 0 auto 24px;
                    width: 100%;
                }
            }
        }

    }
`
import React from 'react'
import styled from 'styled-components'

export const RewardMainStyle = styled.div`
    /* height: calc(100vh - 112px);
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    scroll-snap-type: y mandatory;     */
      
    @media only screen and (max-width: 767px) {
        /* height: auto;
        scroll-snap-type: unset;    
        overflow: auto; */
    }

    section {
        /* scroll-snap-align: start; */
        /* min-height: calc(100vh - 111px); */
        min-height: calc(100vh);
        position: relative;

        @media only screen and (min-width: 1920px) {
            min-height: 1080px;
            height: 100vh;
        }

        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
            padding-top: 0px;   
            min-height: unset;
        }

        .manual-background {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            margin: 0;

            @media only screen and (max-width: 767px) {
                position: static;
                margin-bottom: 30px
            }

            img {
                display: block;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .container {
            position: relative;
            z-index: 2;
        }
        
        /* @media only screen and (max-width: 1399px) {
            min-height: calc(100vh - 102px);            
        }

        @media only screen and (max-width: 1199px) {
            min-height: calc(100vh - 91px);            
        }

        @media only screen and (max-width: 991px) {
            min-height: calc(100vh - 72px);            
        } */
    }

    .swiper {
        width: 100%;
        height: 100%;
    }
`
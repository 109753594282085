import React from "react";
import { RewardBannerCss } from "./RewardStyle";

const RewardBanner = (props) => {
  const { Validatelogin, userRank } = props
  return (
    <RewardBannerCss>
      <figure className="manual-background">
        <img src="images/reward-banner-new.jpg" alt="Banner background" />
      </figure>
      <div className="container">
        <div className="cstm-row">
          <div className="col-7">
            <div className="reward-banner-text">
              <h2>
                <span>WELCOME TO SHAREKAIRE'S THRILLING REWARDS JOURNEY!</span>
              </h2>
              <p>
                <small>
                  Join us at ShareKaire for an exhilarating ride towards transforming
                  lives! Our products and innovative business platform are your
                  ticket to a better life, both physically and financially.
                  Helping others achieve their well-being and financial dreams is
                  what we're all about.
                </small>
              </p>
              <p>
                <small>
                  With ShareKaire, you've got the perfect blend of business and health
                  to share with others, empowering them to reach their goals. For
                  over three decades, we've been dedicated to improving people's
                  health, and now, we're taking it to the next level with our
                  brand-new network marketing platform.
                </small>
              </p>
              <p>
                <small>
                  We're here to support you every step of the way on your journey
                  to success. Let's embark on this exciting adventure together and
                  make a positive impact on the world. Welcome to ShareKaire, where
                  dreams come true!
                </small>
              </p>
              <div className="btn-wrap">
                <a type="button" className="btn"
                  // href="https://commandconcept.csdevhub.com/media/pdf_files/Rewards_Plan_040324.pdf" 
                  href="https://admin.shopkaire.com/media/pdf/Kaire_RewardsPlan_102924.pdf"
                  target="_blank">
                  The full rewards plan
                </a>
                {userRank?.user_data?.userdetails?.[0]?.qualified_as_rank !== 3 &&
                  <button type="button" className="btn btn-2 " onClick={() => Validatelogin()}>
                    SIGNUP
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </RewardBannerCss>
  );
};

export default RewardBanner;

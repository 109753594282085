import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import HomePage from './pages/Home';
import Error from './Error';
import Reward from './pages/Rewards';
import SignUp from './pages/Signup/Index';
import Enrollment from './pages/Enrollment/Index';
import Smartship from './pages/Smartship/Index';
import Review from './pages/Review/Index';
import Cart from './pages/cart/viewCart';
import ThankYou from './pages/order/thanksyou';
import Login from './pages/Login';
import OurStory from './pages/OurStory/Index';
import * as Sentry from '@sentry/react';
import Product from './pages/product/name/id';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const store = 'us'
const routers = sentryCreateBrowserRouter([
    {
        path: '/',
        element: <App />,
        // errorElement: <Error />,
        children: [
            {
                children: [
                    { path: '/', element: <HomePage /> },
                    { path: 'reward', element: <Reward /> },
                    { path: 'signup', element: <SignUp /> },
                    { path: 'enrollment', element: <Enrollment /> },
                    { path: 'smartship', element: <Smartship /> },
                    { path: 'review', element: <Review /> },
                    { path: 'viewcart', element: <Cart /> },
                    { path: 'Order/thankyou/:orderid', element: <ThankYou /> },
                    { path: 'login', element: <Login /> },
                    { path: 'ourstory', element: <OurStory /> },
                    { path: 'product/:name/:id', element: <Product /> },
                ]
            },

        ],
    },
]);

function Routes() {
    return (
        <RouterProvider router={routers} />
    );
}

export default Routes;
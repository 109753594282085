import { useState } from "react";
// import {
//   Typography,
//   Box,
//   // Rating,
//   Dialog,
//   DialogContent,
// } from "@material-ui/core";
import styled from "styled-components";
// import api from "@api/Apis";
// import { toast } from "react-toastify";
import DialogComponent from "../DialogComponent";
import api from "../../../api/Apis";
import { Rating, Typography, Box } from "@mui/material";

const ReviewComponentStyle = styled.div`
  border: 1px solid #ddd;
  border-radius: 0px;
  padding: 25px 25px 50px;
  background: #fff;
  margin: 0px 0 10px;
  position: relative;
  @media (max-width: 767px) {
    margin: 0px auto 10px;
    max-width: calc(100% - 30px);
    padding: 15px;
  }
  & .review_section {
    display: flex;
    align-items: center;

    .error.deletemsg {
      position: absolute;
      bottom: 0;
      font-size: 13px !important;
      width: 100%;
      left: 0;
      max-width: 100%;
      padding: 9px;
      border-radius: 0;
      letter-spacing: 1.2px;
      @media (max-width: 767px) {
        font-size: 12px !important;
        letter-spacing: 0.2px;
        position: unset;
        margin-top: 20px;
      }
    }
    @media (max-width: 1199px) {
      flex-wrap: wrap;
    }
    .rating_section {
      margin: 0;
      width: 40%;
      padding-right: 30px;
      @media (max-width: 1199px) {
        width: 80%;
        padding-right: 0;
        padding-left: 30px;
      }
      @media (max-width: 991px) {
        width: 100%;
        padding: 20px 0;
      }
      .MuiBox-root {
        margin-bottom: 0px;
      }
      label {
        font-size: 22px;
        text-align: center;
        justify-content: center;
        width: 100%;
      }
      > fieldset {
        > legend {
          font-size: 15px !important;
          margin: 0px 0 0;
          display: block !important;
          font-family: var(--common-font);
          font-weight: bold;
          padding-bottom: 5px;
          margin-bottom: 5px;
        }
        > span {
          font-size: 4.5rem;
        }
      }
    }
    .title_of_review {
      display: block;
      font-size: 15px;
      max-width: calc(100% - 130px);
      label {
        margin-top: 0px;
        margin-bottom: 10px;
      }
      .title_review {
        border-radius: 0px;
        padding: 5px 10px;
        font-size: 14px;
        width: 100%;
      }
      @media (max-width: 767px) {
        max-width: 100%;
      }
    }
    .product_name {
      width: 20%;
      @media (max-width: 991px) {
        width: 100%;
      }
      h3 {
        font-size: 16px;
        font-weight: bold;
        margin: 0px;
      }
    }
    .review_button {
      color: #fff;
      padding: 0 !important;
      margin: unset;
      width: 130px;
      flex: 130px;
      text-align: right;
      button {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 15px;
        background: #00356a;
        height: 40px;
        border: 2px solid #00356a;
        text-transform: uppercase;
        padding: 10px 40px;
        max-width: 150px;
        width: 100%;
        margin: 0 !important;
        &.disabled {
          background: darkgray;
          border: 2px solid darkgray;
          margin: auto;
          &:hover {
            background: #fff;
            color: darkgray;
          }
        }
        &:hover {
          background: none;
          color: #00356a;
        }
      }
      @media (max-width: 767px) {
        text-align: left;
        margin-top: 10px;
      }
    }
    .one_on_one {
      display: flex;
      align-items: flex-end;
      textarea {
        padding: 5px 10px !important;
        height: 65px;
        font-size: 14px;
        border-radius: 5px;
      }
      @media (max-width: 767px) {
        flex-wrap: wrap;
      }
    }
  }
`;

const ReviewProductPage = styled(ReviewComponentStyle)`
  border: unset;
  border-radius: none;
  padding: 0;
  background: none;
  margin: 0;
  & .review_section {
    display: unset;
    align-items: center;
    .rating_section {
      margin: 18px 0px 0;
      width: 100%;
      > fieldset {
        > legend {
          font-size: 20px !important;
          white-space: nowrap;
        }
      }
    }
    .product_name {
      width: 100%;
      h3 {
        margin: 0;
        font-size: 40px;
        text-align: center;
      }
    }
    .review_button {
      margin: unset;
    }
    .one_on_one {
      display: unset;
      textarea {
        padding: 10px !important;
        height: 65px;
      }
    }
  }
`;

const ReviewContainer = ({
  setReviewState,
  productName,
  rating,
  setRating,
  review,
  setReviewData,
  reviewData,
  productId,
  handleClose,
}) => {
  const [message, setMessage] = useState("");
  const handleReviewFunction = async (productId, review) => {
    let formData = {
      product_id: +productId,
      review_message: review ? review : "",
      review_ratings: +rating,
    };
    await api
      .saveCustomerReview(formData)
      .then((res) => {
        if (res?.status === 200 && res?.data) {
          setMessage(res?.data?.message);
          handleClose(false);
          setTimeout(() => {
            setMessage("");
          }, 3000);
          // toast.success(res?.data?.message);
          // handleClose(false);
          setRating(0);
          setReviewState("");
        }
      })
      .catch((error) => {
        // handleClose(false);
        setRating(0);
        setReviewState("");
      });
  };
  return (
    <div className="review_section">
      <div className="product_name">
        <h3>{productName}</h3>
      </div>
      <div className="rating_section">
        <Box component="fieldset" mb={3} borderColor="transparent">
          <Typography component="legend">
            Tell us what you think of {productName}:-
          </Typography>
          <Rating
            name="rating"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
        </Box>
      </div>
      <div className="one_on_one">
        <div className="title_of_review">
          <label htmlFor="titile_for_review">
            How was your overall experience using the item:
          </label>
          <textarea
            className="title_review"
            name="title_review"
            value={review}
            rows="4"
            cols="50"
            onChange={(e) => setReviewState(e.target.value)}
          />
        </div>
        <div className="review_button">
          <button
            type="button"
            className={!!review ? "not-disabled" : "disabled"}
            disabled={!review}
            onClick={() => handleReviewFunction(productId, review)}
          >
            Review
          </button>
        </div>
      </div>
      {message && (
        <span
          className="error deletemsg"
          style={{
            color: "green",
            fontSize: "16px",
            display: "block",
            width: "100%",
            textAlign: "center",
          }}
        >
          {message}
        </span>
      )}
    </div>
  );
};
const ReviewComponent = ({
  productId,
  productName,
  section,
  open,
  handleClose,
  reviewData,
  setReviewData,
}) => {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");

  function getReviewContainer() {
    return (
      <ReviewContainer
        productName={productName}
        productId={productId}
        handleClose={handleClose}
        review={review}
        setReviewData={setReviewData}
        reviewData={reviewData}
        setReviewState={setReview}
        rating={rating}
        setRating={setRating}
      />
    );
  }

  return section === "product_page" ? (
    <DialogComponent
      opend={open}
      handleClose={() => handleClose(false)}
      title="Write a review"
      classFor="review_section_product_page"
    >
      <ReviewProductPage>{getReviewContainer()}</ReviewProductPage>
    </DialogComponent>
  ) : (
    <ReviewComponentStyle>{getReviewContainer()}</ReviewComponentStyle>
  );
};
export default ReviewComponent;

import React from "react";
import { EnrollmentStyle } from "./components/EnrollmentStyle";
import EnrollmentBanner from "./components/EnrollmentBanner";
import EnrollmentPack from "./components/EnrollmentPack";

const Enrollment = () => {
  return (
    <>
      <EnrollmentStyle>
        <EnrollmentBanner />
        <EnrollmentPack />
      </EnrollmentStyle>
    </>
  );
};

export default Enrollment;

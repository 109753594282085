import _ from "lodash";
import React from "react";
import { NavLink, useOutletContext } from "react-router-dom";
import styled from "styled-components";
import withRouter from "../../services/HOC/withRouterHOC";

const FooterCss = styled.div`
  z-index: 10;
  position: relative;
  background: url("/images/footer-bg.png"),
    linear-gradient(
      90deg,
      rgba(2, 55, 109, 1) 0%,
      rgba(94, 136, 236, 1) 44.7845%,
      rgba(209, 137, 168, 1) 100%
    );
  font-family: var(--common-font);
  /* padding: 59px 0 80px; */
  padding: 25px 0 10px;

  @media only screen and (max-width: 1199px) {
    padding: 40px 0 50px;
  }

  @media only screen and (max-width: 767px) {
    padding: 20px 0;
  }

  .footer-wrap {

    .disclaimer {
      font-size: 14px;
      line-height: 1.42857143;
      color: #fff;
      font-weight: 500;
      text-align: center;

      @media only screen and (max-width: 767px) {
        font-size: 12px;
      }
    }

    .footer_text {
      background-color: #fff;
      padding: 6px;
      text-align: center;
      margin-bottom: 24px;
      
      p {
        padding: 20px 30px;
        border: 1px solid var(--cmn-blue);
        font-size: 13px;
        line-height: 1.42857143;
        font-weight: 600;
        overflow: hidden;
        position: relative;

        @media only screen and (max-width: 767px) {
          padding: 15px;
        }

        &::after, &::before {
          content: "";
          position: absolute;
          left: -35px;
          top: -35px;
          height: 70px;
          width: 70px;
          border:13px solid rgba(6,53,106,0.09);
          border-radius: 100%;
          z-index: 1;
        }

        &::after {
          left: unset;
          top: unset;
          right: -35px;
          bottom: -35px;
        }
      }
    }

    .footer-listing {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;
      
      @media only screen and (max-width: 575px) {
        flex-direction: column;
        gap:  10px;
      }

      .contact-listing {
        li {
          display: flex;
          flex-direction: column;
          /* gap: 5px; */

          @media only screen and (max-width: 767px) {
            text-align: left;
          }

          @media only screen and (max-width: 575px) {
            align-items: center;
          }

          span {
            display: block;
          }
        }
      }

      ul {
        gap: 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        @media only screen and (max-width: 575px) {
          align-items: center;
        }

        li {
          font-size: 14px;
          line-height: 1.42857143;
          color: #fff;
          font-weight: 500;

          &:not(:last-child) {
            /* margin-bottom: 5px; */

            &::after {
              display: none;
            }
          }

          a {
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;
          }
        }
      }
    }

    .logo {
      text-align: center;
      background: #fff;
      display: block;
      padding: 6px 10px;
      max-width: 280px;
      margin: 20px auto 16px;

      img {
        margin: 0 auto;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    figure {
      max-width: 296px;
      margin: auto;
      margin-bottom: 20px;
      /* display: flex;
      justify-content: center; */

      @media only screen and (max-width: 1199px) {
        max-width: 250px;
      }

      @media only screen and (max-width: 767px) {
        max-width: 200px;
        margin-bottom: 10px;
      }

      a {
        display: block;
        /* display: inline-flex;
        align-items: center;
        font-size: 54px;
        line-height: 1.15;
        font-family: var(--logo-font);
        color: var(--cmn-white); */

        /* @media only screen and (max-width: 1199px) {
          font-size: 45px;
        }

        @media only screen and (max-width: 767px) {
          font-size: 40px;
        } */
      }

      img {
        width: 100%;
        object-fit: contain;
        /* max-width: 54px;
        margin-right: 3px; */

        /* @media only screen and (max-width: 767px) {
          max-width: 50px;
        } */
      }
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 27px;
      flex-wrap: wrap;

      @media only screen and (max-width: 767px) {
        gap: 17px;
      }

      /* @media only screen and (max-width: 480px) {
          flex-direction: column;
          gap: 7px;
      } */

      li {
        position: relative;

        @media only screen and (max-width: 767px) {
          text-align: center;
        }

        &:not(:last-child)::after {
          content: "";
          height: 100%;
          position: absolute;
          top: 0;
          right: -14px;
          border-right: 2px solid #fff;

          @media only screen and (max-width: 767px) {
            right: -9px;
          }

          /* @media only screen and (max-width: 480px) {
            display: none;
          } */
        }

        a {
          font-size: 14px;
          line-height: 1;
          color: #fff;
          display: block;
          text-decoration: none;

          &:hover {
            color: var(--cmn-blue);
          }

          @media only screen and (max-width: 767px) {
            font-size: 13px;
          }
        }
      }
    }
  }

  .footer-sec {
    margin-top: 30px;

    @media only screen and (max-width: 767px) {
      margin-top: 16px;
    }
  }

  .footer-bottom-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    text-align: center;

    @media only screen and (max-width: 767px) {
      gap: 16px;
    }

    .footer-bottom {
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }

    ul {
      li {
        font-size: 14px;
        line-height: 1;
        color: #fff;
        display: block;
        span {
          display: block;
          margin-bottom: 20px;

          @media only screen and (max-width: 1199px) {
            margin-bottom: 15px;
          }

          @media only screen and (max-width: 767px) {
            margin-bottom: 7px;
          }
        }
        a {
          font-size: 14px;
          text-decoration: none;
          line-height: 1;
          color: #fff;
          display: block;

          &:hover {
            color: var(--cmn-blue);
          }
        }
      }
    }
  }
`;
const Footer = ({
  showloader,
  isLoginToken,
  innerLoader,
  subDomain,
  ...props
}) => {

  return (
    <FooterCss>
      <div className="container">
        <div className="footer-wrap">
          <figure>
            <a href="">
              <img src="/images/New-logo.png" alt="fOOterLogO" />
            </a>

          </figure>
          <div class="footer-listing">
            <ul className="contact-listing">
              <li>
                <span> Contact Us</span>
                <span>eKaire.com, Inc.</span>
                <span> 5717 Red Bug Lake Road, Ste. 138</span>
                <span> Winter Springs, FL 32708-4969</span>
              </li>
              <li>
                <a href="mailto:support@kaire.com">Customer Support: support@kaire.com</a>
              </li>
            </ul>
            <ul>

              <li>
                <a
                  className="privacy_style"
                  target="_blank"
                  href="https://admin.shopkaire.com/media/pdf/Kaire_refund_&_return_Policy_new_may_2024.pdf"

                >
                  Refund/Return Policy
                </a>
              </li>
              <li>
                <a
                  className="privacy_style"
                  target="_blank"
                  href="https://commandconcept.csdevhub.com/media/pdf/Kaire_PrivacyPolicy.pdf"
                >
                  {" "}
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  className="privacy_style"
                  target="_blank"
                  href="https://admin.shopkaire.com/media/pdf/Kaire_shipping_policy.pdf"
                >
                  {" "}
                  Shipping Policy
                </a>
              </li>
              {/* <li>
                        <a
                          className="privacy_style"
                          target="_blank"
                          href="https://admin.shopkaire.com/media/pdf/Kaire_TermsConditions.pdf"
                        >
                          {" "}
                          Terms & Conditions{" "}
                        </a>
                      </li> */}

              <li>
                <a
                  className="privacy_style"
                  target="_blank"
                  href="https://admin.shopkaire.com/media/pdf/Kaire_policies_&_procedures.pdf"
                >
                  {" "}
                  Policies & Procedures{" "}
                </a>
              </li>
              <li>
                <a
                  className="privacy_style"
                  target="_blank"
                  href="https://commandconcept.csdevhub.com/media/pdf_files/INCOME_DISCLOSURE.pdf"
                >
                  {" "}
                  Income Disclaimer{" "}
                </a>
              </li>
            </ul>
          </div>
          <div className="logo">
            <img src="/images/creditcard.png" />
          </div>

          <div className="footer_text">
            <p>
              These statements have not been evaluated by the Food and Drug
              Administration. Kaire products are not intended to diagnose
              prevent treat or cure any disease. If you are under medical
              supervision for any allergy, disease, taking prescription
              medications or you are breastfeeding contact your medical
              provider before adding any new supplements to your daily
              regimen.
            </p>
          </div>
          <div className="disclaimer">Copyright 2024 eKaire.com, Inc.  All Rights Reserved.  Kaire ™ is a registered trademark of eKaire.com, Inc.  </div>
        </div>
      </div>
    </FooterCss >

  );
};

export default Footer;
